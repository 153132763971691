import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Portal from '@material-ui/core/Portal';

import WarningImage from '../../../../resources/images/warning-image.png';

const useStyles = makeStyles((theme) => ({
  dropdown: {
    position: 'fixed',
    width: 300,
    top: '0',
    left: '0',
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    zIndex: 99999,

    '& > img': {
      width: '100%',
      height: 'auto',
    },
  },
}));

export default function PortalClickAway({ isVisible, handleClickAway }) {
  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        {isVisible ? (
          <Portal>
            <div className={classes.dropdown}>
              <img src={WarningImage} alt="warning" />
            </div>
          </Portal>
        ) : null}
      </div>
    </ClickAwayListener>
  );
}
