import React, { Fragment, useEffect } from 'react';
import { useFormikContext, Field } from 'formik';
import moment from 'moment';
import { AutocompleteComponent, DatePicker, Input, Select } from '../../../../components';
import verificationFormModel from '../../FormModel/formModel';
import { DATE_FORMAT, MAX_LENGTH_INPUT, getGenderOptions, DATE_FORMAT_FIELD } from '../../../../common/constant';
import { countries } from '../../../../utils/countries';
import { useTranslation } from 'react-i18next';

function ResidentCardComponent() {
  const { formField } = verificationFormModel();
  const { values, setFieldValue } = useFormikContext();
  const { t } = useTranslation();
  const { idoc_card_number, idoc_full_name, idoc_birthday, full_name, birthday, idoc_gender, idoc_nationality, idoc_expired_date, nationality } =
    formField;

  const GENDER_OPTIONS = getGenderOptions(t);

  const handleDatePickerChange = fieldName => date => {
    setFieldValue(fieldName, moment(date).format(DATE_FORMAT_FIELD));
  };

  useEffect(() => {
    if (!values[idoc_full_name.name]) {
      setFieldValue(idoc_full_name.name, values[full_name.name]);
    }
    if (!values[idoc_nationality.name]) {
      setFieldValue(idoc_nationality.name, values[nationality.name]);
    }
    if (!values[idoc_birthday.name]) {
      setFieldValue(idoc_birthday.name, moment(values[birthday.name]).format(DATE_FORMAT_FIELD));
    }
  }, []);

  return (
    <Fragment>
      <Field
        name={idoc_card_number.name}
        label={idoc_card_number.label}
        placeholder={idoc_card_number.placeholder}
        value={values[idoc_card_number.name]}
        fullWidth
        maxLength={MAX_LENGTH_INPUT}
        component={Input}
      />
      <Field
        name={idoc_full_name.name}
        label={idoc_full_name.label}
        placeholder={idoc_full_name.placeholder}
        value={values.idoc_full_name}
        fullWidth
        maxLength={MAX_LENGTH_INPUT}
        component={Input}
      />
      <Field
        name={idoc_birthday.name}
        label={idoc_birthday.label}
        placeholder={idoc_birthday.placeholder}
        maxDate={new Date()}
        format={DATE_FORMAT}
        onChange={handleDatePickerChange(idoc_birthday.name)}
        fullWidth
        component={DatePicker}
      />
      <Field
        name={idoc_gender.name}
        label={idoc_gender.label}
        labelField={idoc_gender.label}
        placeholder={idoc_gender.placeholder}
        value={values.idoc_gender}
        options={GENDER_OPTIONS}
        fullFieldWidth
        component={Select}
      />
      <Field
        name={idoc_nationality.name}
        label={idoc_nationality.label}
        labelField={idoc_nationality.label}
        placeholder={idoc_nationality.placeholder}
        value={values.idoc_nationality}
        options={countries}
        onChange={(event, newValue) => {
          setFieldValue(idoc_nationality.name, newValue);
        }}
        fullFieldWidth
        component={AutocompleteComponent}
      />
      <Field
        name={idoc_expired_date.name}
        label={idoc_expired_date.label}
        placeholder={idoc_expired_date.placeholder}
        format={DATE_FORMAT}
        onChange={handleDatePickerChange(idoc_expired_date.name)}
        minDate={new Date()}
        fullWidth
        component={DatePicker}
      />
    </Fragment>
  );
}

export default ResidentCardComponent;
