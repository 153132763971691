import * as React from 'react';

function IconIndependentUser(props) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path
          d="M6.07328 35.5243L6.07327 35.5245L43.0423 70.3579L43.0329 70.2082C43.0329 70.2082 43.0328 70.2082 43.0328 70.2082C38.4234 70.4976 33.8053 69.8028 29.4851 68.1699C25.165 66.5371 21.2417 64.0035 17.9761 60.7376C14.7105 57.4717 12.1773 53.5482 10.5449 49.2279C8.91237 44.9076 8.21798 40.2893 8.50781 35.68C8.52842 35.3572 8.41999 35.0394 8.20636 34.7966C7.99269 34.5537 7.69128 34.4056 7.36844 34.385C7.0456 34.3643 6.72778 34.4727 6.48489 34.6864C6.242 34.9001 6.09394 35.2015 6.07328 35.5243Z"
          fill="#666A74"
          stroke="#666A74"
          strokeWidth="0.3"
        />
        <path
          d="M58.1462 10.0044L58.1465 10.0046C58.2824 10.0889 58.4336 10.1456 58.5914 10.1714C58.7492 10.1972 58.9106 10.1917 59.0663 10.1552C59.222 10.1187 59.3689 10.0519 59.4988 9.95856C59.6286 9.86525 59.7388 9.74727 59.8231 9.61136C59.9074 9.47545 59.964 9.32428 59.9899 9.16647C60.0157 9.00866 60.0102 8.84731 59.9737 8.69163C59.9372 8.53594 59.8703 8.38898 59.777 8.25912C59.6837 8.12926 59.5657 8.01905 59.4298 7.93479C52.729 3.75304 44.8062 1.9719 36.9603 2.88338C29.1144 3.79487 21.811 7.34488 16.2473 12.9515C13.5845 15.6037 11.3758 18.6754 9.70941 22.044C9.60083 22.2273 9.54224 22.436 9.53955 22.6491C9.53683 22.8643 9.59121 23.0764 9.69713 23.2637C9.80306 23.4511 9.95675 23.607 10.1425 23.7156C10.3047 23.8104 10.4863 23.8662 10.6729 23.8791L10.679 23.8883L10.759 23.8884C10.9882 23.889 11.2129 23.8251 11.4075 23.7043C11.6022 23.5834 11.759 23.4103 11.8601 23.2047L11.8601 23.2046C13.4138 20.0446 15.4809 17.1642 17.9773 14.6808L17.978 14.6801C23.152 9.46299 29.9451 6.15878 37.2434 5.30923C44.5418 4.45969 51.9122 6.11524 58.1462 10.0044Z"
          fill="#666A74"
          stroke="#666A74"
          strokeWidth="0.3"
        />
        <path
          d="M74.5923 47.6331L74.592 47.6341C74.5163 47.8858 74.3612 48.1062 74.1498 48.2625C73.9385 48.4187 73.6823 48.5024 73.4195 48.501M74.5923 47.6331L72.3517 47.867C72.3517 47.867 72.3517 47.867 72.3517 47.867M74.5923 47.6331C74.9127 46.5396 75.1782 45.4307 75.3876 44.3106C75.4207 44.1525 75.422 43.9895 75.3914 43.8309C75.3607 43.6717 75.2986 43.5202 75.2086 43.3854C75.1186 43.2505 75.0026 43.135 74.8674 43.0456C74.7322 42.9562 74.5805 42.8947 74.4211 42.8647C74.2618 42.8347 74.0981 42.8368 73.9396 42.8709C73.7812 42.905 73.6311 42.9704 73.4982 43.0633C73.3654 43.1562 73.2524 43.2747 73.1659 43.4118C73.0798 43.5484 73.0217 43.7007 72.995 43.86M74.5923 47.6331L72.995 43.86M73.4195 48.501C73.304 48.5005 73.189 48.4838 73.0782 48.4512C72.9246 48.4063 72.7815 48.3316 72.6568 48.2314C72.5322 48.1311 72.4285 48.0073 72.3517 47.867M73.4195 48.501C73.4195 48.501 73.4195 48.501 73.4194 48.501L73.4202 48.351L73.4196 48.501C73.4196 48.501 73.4196 48.501 73.4195 48.501ZM72.3517 47.867C72.275 47.7267 72.2266 47.5726 72.2094 47.4136C72.1922 47.2546 72.2064 47.0937 72.2514 46.9403L72.3517 47.867ZM72.995 43.86C72.7984 44.886 72.5488 45.9238 72.2514 46.9402L72.995 43.86Z"
          fill="#666A74"
          stroke="#666A74"
          strokeWidth="0.3"
        />
        <path
          d="M56.0419 69.1789C55.7657 69.1806 55.4972 69.0883 55.2804 68.9172C55.0635 68.7461 54.9113 68.5063 54.8487 68.2372C54.7861 67.9682 54.8169 67.6858 54.9359 67.4365C55.0549 67.1874 55.2551 66.9861 55.5035 66.8657C58.245 65.5193 60.7763 63.7815 63.0178 61.7068C63.1346 61.5964 63.2723 61.5102 63.4227 61.4532C63.5733 61.3961 63.7339 61.3695 63.8949 61.3749C64.056 61.3803 64.2143 61.4176 64.3608 61.4846C64.5074 61.5517 64.6391 61.6472 64.7484 61.7656C64.8578 61.8839 64.9425 62.0228 64.9978 62.1742C65.0531 62.3255 65.0777 62.4864 65.0704 62.6473C65.063 62.8083 65.0238 62.9662 64.9549 63.1119C64.8862 63.2573 64.7894 63.3877 64.6701 63.4954C62.255 65.7295 59.5286 67.6013 56.576 69.0525C56.4099 69.1347 56.2273 69.178 56.0419 69.1789ZM56.0419 69.1789L56.0412 69.0289M56.0419 69.1789L56.0412 69.0289M56.0419 69.1789C56.042 69.1789 56.042 69.1789 56.0421 69.1789L56.0412 69.0289M56.0419 69.1789L56.0412 69.0289"
          fill="#666A74"
          stroke="#666A74"
          strokeWidth="0.3"
        />
        <path
          d="M68.85 58.0655H68.8501C69.0455 58.0654 69.2381 58.0181 69.4114 57.9279C69.5847 57.8376 69.7338 57.707 69.8459 57.547C70.468 56.662 71.0412 55.7435 71.5627 54.7958L71.5628 54.7958L71.5644 54.7926C71.7112 54.5101 71.7415 54.1814 71.6488 53.8768C71.5561 53.5723 71.3478 53.3162 71.0685 53.1633C70.7893 53.0105 70.4613 52.9731 70.1548 53.0592C69.8483 53.1452 69.5877 53.3479 69.4288 53.6238L69.4288 53.6238L69.4274 53.6263C68.949 54.4953 68.4234 55.3374 67.8529 56.1489L67.8528 56.1491C67.7249 56.3315 67.6495 56.5456 67.6349 56.7679C67.6202 56.9902 67.6668 57.2123 67.7697 57.41C67.8725 57.6076 68.0276 57.7733 68.2181 57.8889C68.4086 58.0044 68.6272 58.0656 68.85 58.0655Z"
          fill="#666A74"
          stroke="#666A74"
          strokeWidth="0.3"
        />
        <path
          d="M73.5255 35.884C73.5427 36.1951 73.6785 36.4879 73.9049 36.7019C74.1315 36.9159 74.4314 37.035 74.7431 37.0345L74.7431 37.0346L73.6753 35.8757M73.5255 35.884C73.5255 35.884 73.5255 35.8839 73.5255 35.8839L73.6753 35.8757M73.5255 35.884C73.5255 35.884 73.5255 35.8841 73.5255 35.8841L73.6753 35.8757M73.5255 35.884C73.222 30.4807 71.5796 25.2377 68.746 20.6271M73.6753 35.8757C73.3703 30.4459 71.7194 25.1772 68.8712 20.5443M68.746 20.6271C68.747 20.6285 68.7481 20.63 68.7491 20.6314L68.8712 20.5443M68.746 20.6271C68.7452 20.6257 68.7443 20.6243 68.7434 20.6229L68.8712 20.5443M68.746 20.6271L68.8712 20.5443M70.6019 19.064C70.6017 19.0639 70.6016 19.0638 70.6015 19.0637L70.4961 19.1704L70.6022 19.0644C70.6021 19.0643 70.602 19.0642 70.6019 19.064Z"
          fill="#666A74"
          stroke="#666A74"
          strokeWidth="0.3"
        />
        <path
          d="M65.6725 22.2637C63.5386 22.2633 61.4708 21.5231 59.8214 20.1691C58.1719 18.8151 57.0429 16.9309 56.6269 14.8378C56.2108 12.7447 56.5334 10.5721 57.5396 8.69009C58.5458 6.80812 60.1735 5.33327 62.1452 4.51683C64.1169 3.70039 66.3108 3.59287 68.3529 4.21259C70.395 4.83231 72.159 6.14094 73.3445 7.91549C74.5299 9.69004 75.0634 11.8207 74.854 13.9445C74.6446 16.0682 73.7054 18.0535 72.1964 19.5624C72.1963 19.5624 72.1963 19.5625 72.1963 19.5625M65.6725 22.2637L65.6729 22.1137C66.8651 22.1169 68.046 21.8837 69.1475 21.4276C70.249 20.9715 71.2491 20.3016 72.0901 19.4566L72.1961 19.5626C72.1962 19.5626 72.1962 19.5625 72.1963 19.5625M65.6725 22.2637C66.8845 22.2669 68.0851 22.0298 69.2049 21.5662C70.3246 21.1025 71.3413 20.4215 72.1963 19.5625M65.6725 22.2637L72.1963 19.5625M65.6731 6.25022L65.8114 6.25006C66.6567 6.26427 67.4923 6.43727 68.2744 6.76048C69.099 7.10126 69.8478 7.60226 70.4775 8.23445L70.4778 8.23476C71.5893 9.34443 72.2817 10.8052 72.437 12.3681C72.5923 13.931 72.2009 15.4994 71.3295 16.8061C70.4581 18.1128 69.1606 19.077 67.6581 19.5344C66.1555 19.9917 64.5409 19.914 63.0892 19.3144C61.6375 18.7148 60.4387 17.6304 59.6969 16.246C58.955 14.8617 58.7161 13.2629 59.0209 11.7221C59.3256 10.1814 60.1551 8.79391 61.3681 7.79616C62.5811 6.7984 64.1025 6.25206 65.6731 6.25022Z"
          fill="#666A74"
          stroke="#666A74"
          strokeWidth="0.3"
        />
        <path
          d="M8.9112 37.0151L8.91163 37.0151C10.3864 37.0115 11.8308 36.5956 13.0816 35.8143C14.3325 35.033 15.3399 33.9175 15.9901 32.5938C16.6404 31.2701 16.9075 29.7909 16.7613 28.3234C16.6152 26.8559 16.0615 25.4585 15.163 24.289C14.2644 23.1196 13.0568 22.2247 11.6764 21.7054C10.2961 21.1862 8.79802 21.0633 7.35149 21.3507C5.90497 21.638 4.56762 22.3241 3.4905 23.3315C2.41339 24.3389 1.63944 25.6274 1.25608 27.0515L1.25596 27.0519C0.943705 28.2253 0.905228 29.4547 1.1435 30.6453C1.38177 31.8358 1.89043 32.9558 2.6302 33.9186C3.36997 34.8814 4.32108 35.6613 5.41012 36.1982C6.49916 36.7351 7.697 37.0146 8.9112 37.0151ZM3.71554 30.8604C3.36737 29.8347 3.33117 28.7287 3.61153 27.6824C3.98926 26.2803 4.90762 25.0852 6.16523 24.3592C7.42284 23.6331 8.91704 23.4355 10.3202 23.8096C11.3664 24.09 12.3061 24.6744 13.0202 25.4889C13.7344 26.3034 14.1911 27.3114 14.3324 28.3854C14.4737 29.4594 14.2934 30.5512 13.8142 31.5227C13.3351 32.4942 12.5786 33.3018 11.6404 33.8434C10.7023 34.385 9.62458 34.6362 8.54364 34.5654C7.4627 34.4945 6.42705 34.1046 5.56766 33.4452C4.70827 32.7857 4.06373 31.8862 3.71554 30.8604Z"
          fill="#666A74"
          stroke="#666A74"
          strokeWidth="0.3"
        />
        <path
          d="M44.2005 75.5923C45.6689 76.965 47.6045 77.7278 49.6146 77.7258H49.6146C50.3054 77.7253 50.9932 77.6342 51.6604 77.4549C53.6017 76.9335 55.2719 75.6931 56.3323 73.9855C57.3927 72.2778 57.7638 70.2307 57.3703 68.2595C56.9768 66.2883 55.8482 64.5406 54.2134 63.371C52.5786 62.2013 50.5602 61.6974 48.5675 61.9613C46.5748 62.2252 44.7571 63.2373 43.4832 64.7922C42.2093 66.347 41.5745 68.3283 41.7078 70.334C41.841 72.3397 42.7322 74.2196 44.2005 75.5923ZM47.3588 64.8191C48.0695 64.498 48.8406 64.3323 49.6205 64.3332C50.9481 64.3366 52.2293 64.822 53.226 65.6991C54.2227 66.5762 54.8671 67.7853 55.0393 69.1017C55.2115 70.4181 54.8999 71.7523 54.1624 72.8563C53.4248 73.9602 52.3116 74.7589 51.0295 75.1037C50.2761 75.3052 49.4883 75.3434 48.7189 75.216C47.9495 75.0885 47.2161 74.7982 46.5679 74.3644C45.9196 73.9307 45.3715 73.3636 44.9601 72.7009C44.5487 72.0383 44.2836 71.2954 44.1824 70.5221C44.0812 69.7487 44.1464 68.9626 44.3734 68.2165C44.6005 67.4703 44.9843 66.7812 45.4992 66.1953C46.014 65.6094 46.648 65.1402 47.3588 64.8191Z"
          fill="#666A74"
          stroke="#666A74"
          strokeWidth="0.3"
        />
        <path
          d="M26.0731 45.1002V45.1004V50.26C26.0731 50.5829 26.2014 50.8926 26.4298 51.1209C26.6581 51.3493 26.9678 51.4775 27.2907 51.4775H53.9799C54.3028 51.4775 54.6125 51.3493 54.8409 51.1209C55.0692 50.8926 55.1975 50.5829 55.1975 50.26V45.1004V45.1002C55.1946 42.749 54.2593 40.4949 52.5967 38.8323C50.9342 37.1698 48.6801 36.2345 46.3288 36.2316H46.3286L34.942 36.2316L34.9418 36.2316C32.5906 36.2345 30.3365 37.1698 28.6739 38.8323C27.0113 40.4949 26.076 42.749 26.0731 45.1002ZM52.7623 45.1006V49.0424H28.5083L28.5083 45.1006C28.5083 45.1005 28.5083 45.1005 28.5083 45.1004C28.5105 43.3948 29.1891 41.7597 30.3952 40.5536C31.6012 39.3475 33.2363 38.669 34.942 38.6667C34.942 38.6667 34.9421 38.6667 34.9422 38.6667H46.3285C46.3285 38.6667 46.3286 38.6667 46.3286 38.6667C48.0343 38.669 49.6694 39.3475 50.8755 40.5536C52.0816 41.7597 52.7601 43.3949 52.7623 45.1006Z"
          fill="#666A74"
          stroke="#666A74"
          strokeWidth="0.3"
        />
        <path
          d="M40.6351 35.2898L40.6354 35.2898C42.7092 35.2864 44.6971 34.461 46.1635 32.9946C47.63 31.5282 48.4553 29.5402 48.4588 27.4664V27.4662C48.4588 25.9188 47.9999 24.4062 47.1403 23.1196C46.2806 21.833 45.0587 20.8302 43.6291 20.238C42.1995 19.6459 40.6264 19.4909 39.1088 19.7928C37.5911 20.0947 36.1971 20.8398 35.1029 21.934C34.0088 23.0281 33.2636 24.4222 32.9618 25.9398C32.6599 27.4575 32.8148 29.0306 33.407 30.4601C33.9991 31.8897 35.0019 33.1116 36.2885 33.9713C37.5751 34.831 39.0877 35.2898 40.6351 35.2898ZM37.6413 22.9869C38.5275 22.3947 39.5693 22.0787 40.6351 22.0787C42.0635 22.0804 43.433 22.6485 44.4431 23.6585C45.4533 24.6685 46.0217 26.0379 46.0236 27.4664C46.0238 28.5321 45.708 29.574 45.116 30.4602C44.524 31.3465 43.6825 32.0373 42.6979 32.4453C41.7133 32.8533 40.6298 32.9602 39.5845 32.7524C38.5391 32.5446 37.5789 32.0314 36.8252 31.2779C36.0715 30.5243 35.5582 29.5642 35.3502 28.5189C35.1422 27.4736 35.2488 26.39 35.6566 25.4054C36.0645 24.4207 36.7551 23.579 37.6413 22.9869Z"
          fill="#666A74"
          stroke="#666A74"
          strokeWidth="0.3"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconIndependentUser;
