import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

InputComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  props: PropTypes.object,
};

InputComponent.defaultProps = {
  field: null,
  form: {
    errors: null,
    touched: null,
  },
  props: {},
};

const useStylesInput = makeStyles({
  input: {
    '& textarea': {
      wordBreak: 'break-word',
    },
  },
});

function InputComponent({
  field,
  form: { errors, touched },
  message,
  inputValue,
  maxLength,
  inputProps,
  className,
  ...props
}) {
  const classes = useStylesInput();

  return field ? (
    <TextField
      {...field}
      {...props}
      error={Boolean(message) || (touched[field.name] && Boolean(errors[field.name]))}
      helperText={message || (touched[field.name] && errors[field.name])}
      variant="outlined"
      inputProps={{
        ...inputProps,
        maxLength: maxLength,
      }}
      className={`${className} ${classes.input}`}
    />
  ) : (
    <TextField {...props} error={Boolean(message)} helperText={message} variant="outlined" />
  );
}

export default InputComponent;
