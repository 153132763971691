export const themeConfig = {
  palette: {
    primary: { main: '#272A31' },
  },
  typography: {
    wordBreak: 'break-word',

    h1: {
      fontSize: 96,
      fontWeight: 300,
      letterSpacing: '-1.5px',
      color: '#181619',
    },
    h2: {
      fontSize: 60,
      fontWeight: 300,
      letterSpacing: '-0.5px',
      color: '#181619',
    },
    h3: {
      fontSize: 48,
      fontWeight: 400,
      color: '#181619',
    },
    h4: {
      fontSize: 34,
      fontWeight: 400,
      color: '#181619',
    },
    h5: {
      fontSize: 24,
      fontWeight: 400,
      color: '#181619',
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
      color: '#181619',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      color: '#181619',
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      color: '#181619',
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
      color: '#666A74',
    },
    button: {
      fontSize: 14,
      letterSpacing: '1.25px',
    },
    caption: {
      fontSize: 12,
      letterSpacing: '1.25px',
    },
    overline: {
      fontSize: 10,
      textTransform: 'uppercase',
    },
  },
  shape: {
    borderRadius: 0,
  },
  text: {
    secondary: '#fff',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    colorActive: '#BC8F58',
    colorBlack: '#000',
    colorWhite: '#fff',
    colorBalticSea: '#181619',
    colorShuttleGray: '#666A74',
    colorAlabaster: '#F8F8F8',
    colorSeance: '#9c27b0',
    colorOrangePeel: '#ff9900',
    colorApple: '#43A047',
    colorBurntSienna: '#EF5350',
    colorViewDetail: '#1181F1',
    colorDustyGray: '#959595',
    colorGallery: '#EDEDED',
  },
};

export const themeConfigButtonExport = (theme, iconMargin = false) => ({
  buttonExport: {
    border: `1px solid ${theme.overrides.colorActive}`,
    color: theme.overrides.colorActive,
    backgroundColor: 'transparent',
    marginRight: theme.spacing(2),

    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 0.8,
    },
  },
});

export const themeConfigLink = (theme) => ({
  link: {
    ...theme.typography.body2,
    color: theme.overrides.colorViewDetail,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
});

export const themeStatusConfig = (theme, width = 0) => ({
  status: {
    padding: theme.spacing(1, 2),
    display: 'inline-block',
    textTransform: 'lowercase',
    width: width ? `${width}px` : '100%',
    textAlign: 'center',

    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  statusApproved: {
    backgroundColor: 'rgba(67, 160, 71, 0.2)',
    color: theme.overrides.colorApple,
  },
  statusRejected: {
    backgroundColor: 'rgba(239, 83, 80, 0.2)',
    color: theme.overrides.colorBurntSienna,
  },
  statusVerify: {
    backgroundColor: 'rgba(255, 153, 0, 0.2)',
    color: theme.overrides.colorOrangePeel,
  },
  statusRequesting: {
    backgroundColor: 'rgba(255, 153, 0, 0.2)',
    color: theme.overrides.colorOrangePeel,
  },
});

export const themConfigModalOverflow = () => ({
  maxHeight: 'calc(100vh - 30px)',
  overflow: 'auto',
});
