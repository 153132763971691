export const ActionTypes = {
  GET_CUSTOMER_CODE_START: 'GET_CUSTOMER_CODE_START',
  GET_CUSTOMER_CODE_SUCCESS: 'GET_CUSTOMER_CODE_SUCCESS',
  GET_CUSTOMER_CODE_ERROR: 'GET_CUSTOMER_CODE_ERROR',

  GET_USER_INFORMATION_START: 'GET_USER_INFORMATION_START',
  GET_USER_INFORMATION_SUCCESS: 'GET_USER_INFORMATION_SUCCESS',
  GET_USER_INFORMATION_ERROR: 'GET_USER_INFORMATION_ERROR',

  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',
};

export const actions = {
  getCustomerCodeStart: function (params) {
    return {
      type: ActionTypes.GET_CUSTOMER_CODE_START,
      params,
    };
  },
  getCustomerCodeSuccess: function (data) {
    return {
      type: ActionTypes.GET_CUSTOMER_CODE_SUCCESS,
      payload: data,
    };
  },
  getCustomerCodeError: function (error) {
    return {
      type: ActionTypes.GET_CUSTOMER_CODE_ERROR,
      payload: error,
    };
  },

  getUserInformationStart: function (params) {
    return {
      type: ActionTypes.GET_USER_INFORMATION_START,
      params,
    };
  },
  getUserInformationSuccess: function (data) {
    return {
      type: ActionTypes.GET_USER_INFORMATION_SUCCESS,
      payload: data,
    };
  },
  getUserInformationError: function (error) {
    return {
      type: ActionTypes.GET_USER_INFORMATION_ERROR,
      payload: error,
    };
  },

  resetTypeOfAction: function (error) {
    return {
      type: ActionTypes.RESET_TYPE_OF_ACTION,
    };
  },
};
