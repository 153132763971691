import { Box, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { renderStatusKYC, useStyles } from './style';
import { actions } from './actions';
import {
  DOCUMENT_TYPE_NAME,
  KEY_UPLOAD_HISTORY_DETAIL,
  KYC_INFO_IDENTITY_PAGE,
  KYC_INFO_PAGE,
  KYC_INFO_PAGE_STATUS,
  STATUS_KYC,
  GENDER_OPTIONS,
  CHART_EMPTY,
  KEY_UPLOAD_BUSINESS_DETAIL,
  KEY_UPLOAD_LEGAL_DETAIL,
} from '../../common/constant';
import { formatCountries, formatDate, isPresentative, renderForTypeDoc } from '../../utils';
import ZoomPhotoChildImage from '../../components/ZoomPhoto/childImage';
import _ from 'lodash';
import { useGetInfoBusiness, useGetInfoLegal } from '../../components/Information/LengalBusinessInfo';
import { Fragment } from 'react';

function KYCHistory(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const detailHistoryStore = useSelector(state => state.detailKYCHistory.kycHistory);
  const businessDocument = useGetInfoBusiness({ data: detailHistoryStore });
  const legalDocument = useGetInfoLegal({ data: detailHistoryStore });

  const detailHistory = {
    ...detailHistoryStore,
    nationality: formatCountries(detailHistoryStore?.nationality),
    idoc_nationality: formatCountries(detailHistoryStore?.idoc_nationality),
    idoc_type_convert: DOCUMENT_TYPE_NAME[detailHistoryStore?.idoc_type],
    status: renderStatusKYC(classes, detailHistoryStore?.status, t),
    idoc_gender: _.find(GENDER_OPTIONS, { key: detailHistoryStore?.idoc_gender })?.value,
    requested_at: formatDate(detailHistoryStore?.requested_at) && formatDate(detailHistoryStore.requested_at),
    submitted_at: detailHistoryStore?.submitted_at && formatDate(detailHistoryStore.submitted_at),
    reviewed_at: detailHistoryStore?.reviewed_at && formatDate(detailHistoryStore.reviewed_at),
  };
  useEffect(() => {
    document.getElementsByTagName('main')[0].style = 'padding: 26px 0';
    document.getElementById('root').style.minHeight = '100vh';
    document.getElementById('root').style.background = '#e8e8e8ab';
  });

  useEffect(() => {
    const { customerId, kycId } = props.match.params;
    dispatch(actions.getKYCHistoryStart(customerId, kycId));
  }, []);

  const renderInforWraper = item => {
    const { label, renderLabel, prop, propParams } = item;
    let showWrap = item?.showEmpty ? renderForTypeDoc(detailHistory.idoc_type, item?.renderByType) : true;
    return (detailHistory[prop] && showWrap) || (showWrap && item?.showEmpty) ? (
      <Grid key={label} item xs={6}>
        <Typography className={classes.labelInfo} variant="subtitle1">
          {label ? label : renderLabel(detailHistoryStore[propParams])}
        </Typography>
        <Typography className={classes.labelValue} variant="body2">
          {detailHistory[prop] ? detailHistory[prop] : item?.showEmpty ? CHART_EMPTY : ''}
        </Typography>
      </Grid>
    ) : (
      ''
    );
  };

  const renderInfoBySRS2 = ({ label, value, isLink }, index) => {
    let domValue = '';
    if (isLink) {
      domValue = (
        <a href={value} className={classes.labelValue} target="_blank">
          {value}
        </a>
      );
    } else {
      domValue = (
        <Typography className={classes.labelValue} variant="body2">
          {value || CHART_EMPTY}
        </Typography>
      );
    }
    return (
      <Grid key={index} item xs={12}>
        <Typography className={classes.labelInfo} variant="subtitle1">
          {label}
        </Typography>
        {domValue}
      </Grid>
    );
  };

  const renderUploadPhoto = (arrKeyPhotos, title = '') => {
    return (
      <Grid item md={4} className="grid-col-image">
        {title && (
          <Typography className={classes.kycTitleContent} variant="h6">
            {title}
          </Typography>
        )}
        {arrKeyPhotos.map(item =>
          detailHistory[`${item}_url`] ? (
            <Grid className="grid-image" key={item} item md={12}>
              <ZoomPhotoChildImage fullWith src={detailHistory[`${item}_url`]} alt={item} />
            </Grid>
          ) : (
            ''
          )
        )}
      </Grid>
    );
  };

  return (
    <Box className={classes.boxContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography className={classes.kycTitleContent} variant="h6">
            {t('history_detail.heading_contact_information')}
          </Typography>
          <Grid className={classes.gridInfoContainer} container spacing={2}>
            {KYC_INFO_PAGE.map(renderInforWraper)}
          </Grid>
          <Typography className={classes.kycTitleContent} variant="h6">
            {t('verification.text_identity_document')}
          </Typography>
          <Grid className={classes.gridInfoContainer} container spacing={2}>
            {KYC_INFO_IDENTITY_PAGE.map(renderInforWraper)}
          </Grid>
        </Grid>
        {renderUploadPhoto(KEY_UPLOAD_HISTORY_DETAIL, t('verification.text_upload_photos'))}
      </Grid>
      {/* SRS - 02 */}
      {isPresentative(detailHistory) && (
        <Fragment>
          <Grid container spacing={2}>
            <Grid item md={8}>
              <Typography className={classes.kycTitleContent} variant="h6">
                {t('verification.text_business_card')}
              </Typography>
              <Grid className={classes.gridInfoContainer} container spacing={2}>
                {businessDocument.map(renderInfoBySRS2)}
              </Grid>
            </Grid>
            {renderUploadPhoto(KEY_UPLOAD_BUSINESS_DETAIL)}
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={8}>
              <Typography className={classes.kycTitleContent} variant="h6">
                {t('verification.text_legal_entity_proof')}
              </Typography>
              <Grid className={classes.gridInfoContainer} container spacing={2}>
                {legalDocument.map(renderInfoBySRS2)}
              </Grid>
            </Grid>
            {renderUploadPhoto(KEY_UPLOAD_LEGAL_DETAIL)}
          </Grid>
        </Fragment>
      )}
      {/* END SRS - 02 */}
      <Grid container spacing={2}>
        <Grid item md={8}>
          <Typography className={classes.kycTitleContent} variant="h6">
            {t('history_detail.heading_status_history')}
          </Typography>
          <Grid className={classes.gridInfoContainer} container spacing={2}>
            {KYC_INFO_PAGE_STATUS.map(renderInforWraper)}
            {detailHistoryStore.status === STATUS_KYC.REJECTED && (
              <Grid item md={6}>
                <Typography className={classes.labelInfo} variant="subtitle1">
                  {t('history_detail.label_rejected_reason')}
                </Typography>
                <Typography className="description-pre-line" variant="body2">
                  {detailHistory.reject_reason}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default withRouter(KYCHistory);
