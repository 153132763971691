import i18n from '../i18n/i18n';

export const HTTP_RESPONSE = {
  OK: 200,
  ERROR_CODE_401: 401,
};

export const TYPE_EXTRA_INFO = {
  NAME: 'name',
  VALUE: 'value',
};

export const TYPE_OF_ANT_DESIGN = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  IMG_DONE: 'done',
};

export const languages = [
  {
    key: 'jp',
    value: 'Japanese',
  },
  {
    key: 'en',
    value: 'English',
  },
];

export const GENDER_OPTIONS = [
  {
    key: 'MALE',
    value: i18n.t('common.text_gender_male'),
  },
  {
    key: 'FEMALE',
    value: i18n.t('common.text_gender_female'),
  },
];

export const getGenderOptions = t => [
  {
    key: 'MALE',
    value: t('common.text_gender_male'),
  },
  {
    key: 'FEMALE',
    value: t('common.text_gender_female'),
  },
];

export const MENU_ITEMS = t => {
  return [
    {
      text: t('common.home'),
      link: '#banner',
    },
    {
      text: t('header.text_how_to_use'),
      link: '#how-to-use',
    },
    {
      text: t('header.text_access_kyc_link'),
      link: '#access-kyc-link',
    },
  ];
};

export const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

export const OTP_TIME = 120;

export const RESPONSE_STATUS = {
  SUCCESS: true,
  ERROR: false,
};

export function getVerificationSteps(t) {
  return [
    t('verification.text_contact_information'),
    t('verification.text_identity_document'),
    t('verification.text_upload_photos'),
    t('verification.text_review_submit'),
  ];
}

export const DOCUMENT_TYPE = {
  DRIVER_LICENSE: 'DRIVER_LICENSE',
  PASSPORT: 'PASSPORT',
  BASIC_RESIDENT_REGISTER: 'BASIC_RESIDENT_REGISTER',
  HEALTH_INSURANCE_CARD: 'HEALTH_INSURANCE_CARD',
  RESIDENCE_CARD: 'RESIDENCE_CARD',
  FOREIGNER_REGISTRATION_CARD: 'FOREIGNER_REGISTRATION_CARD',
  SPECIAL_PERMANENT_RESIDENT: 'SPECIAL_PERMANENT_RESIDENT',
};

export const DOCUMENT_TYPE_NAME = {
  DRIVER_LICENSE: 'Driver License',
  PASSPORT: 'Passport',
  BASIC_RESIDENT_REGISTER: 'Basic Resident Register',
  HEALTH_INSURANCE_CARD: 'Health Insurance Card',
  RESIDENCE_CARD: 'Residence Card',
  FOREIGNER_REGISTRATION_CARD: 'Foreigner Registration Card',
  SPECIAL_PERMANENT_RESIDENT: 'Special Permanent Resident',
};

export const GENDER_TYPE = {
  MALE: i18n.t('common.text_gender_male'),
  FEMALE: i18n.t('common.text_gender_female'),
};

export const DATE_FORMAT = 'yyyy/MM/dd';
export const DATE_FORMAT_FIELD = 'YYYY-MM-DD';

export const ACCEPT_IMAGE = '.jpg,.jpeg,.png';
export const ACCEPT_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
export const IMAGE_SIZE = 10 * 1024 * 1024;

export const REGEX_NOT_NUMBER = /[^0-9]*/;

export const MAX_LENGTH_INPUT = 254;
export const MAX_LENGTH_TEXTAREA = 1000;

export const PARAMS_FILTER_DEFAULT = {
  order: null,
  orderBy: null,
  page: 0,
  pageSize: 10,
};

export const KEY_UPLOAD_HISTORY_DETAIL = ['idoc_photo1', 'idoc_photo2', 'idoc_video'];
export const KEY_UPLOAD_LEGAL_DETAIL = ['legal_entity_photo1', 'legal_entity_photo2'];
export const KEY_UPLOAD_BUSINESS_DETAIL = ['business_card_photo1', 'business_card_photo2'];
export const KEY_UPLOAD = [...KEY_UPLOAD_HISTORY_DETAIL, ...KEY_UPLOAD_LEGAL_DETAIL, ...KEY_UPLOAD_BUSINESS_DETAIL];

export const DOCUMENT_TYPE_OPTIONS = [
  {
    key: 'DRIVER_LICENSE',
    value: i18n.t('home.text_driver_license'),
  },
  {
    key: 'PASSPORT',
    value: i18n.t('home.text_passport'),
  },
  {
    key: 'BASIC_RESIDENT_REGISTER',
    value: i18n.t('home.text_basic_resident_register'),
  },
  {
    key: 'HEALTH_INSURANCE_CARD',
    value: i18n.t('home.text_health_insurance_card'),
  },
  {
    key: 'RESIDENCE_CARD',
    value: i18n.t('home.text_residence_card'),
  },
  {
    key: 'FOREIGNER_REGISTRATION_CARD',
    value: i18n.t('home.text_foreigner_registration_card'),
  },
  {
    key: 'SPECIAL_PERMANENT_RESIDENT',
    value: i18n.t('home.text_special_permanent_resident'),
  },
];

export const KYC_INFO_IDENTITY_PAGE = [
  {
    label: i18n.t('verification.text_identity_document'),
    showEmpty: true,
    prop: 'idoc_type_convert',
    renderByType: 'all',
  },
  {
    propParams: 'idoc_type',
    renderLabel: idoc_type => i18n.t(`list_kyc.idoc_number_${idoc_type}`),
    showEmpty: true,
    prop: 'idoc_number',
    renderByType: [
      'DRIVER_LICENSE',
      'PASSPORT',
      'HEALTH_INSURANCE_CARD',
      'RESIDENCE_CARD',
      'FOREIGNER_REGISTRATION_CARD',
      'SPECIAL_PERMANENT_RESIDENT',
    ],
  },
  {
    label: i18n.t('verification.text_full_name'),
    showEmpty: true,
    prop: 'idoc_full_name',
    renderByType: [
      'DRIVER_LICENSE',
      'BASIC_RESIDENT_REGISTER',
      'HEALTH_INSURANCE_CARD',
      'RESIDENCE_CARD',
      'FOREIGNER_REGISTRATION_CARD',
      'SPECIAL_PERMANENT_RESIDENT',
    ],
  },
  {
    label: i18n.t('list_kyc.idoc_surname'),
    showEmpty: true,
    prop: 'idoc_surname',
    renderByType: ['PASSPORT'],
  },
  {
    label: i18n.t('list_kyc.idoc_given_name'),
    showEmpty: true,
    prop: 'idoc_given_name',
    renderByType: ['PASSPORT'],
  },
  {
    label: i18n.t('list_kyc.idoc_nationality'),
    showEmpty: true,
    prop: 'idoc_nationality',
    renderByType: ['PASSPORT', 'RESIDENCE_CARD', 'SPECIAL_PERMANENT_RESIDENT', 'FOREIGNER_REGISTRATION_CARD'],
  },
  {
    label: i18n.t('verification.text_date_of_birth'),
    showEmpty: true,
    prop: 'idoc_birthday',
    renderByType: [
      'DRIVER_LICENSE',
      'PASSPORT',
      'BASIC_RESIDENT_REGISTER',
      'HEALTH_INSURANCE_CARD',
      'RESIDENCE_CARD',
      'FOREIGNER_REGISTRATION_CARD',
      'SPECIAL_PERMANENT_RESIDENT',
    ],
  },
  {
    label: i18n.t('list_kyc.idoc_gender'),
    showEmpty: true,
    prop: 'idoc_gender',
    renderByType: [
      'PASSPORT',
      'BASIC_RESIDENT_REGISTER',
      'HEALTH_INSURANCE_CARD',
      'RESIDENCE_CARD',
      'FOREIGNER_REGISTRATION_CARD',
      'SPECIAL_PERMANENT_RESIDENT',
    ],
  },
  {
    label: i18n.t('verification.text_date_of_issue'),
    showEmpty: true,
    prop: 'idoc_issued_date',
    renderByType: ['DRIVER_LICENSE', 'PASSPORT'],
  },
  {
    label: i18n.t('list_kyc.idoc_applicable_date'),
    showEmpty: true,
    prop: 'idoc_applicable_date',
    renderByType: ['HEALTH_INSURANCE_CARD'],
  },
  {
    label: i18n.t('verification.text_date_of_expiry'),
    showEmpty: true,
    prop: 'idoc_expired_date',
    renderByType: [
      'DRIVER_LICENSE',
      'PASSPORT',
      'BASIC_RESIDENT_REGISTER',
      'HEALTH_INSURANCE_CARD',
      'RESIDENCE_CARD',
      'FOREIGNER_REGISTRATION_CARD',
      'SPECIAL_PERMANENT_RESIDENT',
    ],
  },
  {
    label: i18n.t('list_kyc.idoc_supply_no'),
    showEmpty: true,
    prop: 'idoc_supply_no',
    renderByType: ['HEALTH_INSURANCE_CARD'],
  },
  {
    label: i18n.t('verification.text_address'),
    showEmpty: true,
    prop: 'idoc_address',
    renderByType: ['DRIVER_LICENSE', 'BASIC_RESIDENT_REGISTER', 'HEALTH_INSURANCE_CARD', 'FOREIGNER_REGISTRATION_CARD', 'SPECIAL_PERMANENT_RESIDENT'],
  },
  {
    label: i18n.t('history_detail.label_kyc_status'),
    showEmpty: true,
    prop: 'status',
    renderByType: 'all',
  },
];

export const KYC_INFO_PAGE = [
  {
    label: i18n.t('verification.text_full_name'),
    prop: 'full_name',
  },
  {
    label: i18n.t('verification.text_email'),
    prop: 'email',
  },
  {
    label: i18n.t('verification.text_date_of_birth'),
    prop: 'birthday',
  },
  {
    label: i18n.t('home.text_phone_number'),
    prop: 'phone',
  },
  {
    label: i18n.t('verification.text_nationality'),
    prop: 'nationality',
  },
  {
    label: i18n.t('home.text_residential_address'),
    prop: 'residential_address',
  },
];

export const STATUS_KYC = {
  PENDING: 'REQUESTING',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
};

export const OTP_REPRESENT = {
  UNDEFINE: 'UNDEFINE',
  LEGAL_ENTITY: 'LEGAL_ENTITY',
  INDIVIDUAL: 'INDIVIDUAL',
};

export const KYC_INFO_PAGE_STATUS = [
  {
    label: i18n.t('history_detail.label_requested_by'),
    prop: 'requested_by_name',
  },
  {
    label: i18n.t('history_detail.label_requested_date'),
    prop: 'requested_at',
  },
  {
    label: i18n.t('history_detail.label_submitted_by'),
    prop: 'submitted_by_name',
  },
  {
    label: i18n.t('history_detail.label_submitted_date'),
    prop: 'submitted_at',
  },
  {
    propParams: 'status',
    renderLabel: status => (status === STATUS_KYC.REJECTED ? i18n.t('history_detail.label_rejected_by') : i18n.t('history_detail.label_approved_by')),
    prop: 'reviewed_by_name',
  },
  {
    propParams: 'status',
    renderLabel: status =>
      status === STATUS_KYC.REJECTED ? i18n.t('history_detail.label_rejected_at') : i18n.t('history_detail.label_approved_date'),
    prop: 'reviewed_at',
  },
];

export const COUNTRY_OPTIONS = ['VN', 'JP', 'GB', 'CN'];
export const DEFAULT_COUNTRY = 'JP';
export const END_POINT_EXPORT = {
  KYC_EDIT_REQUEST: '/customer/kyc/history/export',
  KYC_HISTORY: customerCode => `/manage/customer/${customerCode}/export-kyc-history`,
  PROFILE_HISTORY: customerCode => `/manage/customer/${customerCode}/export-profile-edit-history`,
};

export const getDefaultPageSize = t => [
  {
    value: 10,
    label: t('component.text_page_size_options', { size: 10 }),
  },
  {
    value: 20,
    label: t('component.text_page_size_options', { size: 20 }),
  },
  {
    value: 30,
    label: t('component.text_page_size_options', { size: 30 }),
  },
];

export const PERMISSION_DENIED = 'denied';

export const getKYCStatusOptions = t => [
  {
    key: 'REQUESTING',
    value: t('verification.text_status_requesting'),
  },
  {
    key: 'VERIFYING',
    value: t('verification.text_status_verifying'),
  },
  {
    key: 'REJECTED',
    value: t('verification.text_status_rejected'),
  },
  {
    key: 'APPROVED',
    value: t('verification.text_status_approved'),
  },
];

export const getDocumentTypeOptions = (t = { i18n }) => [
  {
    key: 'DRIVER_LICENSE',
    value: t('home.text_driver_license'),
  },
  {
    key: 'PASSPORT',
    value: t('home.text_passport'),
  },
  {
    key: 'BASIC_RESIDENT_REGISTER',
    value: t('home.text_basic_resident_register'),
  },
  {
    key: 'HEALTH_INSURANCE_CARD',
    value: t('home.text_health_insurance_card'),
  },
  {
    key: 'RESIDENCE_CARD',
    value: t('home.text_residence_card'),
  },
  {
    key: 'FOREIGNER_REGISTRATION_CARD',
    value: t('home.text_foreigner_registration_card'),
  },
  {
    key: 'SPECIAL_PERMANENT_RESIDENT',
    value: t('home.text_special_permanent_resident'),
  },
];

export const getDocumentTypeOptionsForJapan = (t = { i18n }) => [
  {
    key: 'DRIVER_LICENSE',
    value: t('home.text_driver_license'),
  },
  {
    key: 'PASSPORT',
    value: t('home.text_passport'),
  },
  {
    key: 'BASIC_RESIDENT_REGISTER',
    value: t('home.text_basic_resident_register'),
  },
  {
    key: 'HEALTH_INSURANCE_CARD',
    value: t('home.text_health_insurance_card'),
  },
];

export const STATUS_LOGIN = {
  DEFAULT: 'DEFAULT',
  VERIFY_OTP: 'VERIFY_OTP',
  SELECT_REPRESENTATIVE: 'SELECT_REPRESENTATIVE',
};

export const REPRESENTATIVE_TYPE = {
  LEGAL_ENTITY: 'LEGAL_ENTITY',
  INDIVIDUAL: 'INDIVIDUAL',
};

export const CHART_EMPTY = '---';
