import React, { memo, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, SwipeableDrawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useDispatch } from 'react-redux';

import SelectComponent from '../../../../components/Select';
import LOGO from '../../../../resources/images/logo.png';
import { getCurrentLanguage } from '../../../../i18n/i18n';
import { iOS } from '../../../../common/constant';
import { actions as authActions } from '../../../login/actions';
import { getLanguages, getLanguagesShorten } from '../../../../utils';

function HeaderPrivate() {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(getCurrentLanguage());
  const languages = getLanguages(t);
  const languagesShorten = getLanguagesShorten(t);

  const [width, setWidth] = useState(window.innerWidth);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChangeLanguage = e => {
    const { value } = e.target;
    i18n.changeLanguage(value);
    localStorage.setItem('language', value);
    setCurrentLanguage(value);
  };

  const handleLogout = () => {
    dispatch(authActions.logout());
  };

  console.log('====================>', currentLanguage);

  return (
    <div className="header">
      <div className="header__container container">
        <Grid container>
          <Grid item xs={6} md={4} className="header__left">
            <a href="/">
              <img className="logo" src={LOGO} alt="glocaly" />
            </a>
          </Grid>
          <Grid item xs={6} md={8} className="header__right">
            <Fragment>
              <SelectComponent
                className="button--language"
                variant="outlined"
                menuClass="list--language"
                value={currentLanguage}
                options={width > 640 ? languages : languagesShorten}
                onChange={handleChangeLanguage}
              />
              <IconButton onClick={handleLogout} className="button button-logout">
                <ExitToAppIcon />
              </IconButton>
            </Fragment>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default memo(HeaderPrivate);
