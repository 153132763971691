import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 12a.75.75 0 01.745.662l.005.088v1.5a2.25 2.25 0 01-2.118 2.246l-.132.004H3.75a2.25 2.25 0 01-2.246-2.118L1.5 14.25v-1.5a.75.75 0 011.495-.088L3 12.75v1.5c0 .385.29.702.663.745L3.75 15h10.5a.75.75 0 00.745-.662L15 14.25v-1.5a.75.75 0 01.75-.75zM8.889 3.758l.043-.005L9 3.75l.056.002.095.013.083.022.083.033.074.04.078.055.061.055 2.25 2.25a.75.75 0 01-1.06 1.06l-.97-.97v4.94a.75.75 0 01-1.5 0V6.31l-.97.97a.75.75 0 01-1.06-1.06l2.25-2.25a.756.756 0 01.084-.073l.083-.053.085-.04.079-.027.088-.019z"
        fill="#666A74"
      />
    </svg>
  );
}

export default SvgComponent;
