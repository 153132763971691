import React from 'react';

function HeadingComponent({ text }) {
  return (
    <div className="heading">
      {text}
      <div className="heading__divider"></div>
    </div>
  );
}

export default HeadingComponent;
