import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../utils';
import ContentChild from '../../ContentChild';

function DriverLicenseContent({ values }) {
  const { t } = useTranslation();
  const { idoc_full_name, idoc_birthday, idoc_address, idoc_issued_date, idoc_expired_date, idoc_number } = values;
  const documentContents = [
    {
      label: t('verification.text_full_name'),
      value: idoc_full_name,
    },
    {
      label: t('verification.text_date_of_birth'),
      value: formatDate(idoc_birthday),
    },
    {
      label: t('verification.text_address'),
      value: idoc_address,
    },
    {
      label: t('verification.text_date_of_issue'),
      value: formatDate(idoc_issued_date),
    },
    {
      label: t('verification.text_date_of_expiry'),
      value: formatDate(idoc_expired_date),
    },
    {
      label: t('verification.text_license_number'),
      value: idoc_number,
    },
  ];

  return (
    <Fragment>
      {documentContents.map((document, index) => (
        <ContentChild key={index} label={document.label} value={document.value} />
      ))}
    </Fragment>
  );
}

export default DriverLicenseContent;
