import { combineReducers } from 'redux';
import homeReducer from '../pages/home/reducer';
import loginReducer from '../pages/login/reducer';
import appReducer from '../pages/layout/reducer';
import kycReducer from '../pages/verification/reducer';
import kycHistoryReducer from '../pages/kycHistory/reducer';
import detailKYCHistoryReducer from '../pages/kycHistoryDetail/reducer';

export default combineReducers({
  app: appReducer,
  home: homeReducer,
  auth: loginReducer,
  kyc: kycReducer,
  kycHistory: kycHistoryReducer,
  detailKYCHistory: detailKYCHistoryReducer,
});
