import { api } from './api';

export const getCustomerCodeAPI = ({ contact, contactMethod }) => {
  return api.get('/customer/get-code', {
    contact_address: contact,
    contact_method: contactMethod,
  });
};

export const createOTPCodeAPI = (body) => {
  return api.post('customer/otp/request', body);
};

export const resendOTPCodeAPI = (id) => {
  return api.post(`customer/otp/resend?request_id=${id}`);
};

export const verifyOTPCodeAPI = (body) => {
  return api.post('customer/otp/verify', body);
};
