import moment from 'moment';
import { DOCUMENT_TYPE_NAME, GENDER_TYPE } from '../common/constant';
import { countries } from './countries';
import { ROUTE_HIDE_LAYOUT } from '../Routes';
import React, { Fragment } from 'react';
import IconFlagEnglish from '../resources/images/flag-english.png';
import IconFlagJapan from '../resources/images/flag-japan.png';
import IconChinese from '../resources/images/flag-chinese.png';

import { Typography } from '@material-ui/core';

export function startTimer(countTimer, duration, display, callback = () => {}) {
  var timer = duration,
    minutes,
    seconds;
  countTimer = setInterval(function () {
    minutes = parseInt(timer / 60, 10);
    seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    display.textContent = minutes + ':' + seconds;

    if (--timer < 0) {
      callback();
      window.clearInterval(countTimer);
    }
  }, 1000);
}

export const isFile = input => 'File' in window && input instanceof File;
export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function isEmpty(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export function formatCountries(nationality = '') {
  return countries.find(item => item.key === nationality)?.value;
}

export function formatDate(date) {
  return moment(date).format('YYYY/MM/DD');
}

export function formatGender(type) {
  return GENDER_TYPE[type];
}

export const getGender = (key, t) => {
  switch (key) {
    case 'MALE':
      return t('common.text_gender_male');
    case 'FEMALE':
      return t('common.text_gender_female');
    default:
      return '';
  }
};

export function formatDocument(document) {
  return DOCUMENT_TYPE_NAME[document];
}

export const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const isStatusVerifying = field => {
  return field === 'VERIFYING';
};

export const isStatusApproved = field => {
  return field === 'APPROVED';
};

export const isStatusRequesting = field => {
  return field === 'REQUESTING';
};

export const isStatusRejected = field => {
  return field === 'REJECTED';
};

export const changeTable = (setFilters, filters) => ({
  handleChangePage: page => {
    setFilters({ ...filters, page });
  },
  handleChangeRowsPerPage: pageSize => {
    setFilters({
      ...filters,
      page: 0,
      pageSize,
    });
  },
  handleFilters: data => {
    setFilters({
      ...filters,
      ...data,
    });
  },
  handleSearch: data => {
    setFilters({
      ...filters,
      ...data,
    });
  },
});

export function dateNowNoSpecial() {
  return moment(new Date()).format('YYYYMMDDHHmmss');
}

export const checkLocationHideLayout = () => {
  return !ROUTE_HIDE_LAYOUT.every(path => window.location.pathname.indexOf(path) === -1);
};

export const getLanguages = t => [
  {
    key: 'en',
    value: (
      <Fragment>
        <img src={IconFlagEnglish} alt="flag english" />
        <Typography variant="caption" className="language">
          {t('common.text_language_english')}
        </Typography>
      </Fragment>
    ),
  },
  {
    key: 'jp',
    value: (
      <Fragment>
        <img src={IconFlagJapan} alt="flag english" />
        <Typography variant="caption" className="language">
          {t('common.text_language_japan')}
        </Typography>
      </Fragment>
    ),
  },
  {
    key: 'cn',
    value: (
      <Fragment>
        <img src={IconChinese} alt="flag english" />
        <Typography variant="caption" className="language">
          {t('common.text_chinese_cn')}
        </Typography>
      </Fragment>
    ),
  },
  {
    key: 'scn',
    value: (
      <Fragment>
        <img src={IconChinese} alt="flag english" />
        <Typography variant="caption" className="language">
          {t('common.text_chinese_cs')}
        </Typography>
      </Fragment>
    ),
  },
];

export const getLanguagesShorten = t => [
  {
    key: 'en',
    value: (
      <Fragment>
        <img src={IconFlagEnglish} alt="flag english" />
        <Typography variant="caption" className="language">
          EN
        </Typography>
      </Fragment>
    ),
  },
  {
    key: 'jp',
    value: (
      <Fragment>
        <img src={IconFlagJapan} alt="flag english" />
        <Typography variant="caption" className="language">
          JP
        </Typography>
      </Fragment>
    ),
  },
  {
    key: 'cn',
    value: (
      <Fragment>
        <img src={IconChinese} alt="flag english" />
        <Typography variant="caption" className="language">
          CN
        </Typography>
      </Fragment>
    ),
  },
  {
    key: 'scn',
    value: (
      <Fragment>
        <img src={IconChinese} alt="flag english" />
        <Typography variant="caption" className="language">
          CNS
        </Typography>
      </Fragment>
    ),
  },
];

export const camelCaseToUnderscore = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const renderForTypeDoc = (idoc_type, renderByType = []) => {
  return renderByType === 'all' ? true : renderByType.includes(idoc_type);
};

export const isPresentative = customer => {
  return customer?.business_card_pic && customer?.business_card_company && customer?.business_card_company_addr && customer?.business_card_phone;
};

export const clearCacheForImage = (file, url) => {
  return isFile(file) ? url : `${url}&t=${new Date().getTime()}`;
};
