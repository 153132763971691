import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, makeStyles, Button } from '@material-ui/core';

import { SimpleModal } from '../Modal';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    maxWidth: 400,
    padding: theme.spacing(4, 5),

    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
}));

function ConfirmModalComponent(props) {
  const { isOpeningModal, toggleModal, onConfirm } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <SimpleModal isOpeningModal={isOpeningModal} toggleModal={toggleModal} classNameBox={classes.root} width="unset">
      <Typography variant="h6">{t('component.modal_confirm_title')}</Typography>
      <Typography variant="body1">{t('component.modal_confirm_description')}</Typography>
      <Button className="button button-gradient" fullWidth onClick={onConfirm}>
        {t('common.text_yes')}
      </Button>
      <Button className="button button-transparent" fullWidth onClick={toggleModal}>
        {t('component.button_cancel')}
      </Button>
    </SimpleModal>
  );
}

export default ConfirmModalComponent;
