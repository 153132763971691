import React from "react";
import { Typography } from "@material-ui/core";
import NODATA from "../../resources/images/no_data.png";

const EmptyData = ({ text }) => (
  <div className="box-empty-wrap">
    <img src={NODATA} alt="img_no_data" />
    <Typography variant="subtitle1">{text}</Typography>
  </div>
);

export default EmptyData;
