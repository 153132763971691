import { Box, IconButton, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { SimpleModal } from "../Modal";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > div": {
      padding: 0,
      backgroundColor: "transparent",
    },
  },
  boxAction: {
    textAlign: "center",
    backgroundColor: "transparent",

    "& > button:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
  },
  boxImage: {
    textAlign: "center",
    maxHeight: 800,
    maxWidth: 800,
  },
  buttonRotate: {
    color: theme.overrides.colorWhite,
  },
}));

function ZoomPhotoChildImage({ src, visible, width, fullWith }) {
  const [rotation, setRotation] = useState(0);
  const [isOpening, setIsOpening] = useState(visible ? visible : false);
  const classes = useStyles();

  const rotateRight = () => {
    let newRotation = rotation + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    setRotation(newRotation);
  };

  const rotateLeft = () => {
    let newRotation = rotation - 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    setRotation(newRotation);
  };

  const onToggleImage = () => {
    setIsOpening(!isOpening);
  };

  useEffect(() => {
    if (!isOpening) {
      setRotation(0);
    }
  }, [isOpening]);

  return (
    <>
      <SimpleModal
        isOpeningModal={isOpening}
        toggleModal={onToggleImage}
        closeHeader
        className={classes.root}
        width={"unset"}
      >
        <Box className={classes.boxImage}>
          <img
            style={{
              transform: `rotate(${rotation}deg)`,
              maxWidth: "100%",
              height: "auto",
              minWidth: `${width}px`,
            }}
            src={src}
            alt="current-zoom"
          />
        </Box>
        <Box className={classes.boxAction}>
          <IconButton onClick={rotateLeft} className={classes.buttonRotate}>
            <RotateLeftIcon />
          </IconButton>
          <IconButton onClick={rotateRight} className={classes.buttonRotate}>
            <RotateRightIcon />
          </IconButton>
        </Box>
      </SimpleModal>
      <img onClick={onToggleImage} style={{ width: fullWith ? "100%" : `${width}px` }} src={src} alt="root-zoom" />
    </>
  );
}

export default ZoomPhotoChildImage;
