import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  customer: {
    censored_email: '',
    censored_phone: '',
  },
  session: {},
  currentUser: {},
  type: '',
  error: '',
  currentUserTemp: {},
  typeRepresentative: '',
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_OTP_CODE_SUCCESS:
      return {
        ...state,
        type: action.type,
        session: action.payload,
      };
    case ActionTypes.CREATE_OTP_CODE_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case ActionTypes.RESEND_OTP_CODE_SUCCESS:
      return {
        ...state,
        session: action.payload,
      };
    case ActionTypes.RESEND_OTP_CODE_ERROR:
      return {
        ...state,
        type: action.type,
      };

    case ActionTypes.VERIFY_OTP_CODE_SUCCESS:
      return {
        ...state,
        currentUserTemp: action.payload,
        type: action.type,
      };
    case ActionTypes.COMPLETE_LOGIN:
      return {
        ...state,
        currentUser: state.currentUserTemp,
        typeRepresentative: action.typeRepresentative,
      };
    case ActionTypes.VERIFY_OTP_CODE_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case ActionTypes.USER_LOGOUT:
      return {
        ...state,
        ...DEFAULT_STATE,
      };
    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: '',
      };
    default:
      return state;
  }
};
