import { ActionTypes } from './actions';

const DEFAULT_STATE = {
    listData: {
        data: [],
    },
    dataChange: [],
};

export default (state = DEFAULT_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.GET_LIST_REQUEST_KYC_START:
            return {
                ...state,
                dataChange: [],
            };
        case ActionTypes.GET_DETAIL_KYC_SUCCESS:
            return {
                ...state,
                dataChange: payload,
            };
        case ActionTypes.GET_LIST_REQUEST_KYC_SUCCESS:
            return {
                ...state,
                listData: payload,
            };
        default:
            return state;
    }
};
