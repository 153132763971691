import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FooterComponent from '../../../components/Footer';
import HeaderCommon from './components/header';
import { checkLocationHideLayout } from '../../../utils';

function PrivateLayout({ component: Component }) {
  const isAuthenticated = useSelector((state) => !!state.auth.currentUser?.access_token);
  const hideLayout = checkLocationHideLayout();

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className="layout">
     {!hideLayout && <HeaderCommon />}
      <main className="main">
        <Component />
      </main>
      <FooterComponent />
    </div>
  );
}

export default PrivateLayout;
