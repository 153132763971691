import React, { memo, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, List, ListItem, SwipeableDrawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, useLocation, matchPath } from 'react-router-dom';
import SelectComponent from '../../../../components/Select';
import LOGO from '../../../../resources/images/logo.png';
import i18n, { getCurrentLanguage } from '../../../../i18n/i18n';
import { iOS, MENU_ITEMS } from '../../../../common/constant';
import { getLanguages, getLanguagesShorten } from '../../../../utils';

function HeaderCommon() {
  const { t } = useTranslation();
  const location = useLocation();
  const [currentLanguage, setCurrentLanguage] = useState(getCurrentLanguage());

  const languages = getLanguages(t);
  const languagesShorten = getLanguagesShorten(t);

  const [width, setWidth] = useState(window.innerWidth);
  const [isOpeningDrawer, setIsOpeningDrawer] = useState(false);
  const [menuActive, setMenuActive] = useState('#banner');
  const [showListMenuItem, setShowListMenuItem] = useState(true);

  const handleCloseDrawer = () => {
    setIsOpeningDrawer(false);
  };

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    const match = matchPath(location.pathname, {
      path: '/login',
      exact: true,
    });

    if (Boolean(match)) {
      setShowListMenuItem(false);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [location]);

  useEffect(() => {
    i18n.on('languageChanged', handleCloseDrawer);

    return () => {
      i18n.off('languageChanged', handleCloseDrawer);
    };
  }, [i18n]);

  const handleChangeLanguage = e => {
    const { value } = e.target;
    i18n.changeLanguage(value);
    localStorage.setItem('language', value);
    setCurrentLanguage(value);
  };

  const toggleDrawer = () => setIsOpeningDrawer(!isOpeningDrawer);
  const listMenuItems = MENU_ITEMS(t);

  const scrollTo = (e, id) => {
    e.preventDefault();
    const element = document.querySelector(id);
    element.scrollIntoView();
    setMenuActive(id);
  };
  console.log('====================>', currentLanguage);

  return (
    <div className="header">
      <div className="header__container container">
        <Grid container>
          <Grid item xs={6} md={4} className="header__left">
            <Link to="/">
              <img className="logo" src={LOGO} alt="glocaly" />
            </Link>
          </Grid>
          <Grid item xs={6} md={8} className="header__right">
            {width > 1024 ? (
              <Fragment>
                {showListMenuItem && (
                  <ul className="list">
                    {listMenuItems.map(({ text, link }) => (
                      <li key={link} className="list__item">
                        <a href={link}>{text}</a>
                      </li>
                    ))}
                  </ul>
                )}
                <SelectComponent
                  className="button--language"
                  variant="outlined"
                  menuClass="list--language"
                  value={currentLanguage}
                  options={languages}
                  onChange={handleChangeLanguage}
                />
              </Fragment>
            ) : (
              <Fragment>
                {showListMenuItem && (
                  <Fragment>
                    <IconButton onClick={toggleDrawer} className="button-menu">
                      <MenuIcon />
                    </IconButton>
                    <SwipeableDrawer
                      disableBackdropTransition={!iOS}
                      disableDiscovery={iOS}
                      anchor="right"
                      open={isOpeningDrawer}
                      onOpen={toggleDrawer}
                      onClose={toggleDrawer}
                      className="drawer-menu">
                      <List className="list">
                        {listMenuItems.map(({ text, link }, index) => (
                          <ListItem button key={index}>
                            <Link
                              className={`list__item ${link === menuActive ? 'activated' : ''}`}
                              to={link}
                              onClick={e => {
                                scrollTo(e, link);

                                setTimeout(() => {
                                  toggleDrawer();
                                }, 100);
                              }}>
                              {text}
                            </Link>
                          </ListItem>
                        ))}
                      </List>
                      <SelectComponent
                        className="button--language"
                        variant="outlined"
                        menuClass="list--language"
                        value={currentLanguage}
                        options={languages}
                        onChange={handleChangeLanguage}
                      />
                    </SwipeableDrawer>
                  </Fragment>
                )}
                {!showListMenuItem && (
                  <SelectComponent
                    className="button--language"
                    variant="outlined"
                    menuClass="list--language"
                    value={currentLanguage}
                    options={languagesShorten}
                    onChange={handleChangeLanguage}
                  />
                )}
              </Fragment>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default memo(HeaderCommon);
