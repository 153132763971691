import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';

import { SimpleModal } from '../../../../components/Modal';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import UploadIcon from '../../../../resources/images/IconUpload';
import { getBase64, isFile, isMobileDevice } from '../../../../utils';
import Webcam from './webcam';
import { ACCEPT_IMAGE, ACCEPT_IMAGE_FORMATS, IMAGE_SIZE } from '../../../../common/constant';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    backgroundColor: 'transparent',
    border: 'none',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    height: '100%',
    border: '1px dashed rgba(0, 0, 0, 0.12)',
    minHeight: 273,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentError: {
    borderColor: '#EF5350',
    fontSize: 12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 14,
  },
  description: {
    fontSize: 12,
    textAlign: 'center',
    wordBreak: 'break-word',
  },
  buttonContainer: {
    padding: '8px 0',
    '& > .button-label': {
      marginLeft: 0,
    },
  },
  button: {
    textTransform: 'unset',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    width: '100%',
    fontSize: 12,
    color: '#666A74',
    fontFamily: 'RobotoMedium',
  },
  input: {
    display: 'none',
  },
  label: {
    width: '100%',
  },
  errorMessage: {
    color: '#EF5350',
  },
  containerModal: {
    backgroundColor: theme.overrides.colorBlack,
    padding: theme.spacing(1, 2, 3),
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
  },
  boxImage: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  removeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: '10',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      color: theme.overrides.colorWhite,
    },
  },
}));

function MediaCard({ cardID, imgSrc, description, card, cardUrl, cardFile, fileName }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [fileSrc, setFileSrc] = useState(imgSrc);
  const [isOpeningWebcamModal, setIsOpeningWebcamModal] = useState(false);
  const [deviceHasCamera, setDeviceHasCamera] = useState(false);
  const { values, errors, touched, setFieldTouched, setFieldValue, resetForm } = useFormikContext();
  const isDefaultImage = fileSrc === imgSrc;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (values[cardUrl.name] && !isFile(values[cardFile.name])) {
      setFileSrc(values[cardUrl.name]);
    } else if (isFile(values[cardFile.name])) {
      getBase64(values[cardFile.name], (uri) => {
        setFileSrc(uri);
      });
    }
  }, [fileSrc, values, cardUrl, cardFile]);

  useEffect(() => {
    detectWebcam(function (hasWebcam) {
      setDeviceHasCamera(hasWebcam);
    });
  }, []);

  function detectWebcam(callback) {
    let md = navigator.mediaDevices;
    if (!md || !md.enumerateDevices) return callback(false);
    md.enumerateDevices().then((devices) => {
      callback(devices.some((device) => 'videoinput' === device.kind));
    });
  }

  const onTouchButton = (fieldName) => () => {
    setFieldTouched(fieldName, true);
  };

  const validateFile = (file) => {
    if (!file) {
      return false;
    }

    if (!ACCEPT_IMAGE_FORMATS.includes(file.type)) {
      enqueueSnackbar(t('message.MSG_18'), { variant: 'error' });
      return false;
    }
    if (file.size > IMAGE_SIZE) {
      enqueueSnackbar(t('message.MSG_19'), { variant: 'error' });
      return false;
    }
    return true;
  };

  const handleUpload = (event) => {
    var file = event.target.files[0];
    const fileExtension = validateFile(file);
    try {
      if (fileExtension) {
        setFieldValue(cardFile.name, file);
        getBase64(file, (Uri) => {
          setFieldValue(cardUrl.name, Uri);
          setFileSrc(Uri);
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleTakePhotoUpload = (file) => {
    toggleWebcamModal();
    try {
      setFieldValue(cardFile.name, file);
      getBase64(file, (Uri) => {
        setFieldValue(cardUrl.name, Uri);
        setFileSrc(Uri);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const removeImage = () => {
    resetForm({
      values: {
        ...values,
        [cardFile.name]: '',
        [cardUrl.name]: '',
        [card.name]: '',
      },
    });
    setFileSrc(imgSrc);
  };

  const toggleWebcamModal = useCallback(() => {
    setIsOpeningWebcamModal(!isOpeningWebcamModal);
  }, [isOpeningWebcamModal]);

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent
        className={clsx(classes.content, {
          [classes.contentError]: touched[card.name] && !!errors[card.name],
        })}
      >
        <Box className={classes.boxImage}>
          {!isDefaultImage && (
            <IconButton onClick={removeImage} className={classes.removeIcon}>
              <Close />
            </IconButton>
          )}
          <img className={classes.image} src={fileSrc} alt={'card information upload'} />
        </Box>
        <Typography className={classes.description} color="textSecondary">
          {description}
        </Typography>
      </CardContent>
      <CardActions className={classes.buttonContainer}>
        <Field>
          {({ field, form: { touched, errors }, meta }) =>
            !isMobileDevice ? (
              <Fragment>
                <input
                  accept={ACCEPT_IMAGE}
                  id={cardID}
                  className={classes.input}
                  type="file"
                  onChange={handleUpload}
                />
                <label htmlFor={cardID} className={`button-label ${classes.label}`}>
                  <Button
                    className={classes.button}
                    component="span"
                    startIcon={<UploadIcon />}
                    onBlur={onTouchButton(cardFile.name)}
                  >
                    {t('common.button_upload_photo')}
                  </Button>
                </label>

                <Button
                  className={classes.button}
                  startIcon={<PhotoCameraOutlinedIcon />}
                  onClick={toggleWebcamModal}
                  disabled={!deviceHasCamera}
                >
                  {t('common.button_take_photo')}
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <input
                  accept={ACCEPT_IMAGE}
                  id={cardID}
                  className={classes.input}
                  type="file"
                  onChange={handleUpload}
                />
                <label htmlFor={cardID} className={`button-label ${classes.label}`}>
                  <Button className={classes.button} component="span" startIcon={<UploadIcon />}>
                    {t('common.button_upload_photo')}
                  </Button>
                </label>
                <Box> </Box>
                <input
                  accept={ACCEPT_IMAGE}
                  id={`${cardID}_capture`}
                  className={classes.input}
                  type="file"
                  capture="user"
                  onChange={handleUpload}
                />
                <label htmlFor={`${cardID}_capture`} className={`button-label ${classes.label}`}>
                  <Button className={classes.button} component="span" startIcon={<PhotoCameraOutlinedIcon />}>
                    {t('common.button_take_photo')}
                  </Button>
                </label>
              </Fragment>
            )
          }
        </Field>
      </CardActions>
      <SimpleModal
        isOpeningModal={isOpeningWebcamModal}
        toggleModal={toggleWebcamModal}
        title={t('common.text_camera')}
        closeHeader={true}
        className={`webcam-modal`}
        classNameBox={classes.containerModal}
        width="unset"
      >
        <Webcam handleTakePhotoUpload={handleTakePhotoUpload} fileName={fileName} />
      </SimpleModal>
    </Card>
  );
}

export default MediaCard;
