export const ActionTypes = {
  GET_KYC_INFORMATION_START: 'GET_KYC_INFORMATION_START',
  GET_KYC_INFORMATION_SUCCESS: 'GET_KYC_INFORMATION_SUCCESS',
  GET_KYC_INFORMATION_ERROR: 'GET_KYC_INFORMATION_ERROR',

  UPDATE_KYC_INFORMATION_START: 'UPDATE_KYC_INFORMATION_START',
  UPDATE_KYC_INFORMATION_SUCCESS: 'UPDATE_KYC_INFORMATION_SUCCESS',
  UPDATE_KYC_INFORMATION_ERROR: 'UPDATE_KYC_INFORMATION_ERROR',

  REQUEST_EDIT_KYC_START: 'REQUEST_EDIT_KYC_START',
  REQUEST_EDIT_KYC_SUCCESS: 'REQUEST_EDIT_KYC_SUCCESS',
  REQUEST_EDIT_KYC_ERROR: 'REQUEST_EDIT_KYC_ERROR',

  VERIFY_PHONE_NUMBER_START: 'VERIFY_PHONE_NUMBER_START',
  VERIFY_PHONE_NUMBER_SUCCESS: 'VERIFY_PHONE_NUMBER_SUCCESS',
  VERIFY_PHONE_NUMBER_ERROR: 'VERIFY_PHONE_NUMBER_ERROR',

  VERIFY_EMAIL_START: 'VERIFY_EMAIL_START',
  VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_ERROR: 'VERIFY_EMAIL_ERROR',

  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',
};

export const actions = {
  getKYCInformationStart: function () {
    return {
      type: ActionTypes.GET_KYC_INFORMATION_START,
    };
  },
  getKYCInformationSuccess: function (data) {
    return {
      type: ActionTypes.GET_KYC_INFORMATION_SUCCESS,
      payload: data,
    };
  },
  getKYCInformationError: function (error) {
    return {
      type: ActionTypes.GET_KYC_INFORMATION_ERROR,
      payload: error,
    };
  },

  updateKYCInformationStart: function (params) {
    return {
      type: ActionTypes.UPDATE_KYC_INFORMATION_START,
      params,
    };
  },
  updateKYCInformationSuccess: function (data) {
    return {
      type: ActionTypes.UPDATE_KYC_INFORMATION_SUCCESS,
      payload: data,
    };
  },
  updateKYCInformationError: function (error) {
    return {
      type: ActionTypes.UPDATE_KYC_INFORMATION_ERROR,
      payload: error,
    };
  },

  requestEditKYCStart: function (params) {
    return {
      type: ActionTypes.REQUEST_EDIT_KYC_START,
      params,
    };
  },
  requestEditKYCSuccess: function (data) {
    return {
      type: ActionTypes.REQUEST_EDIT_KYC_SUCCESS,
      payload: data,
    };
  },
  requestEditKYCError: function (error) {
    return {
      type: ActionTypes.REQUEST_EDIT_KYC_ERROR,
      payload: error,
    };
  },

  verifyPhoneNumberStart: function (params) {
    return {
      type: ActionTypes.VERIFY_PHONE_NUMBER_START,
      params,
    };
  },
  verifyPhoneNumberSuccess: function (data) {
    return {
      type: ActionTypes.VERIFY_PHONE_NUMBER_SUCCESS,
      payload: data,
    };
  },
  verifyPhoneNumberError: function (error) {
    return {
      type: ActionTypes.VERIFY_PHONE_NUMBER_ERROR,
      payload: error,
    };
  },

  verifyEmailStart: function (params) {
    return {
      type: ActionTypes.VERIFY_EMAIL_START,
      params,
    };
  },
  verifyEmailSuccess: function (data) {
    return {
      type: ActionTypes.VERIFY_EMAIL_SUCCESS,
      payload: data,
    };
  },
  verifyEmailError: function (error) {
    return {
      type: ActionTypes.VERIFY_EMAIL_ERROR,
      payload: error,
    };
  },

  resetTypeOfAction: function (error) {
    return {
      type: ActionTypes.RESET_TYPE_OF_ACTION,
    };
  },
};
