export const ActionTypes = {
  SHOW_LOADING: 'SHOW_LOADING',
  HIDE_LOADING: 'HIDE_LOADING',
};

export const actions = {
  showLoading: function () {
    return {
      type: ActionTypes.SHOW_LOADING,
    };
  },
  hideLoading: function () {
    return {
      type: ActionTypes.HIDE_LOADING,
    };
  },
};
