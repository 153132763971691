import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCountries, formatDate, getGender } from '../../../utils';
import ContentChild from '../../ContentChild';

function PassportContent({ values }) {
  const { t } = useTranslation();
  const {
    idoc_birthday,
    idoc_issued_date,
    idoc_expired_date,
    idoc_number,
    idoc_surname,
    idoc_given_name,
    idoc_gender,
    idoc_nationality,
  } = values;

  const documentContents = [
    {
      label: t('verification.text_passport_number'),
      value: idoc_number,
    },
    {
      label: t('verification.text_surname'),
      value: idoc_surname,
    },
    {
      label: t('verification.text_given_name'),
      value: idoc_given_name,
    },
    {
      label: t('verification.text_nationality'),
      value: formatCountries(idoc_nationality),
    },
    {
      label: t('verification.text_date_of_birth'),
      value: formatDate(idoc_birthday),
    },
    {
      label: t('verification.text_gender'),
      value: getGender(idoc_gender, t),
    },
    {
      label: t('verification.text_date_of_issue'),
      value: formatDate(idoc_issued_date),
    },
    {
      label: t('verification.text_date_of_expiry'),
      value: formatDate(idoc_expired_date),
    },
  ];

  return (
    <Fragment>
      {documentContents.map((document, index) => (
        <ContentChild key={index} label={document.label} value={document.value} />
      ))}
    </Fragment>
  );
}

export default PassportContent;
