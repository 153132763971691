import { put, takeLatest } from "redux-saga/effects";
import { actions, ActionTypes } from "./actions";
import { getDetailKYCHistoryPageAPI } from "../../services/KYCRequest";
import { RESPONSE_STATUS } from "../../common/constant";
import { actions as appActions } from "../../pages/layout/actions";

function* getDetailKYCHistory({ customerId, kycId }) {
  try {
    yield put(appActions.showLoading());
    const response = yield getDetailKYCHistoryPageAPI(customerId, kycId);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getKYCHistorySuccess(response.data));
    } else {
      yield put(actions.getKYCHistoryError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.getKYCHistoryError(e));
    yield put(appActions.hideLoading());
  }
}

export function* watchKYCHistoryDetail() {
  yield takeLatest(ActionTypes.GET_KYC_HISTORY_START, getDetailKYCHistory);
}
