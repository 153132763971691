import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import translationJP from './locales/jp/defaults.json';
import translationEN from './locales/en/defaults.json';
import translationCN from './locales/cn/defaults.json';
import translationSCN from './locales/scn/defaults.json';

export const supportLanguages = ['en', 'jp', 'cn', 'scn'];

export function getCurrentLanguage() {
  const rootLanguage = localStorage.getItem('language');
  const includeLanguage = supportLanguages.includes(rootLanguage);
  return includeLanguage ? rootLanguage : 'jp';
}

const resources = {
  en: {
    translation: translationEN,
  },
  jp: {
    translation: translationJP,
  },
  cn: {
    translation: translationCN,
  },
  scn: {
    translation: translationSCN,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: getCurrentLanguage(),
    fallbackLng: getCurrentLanguage(),
    nsSeparator: false,
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      lookupQuerystring: 'lang',
      lookupCookie: 'i18n',
      lookupLocalStorage: 'language',
      caches: ['localStorage', 'cookie'],
    },
  });

export default i18n;

window.i18n = i18n;
