import i18n from '../../../i18n/i18n';

const initialFormModel = () => ({
  formId: 'verificationForm',
  formField: {
    customer_code: {
      name: 'customer_code',
    },
    // SRS-02 new field,
    business_card_pic: {
      name: 'business_card_pic',
      label: i18n.t('verification.text_company_full_name'),
      placeholder: i18n.t('verification.text_des_company_full_name'),
      requiredErrorMsg: t => t('message.MSG_4', { fieldName: t('verification.text_company_full_name') }),
    },
    business_card_company: {
      name: 'business_card_company',
      label: i18n.t('verification.text_your_company'),
      placeholder: i18n.t('verification.text_des_your_company'),
      requiredErrorMsg: t => t('message.MSG_4', { fieldName: t('verification.text_your_company') }),
    },
    business_card_company_addr: {
      name: 'business_card_company_addr',
      label: i18n.t('verification.text_company_address'),
      placeholder: i18n.t('verification.text_des_company_address'),
      requiredErrorMsg: t => t('message.MSG_4', { fieldName: t('verification.text_company_address') }),
    },
    business_card_phone: {
      name: 'business_card_phone',
      label: i18n.t('verification.text_telephone_number'),
      placeholder: i18n.t('verification.text_dec_telephone_number'),
      formatErrorMsg: t => t('message.MSG_39'),
      requiredErrorMsg: t => t('message.MSG_4', { fieldName: t('verification.text_telephone_number') }),
    },
    business_card_fax: {
      name: 'business_card_fax',
      label: i18n.t('verification.text_fax_optional'),
      labelNoOptional: i18n.t('verification.text_fax_label'),
      placeholder: i18n.t('verification.text_des_fax_optional'),
      formatErrorMsg: t => t('message.MSG_39'),
    },
    business_card_mobile: {
      name: 'business_card_mobile',
      label: i18n.t('verification.text_mobile_number_optional'),
      labelNoOptional: i18n.t('verification.text_mobile_number_label'),
      placeholder: i18n.t('verification.text_des_mobile_number_optional'),
      formatErrorMsg: t => t('message.MSG_39'),
    },
    business_card_email: {
      name: 'business_card_email',
      label: i18n.t('verification.text_mail_optional'),
      labelNoOptional: i18n.t('verification.text_mail_label'),
      placeholder: i18n.t('verification.text_mail_des_optional'),
      requiredErrorMsg: t => t('message.MSG_4', { fieldName: t('verification.text_mail_label') }),
      validErrorMsg: i18n.t('message.MSG_2'),
    },
    business_card_web: {
      name: 'business_card_web',
      label: i18n.t('verification.text_website_optional'),
      labelNoOptional: i18n.t('verification.text_website_label'),
      placeholder: i18n.t('verification.text_des_website_optional'),
      requiredErrorMsg: t => t('message.MSG_4', { fieldName: t('verification.text_website_label') }),
      validErrorMsg: i18n.t('message.MSG_2'),
    },
    business_card_photo1: {
      name: 'business_card_photo1',
      requiredErrorMsg: t => t('message.MSG_39'),
    },
    business_card_photo1_file: {
      name: 'business_card_photo1_file',
      requiredErrorMsg: t => t('message.MSG_4', { fieldName: t('verification.idoc_photo1') }),
    },
    business_card_photo1_url: {
      name: 'business_card_photo1_url',
      requiredErrorMsg: t => t('message.MSG_4', { fieldName: t('verification.idoc_photo1') }),
    },
    business_card_photo2: {
      name: 'business_card_photo2',
      requiredErrorMsg: t => t('message.MSG_39'),
    },
    business_card_photo2_file: {
      name: 'business_card_photo2_file',
      requiredErrorMsg: t => t('message.MSG_4', { fieldName: t('verification.idoc_photo1') }),
    },
    business_card_photo2_url: {
      name: 'business_card_photo2_url',
      requiredErrorMsg: t => t('message.MSG_4', { fieldName: t('verification.idoc_photo1') }),
    },
    legal_entity_photo1: {
      name: 'legal_entity_photo1',
      requiredErrorMsg: t => t('message.MSG_39'),
    },
    legal_entity_photo1_file: {
      name: 'legal_entity_photo1_file',
      requiredErrorMsg: t => t('message.MSG_4', { fieldName: t('verification.idoc_photo1') }),
    },
    legal_entity_photo1_url: {
      name: 'legal_entity_photo1_url',
      requiredErrorMsg: t => t('message.MSG_4', { fieldName: t('verification.idoc_photo1') }),
    },
    legal_entity_photo2: {
      name: 'legal_entity_photo2',
      requiredErrorMsg: t => t('message.MSG_39'),
    },
    legal_entity_photo2_file: {
      name: 'legal_entity_photo2_file',
      requiredErrorMsg: t => t('message.MSG_4', { fieldName: t('verification.idoc_photo1') }),
    },
    legal_entity_photo2_url: {
      name: 'legal_entity_photo2_url',
      requiredErrorMsg: t => t('message.MSG_4', { fieldName: t('verification.idoc_photo1') }),
    },
    legal_entity_company: {
      name: 'legal_entity_company',
      label: i18n.t('verification.text_your_company'),
      placeholder: i18n.t('verification.text_des_legal_company'),
      requiredErrorMsg: t => t('message.MSG_4', { fieldName: t('verification.text_your_company') }),
    },
    legal_entity_representative_name: {
      name: 'legal_entity_representative_name',
      label: i18n.t('verification.text_representative_name'),
      placeholder: i18n.t('verification.text_des_representative_name'),
      requiredErrorMsg: t => t('message.MSG_4', { fieldName: t('verification.text_representative_name') }),
    },
    legal_entity_company_business: {
      name: 'legal_entity_company_business',
      label: i18n.t('verification.text_company_business'),
      placeholder: i18n.t('verification.text_des_company_business'),
      requiredErrorMsg: t => t('message.MSG_4', { fieldName: t('verification.text_company_business') }),
    },
    legal_entity_license: {
      name: 'legal_entity_license',
      label: i18n.t('verification.text_license'),
      placeholder: i18n.t('verification.text_des_license'),
      requiredErrorMsg: t => t('message.MSG_4', { fieldName: t('verification.text_license') }),
    },

    // End SRS 02
    full_name: {
      name: 'full_name',
      label: i18n.t('verification.text_full_name'),
      placeholder: i18n.t('verification.text_full_name_placeholder'),
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_full_name') }),
    },
    birthday: {
      name: 'birthday',
      label: i18n.t('verification.text_date_of_birth'),
      placeholder: 'YYYY/MM/DD',
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_date_of_birth') }),
    },
    nationality: {
      name: 'nationality',
      label: i18n.t('verification.text_nationality'),
      placeholder: i18n.t('verification.text_nationality'),
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_nationality') }),
    },
    email: {
      name: 'email',
      label: i18n.t('verification.text_email'),
      placeholder: i18n.t('verification.text_email_placeholder'),
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_email') }),
      validErrorMsg: i18n.t('message.MSG_2'),
    },
    phoneNumber: {
      name: 'phone',
      label: i18n.t('verification.text_phone_number'),
      placeholder: i18n.t('verification.text_phone_number'),
      formatErrorMsg: i18n.t('message.MSG_39'),
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_phone_number') }),
    },
    residential_address: {
      name: 'residential_address',
      label: i18n.t('verification.text_residential_address'),
      placeholder: i18n.t('verification.text_residential_address_placeholder'),
      requiredErrorMsg: i18n.t('message.MSG_4', {
        fieldName: i18n.t('verification.text_residential_address'),
      }),
    },
    isFirstKYC: {
      name: 'first_kyc',
    },

    idoc_type: {
      name: 'idoc_type',
      label: i18n.t('verification.text_identity_document'),
      placeholder: i18n.t('verification.placeholder_select_identity_document'),
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_identity_document') }),
      options: [
        { key: 'DRIVER_LICENSE', value: 'Driver License' },
        { key: 'PASSPORT', value: 'Passport' },
        { key: 'BASIC_RESIDENT_REGISTER', value: 'Basic Resident Register' },
        { key: 'HEALTH_INSURANCE_CARD', value: 'Health Insurance Card' },
        { key: 'RESIDENCE_CARD', value: 'Residence Card' },
        { key: 'FOREIGNER_REGISTRATION_CARD', value: 'Foreigner Registration Card' },
        { key: 'SPECIAL_PERMANENT_RESIDENT', value: 'Special Permanent Resident' },
      ],
      optionsForJapan: [
        { key: 'DRIVER_LICENSE', value: 'Driver License' },
        { key: 'PASSPORT', value: 'Passport' },
        { key: 'BASIC_RESIDENT_REGISTER', value: 'Basic Resident Register' },
        { key: 'HEALTH_INSURANCE_CARD', value: 'Health Insurance Card' },
      ],
    },
    idoc_full_name: {
      name: 'idoc_full_name',
      label: i18n.t('verification.text_full_name'),
      placeholder: i18n.t('verification.placeholder_identity_document_name'),
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_full_name') }),
    },
    idoc_birthday: {
      name: 'idoc_birthday',
      label: i18n.t('verification.text_date_of_birth'),
      placeholder: 'YYYY/MM/DD',
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_date_of_birth') }),
    },
    idoc_address: {
      name: 'idoc_address',
      label: i18n.t('verification.text_address'),
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_address') }),
    },
    idoc_issued_date: {
      name: 'idoc_issued_date',
      label: i18n.t('verification.text_date_of_issue'),
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_date_of_issue') }),
    },
    idoc_expired_date: {
      name: 'idoc_expired_date',
      label: i18n.t('verification.text_date_of_expiry'),
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_date_of_expiry') }),
    },
    idoc_number: {
      name: 'idoc_number',
      label: i18n.t('verification.text_license_number'),
      labelPassportNumber: i18n.t('verification.text_passport_number'),
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_license_number') }),
    },
    idoc_surname: {
      name: 'idoc_surname',
      label: i18n.t('verification.text_surname'),
      placeholder: i18n.t('verification.placeholder_enter_surname'),
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_surname') }),
    },
    idoc_given_name: {
      name: 'idoc_given_name',
      label: i18n.t('verification.text_given_name'),
      placeholder: i18n.t('verification.placeholder_enter_given_name'),
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_given_name') }),
    },
    idoc_nationality: {
      name: 'idoc_nationality',
      label: i18n.t('verification.text_nationality'),
      placeholder: i18n.t('verification.text_select_country'),
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_nationality') }),
    },
    idoc_gender: {
      name: 'idoc_gender',
      label: i18n.t('verification.text_gender'),
      placeholder: i18n.t('verification.placeholder_select_gender'),
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_gender') }),
    },
    idoc_card_number: {
      name: 'idoc_number',
      label: i18n.t('verification.text_card_number'),
      placeholder: i18n.t('verification.placeholder_enter_card_number'),
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_card_number') }),
    },
    idoc_applicable_date: {
      name: 'idoc_applicable_date',
      label: i18n.t('verification.text_applicable_date'),
      placeholder: 'YYYY/MM/DD',
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_applicable_date') }),
    },
    idoc_supply_no: {
      name: 'idoc_supply_no',
      label: i18n.t('verification.text_insurer_number'),
      placeholder: i18n.t('verification.placeholder_enter_insurer_number'),
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.text_insurer_number') }),
    },

    idoc_photo1: {
      name: 'idoc_photo1',
      requiredErrorMsg: i18n.t('message.MSG_39'),
    },
    idoc_photo2: {
      name: 'idoc_photo2',
      requiredErrorMsg: i18n.t('message.MSG_39'),
    },
    idoc_video: {
      name: 'idoc_video',
      requiredErrorMsg: i18n.t('message.MSG_39'),
    },

    idoc_photo1_url: {
      name: 'idoc_photo1_url',
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.idoc_photo1') }),
    },
    idoc_photo2_url: {
      name: 'idoc_photo2_url',
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.idoc_photo2') }),
    },
    idoc_video_url: {
      name: 'idoc_video_url',
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.idoc_video') }),
    },

    idoc_photo1_file: {
      name: 'idoc_photo1_file',
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.idoc_photo1') }),
    },
    idoc_photo2_file: {
      name: 'idoc_photo2_file',
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.idoc_photo2') }),
    },
    idoc_video_file: {
      name: 'idoc_video_file',
      requiredErrorMsg: i18n.t('message.MSG_4', { fieldName: i18n.t('verification.idoc_video') }),
    },
  },
});

export default initialFormModel;

const {
  idoc_photo1_url,
  idoc_photo2_url,
  idoc_video_url,
  business_card_photo1_url,
  business_card_photo2_url,
  legal_entity_photo2_url,
  legal_entity_photo1_url,
} = initialFormModel().formField;

export const listIgnoreUrl = [
  idoc_photo1_url.name,
  idoc_photo2_url.name,
  idoc_video_url.name,
  business_card_photo1_url.name,
  business_card_photo2_url.name,
  legal_entity_photo1_url.name,
  legal_entity_photo2_url.name,
];
