import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

SelectComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  options: PropTypes.array.isRequired,
  props: PropTypes.object,
  menuClass: PropTypes.string,
};

SelectComponent.defaultProps = {
  field: null,
  form: null,
  props: {},
  menuClass: '',
};

function SelectComponent({ field, form, options, menuClass, message, fullFieldWidth, labelField, ...props }) {
  const isError = Boolean(message) || (field && form.touched[field.name] && Boolean(form.errors[field.name]));

  return (
    <FormControl variant="outlined" fullWidth={fullFieldWidth} error={isError}>
      <InputLabel>{labelField}</InputLabel>
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          className: menuClass,
          disableScrollLock: true,
        }}
        IconComponent={ExpandMoreIcon}
        {...field}
        {...props}
      >
        <MenuItem className="hide" value="">
          <em className="hide">None</em>
        </MenuItem>
        {options.map(({ key, value }, index) => (
          <MenuItem key={index} value={key}>
            {value}
          </MenuItem>
        ))}
      </Select>
      {isError && <FormHelperText>{form.errors[field.name]}</FormHelperText>}
    </FormControl>
  );
}

export default SelectComponent;
