import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n/i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { persistor, store, sagaMiddleware } from './redux/configStore';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';
import rootSaga from './redux/rootSaga';
import { getToken } from './services/api';

const handleAuthentication = (store) => () => {
  const { auth } = store.getState();
  getToken(auth.currentUser?.access_token);
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate onBeforeLift={handleAuthentication(store)} loading={null} persistor={persistor}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
sagaMiddleware.run(rootSaga);
serviceWorker.unregister();
