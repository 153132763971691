import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCountries, formatDate, getGender } from '../../../utils';
import ContentChild from '../../ContentChild';

function ResidentCardContent({ values }) {
  const { t } = useTranslation();
  const { idoc_number, idoc_full_name, idoc_birthday, idoc_gender, idoc_nationality, idoc_expired_date } = values;

  const documentContents = [
    {
      label: t('verification.text_card_number'),
      value: idoc_number,
    },
    {
      label: t('verification.text_full_name'),
      value: idoc_full_name,
    },
    {
      label: t('verification.text_date_of_birth'),
      value: formatDate(idoc_birthday),
    },
    {
      label: t('verification.text_gender'),
      value: getGender(idoc_gender, t),
    },
    {
      label: t('verification.text_nationality'),
      value: formatCountries(idoc_nationality),
    },
    {
      label: t('verification.text_date_of_expiry'),
      value: formatDate(idoc_expired_date),
    },
  ];

  return (
    <Fragment>
      {documentContents.map((document, index) => (
        <ContentChild key={index} label={document.label} value={document.value} />
      ))}
    </Fragment>
  );
}

export default ResidentCardContent;
