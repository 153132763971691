import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormikContext, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Select } from '../../../components';
import {
  BasicResidentRegisterComponent,
  DriverLicenseComponent,
  ForeignerRegistrationCardComponent,
  HealthInsuranceCardComponent,
  PassportComponent,
  ResidentCardComponent,
  SpecialPermanentResidentComponent,
  ConfirmModal,
} from '../Components';
import { getInitialValuesStep2Legal, getInitialValuesStep3Legal, initialValuesStep2, initialValuesStep3 } from '../FormModel/initialValues';
import { getDocumentTypeOptions, getDocumentTypeOptionsForJapan, REPRESENTATIVE_TYPE } from '../../../common/constant';
import { LegalEntityDocuments } from './LegalEntityDocuments';
import { useSelector } from 'react-redux';

function Step2Form({ formField }) {
  const { values, resetForm, setFieldValue, setErrors, setTouched } = useFormikContext();
  const { idoc_type } = formField;
  const { t } = useTranslation();
  const typeRepresentative = useSelector(state => state.auth.typeRepresentative);

  const [isOpeningConfirmModal, setIsOpeningConfirmModal] = useState(false);
  const [selectValue, setSelectValue] = useState('');
  const [isChangeDocumentType, setIsChangeDocumentType] = useState(false);

  const toggleConfirmModal = useCallback(() => {
    return setIsOpeningConfirmModal(!isOpeningConfirmModal);
  }, [isOpeningConfirmModal]);

  const renderFieldContent = useCallback(
    key => {
      switch (key) {
        case 'DRIVER_LICENSE':
          return <DriverLicenseComponent />;
        case 'PASSPORT':
          return <PassportComponent />;
        case 'BASIC_RESIDENT_REGISTER':
          return <BasicResidentRegisterComponent />;
        case 'HEALTH_INSURANCE_CARD':
          return <HealthInsuranceCardComponent />;
        case 'RESIDENCE_CARD':
          return <ResidentCardComponent />;
        case 'FOREIGNER_REGISTRATION_CARD':
          return <ForeignerRegistrationCardComponent />;
        case 'SPECIAL_PERMANENT_RESIDENT':
          return <SpecialPermanentResidentComponent />;
        default:
          return <div className="block-default">{t('verification.text_not_select_an_identity_document')}</div>;
      }
    },
    [t]
  );

  useEffect(() => {
    if (values.nationality === 'JP' && !idoc_type.optionsForJapan.some(option => option.key === values[idoc_type.name])) {
      resetForm({
        values: {
          ...values,
          ...initialValuesStep2(),
          ...initialValuesStep3(),
          ...getInitialValuesStep2Legal(values),
          ...getInitialValuesStep3Legal(values),
          [idoc_type.name]: '',
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!isOpeningConfirmModal && isChangeDocumentType) {
      resetForm({
        values: {
          ...values,
          ...initialValuesStep2(),
          ...initialValuesStep3(),
          ...getInitialValuesStep2Legal(values),
          ...getInitialValuesStep3Legal(values),
          [idoc_type.name]: selectValue,
        },
      });
    }
  }, [isOpeningConfirmModal]);

  const handleSelectChange = event => {
    const { value } = event.target;
    if (!!values.idoc_type) {
      setSelectValue(value);
      toggleConfirmModal();
    } else {
      setFieldValue(idoc_type.name, value);
      setErrors({});
      setTouched({});
    }
  };

  const memoizedConfirmModal = useMemo(
    () => <ConfirmModal isOpeningModal={isOpeningConfirmModal} handleConfirm={setIsChangeDocumentType} toggleModal={toggleConfirmModal} />,
    [isOpeningConfirmModal, setIsChangeDocumentType, toggleConfirmModal]
  );

  const DOCUMENT_TYPE_OPTIONS = getDocumentTypeOptions(t);
  const DOCUMENT_TYPE_OPTIONS_FOR_JAPAN = getDocumentTypeOptionsForJapan(t);

  return (
    <Grid container spacing={2}>
      <Grid item xs={false} md={false} lg={2}></Grid>
      <Grid item xs={12} md={12} lg={8}>
        {values.nationality === 'JP' && (
          <Field
            name={idoc_type.name}
            label={idoc_type.label}
            labelField={idoc_type.label}
            placeholder={idoc_type.placeholder}
            displayEmpty
            options={DOCUMENT_TYPE_OPTIONS_FOR_JAPAN}
            value={values.idoc_type || ''}
            onChange={handleSelectChange}
            fullFieldWidth
            component={Select}
          />
        )}
        {values.nationality !== 'JP' && (
          <Field
            name={idoc_type.name}
            label={idoc_type.label}
            labelField={idoc_type.label}
            placeholder={idoc_type.placeholder}
            displayEmpty
            options={DOCUMENT_TYPE_OPTIONS}
            value={values.idoc_type || ''}
            onChange={handleSelectChange}
            fullFieldWidth
            component={Select}
          />
        )}
        {renderFieldContent(values?.idoc_type)}
        {memoizedConfirmModal}
      </Grid>
      {REPRESENTATIVE_TYPE.LEGAL_ENTITY === typeRepresentative && values?.idoc_type && (
        <LegalEntityDocuments idocType={values?.idoc_type} formField={formField} />
      )}
      <Grid item xs={false} md={false} lg={2}></Grid>
    </Grid>
  );
}

export default Step2Form;
