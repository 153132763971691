export const ActionTypes = {
  CREATE_OTP_CODE_START: 'CREATE_OTP_CODE_START',
  CREATE_OTP_CODE_SUCCESS: 'CREATE_OTP_CODE_SUCCESS',
  CREATE_OTP_CODE_ERROR: 'CREATE_OTP_CODE_ERROR',

  RESEND_OTP_CODE_START: 'RESEND_OTP_CODE_START',
  RESEND_OTP_CODE_SUCCESS: 'RESEND_OTP_CODE_SUCCESS',
  RESEND_OTP_CODE_ERROR: 'RESEND_OTP_CODE_ERROR',

  VERIFY_OTP_CODE_START: 'VERIFY_OTP_CODE_START',
  VERIFY_OTP_CODE_SUCCESS: 'VERIFY_OTP_CODE_SUCCESS',
  VERIFY_OTP_CODE_ERROR: 'VERIFY_OTP_CODE_ERROR',

  USER_LOGOUT: 'USER_LOGOUT',

  RESET_TYPE_OF_ACTION: 'RESET_TYPE_OF_ACTION',

  COMPLETE_LOGIN: 'COMPLETE_LOGIN',
};

export const actions = {
  createOTPCodeStart: function (params) {
    return {
      type: ActionTypes.CREATE_OTP_CODE_START,
      params,
    };
  },
  createOTPCodeSuccess: function (data) {
    return {
      type: ActionTypes.CREATE_OTP_CODE_SUCCESS,
      payload: data,
    };
  },
  createOTPCodeError: function (error) {
    return {
      type: ActionTypes.CREATE_OTP_CODE_ERROR,
      payload: error,
    };
  },

  resendOTPCodeStart: function (params) {
    return {
      type: ActionTypes.RESEND_OTP_CODE_START,
      params,
    };
  },
  resendOTPCodeSuccess: function (data) {
    return {
      type: ActionTypes.RESEND_OTP_CODE_SUCCESS,
      payload: data,
    };
  },
  resendOTPCodeError: function (error) {
    return {
      type: ActionTypes.RESEND_OTP_CODE_ERROR,
      payload: error,
    };
  },

  verifyOTPCodeStart: function (params) {
    return {
      type: ActionTypes.VERIFY_OTP_CODE_START,
      params,
    };
  },
  verifyOTPCodeSuccess: function (data) {
    return {
      type: ActionTypes.VERIFY_OTP_CODE_SUCCESS,
      payload: data,
    };
  },
  verifyOTPCodeError: function (error) {
    return {
      type: ActionTypes.VERIFY_OTP_CODE_ERROR,
      payload: error,
    };
  },

  logout: function (error) {
    return {
      type: ActionTypes.USER_LOGOUT,
      payload: error,
    };
  },

  resetTypeOfAction: function (error) {
    return {
      type: ActionTypes.RESET_TYPE_OF_ACTION,
    };
  },
  completeLogin: typeRepresentative => {
    return {
      type: ActionTypes.COMPLETE_LOGIN,
      typeRepresentative,
    };
  },
};
