import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={80} height={80} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M77.5 65h-5V23.125a4.38 4.38 0 00-4.375-4.375H61.25V6.875a3.128 3.128 0 00-3.125-3.125h-36.25a3.128 3.128 0 00-3.125 3.125V18.75h-6.875A4.38 4.38 0 007.5 23.125V65h-5a1.25 1.25 0 00-1.25 1.25v5.625a4.38 4.38 0 004.375 4.375h68.75a4.38 4.38 0 004.375-4.375V66.25A1.25 1.25 0 0077.5 65zM21.25 6.875a.625.625 0 01.625-.625h36.25a.625.625 0 01.625.625v2.353L40 18.603 21.25 9.228V6.875zm0 5.147l18.19 9.097a1.258 1.258 0 001.12 0l18.19-9.097v18.603a.624.624 0 01-.625.625h-36.25a.624.624 0 01-.625-.625V12.022zm.625 21.728H33.75v25a1.25 1.25 0 002.5 0v-25h2.5V47.5a1.25 1.25 0 002.5 0V33.75h2.5v12.5a1.25 1.25 0 002.5 0v-12.5h11.875a3.128 3.128 0 003.125-3.125V25h5v36.25h-52.5V25h5v5.625a3.128 3.128 0 003.125 3.125zM10 23.125a1.877 1.877 0 011.875-1.875h6.875v1.25H12.5a1.25 1.25 0 00-1.25 1.25V62.5a1.25 1.25 0 001.25 1.25h55a1.25 1.25 0 001.25-1.25V23.75a1.25 1.25 0 00-1.25-1.25h-6.25v-1.25h6.875A1.877 1.877 0 0170 23.125V65H10V23.125zM45.898 67.5l-.625 2.5H34.727l-.625-2.5h11.796zm30.352 4.375a1.877 1.877 0 01-1.875 1.875H5.625a1.877 1.877 0 01-1.875-1.875V67.5h27.773l1.015 4.053a1.25 1.25 0 001.212.947h12.5a1.25 1.25 0 001.212-.947l1.015-4.053H76.25v4.375z"
        fill="#666A74"
      />
      <path
        d="M40 53.75a1.25 1.25 0 001.25-1.25v-1.25a1.25 1.25 0 00-2.5 0v1.25A1.25 1.25 0 0040 53.75z"
        fill="#666A74"
      />
    </svg>
  );
}

export default SvgComponent;
