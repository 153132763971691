import * as Yup from 'yup';
import verificationFormModel from './formModel';
import { DOCUMENT_TYPE } from '../../../common/constant';
import i18n from '../../../i18n/i18n';

const {
  formField: {
    full_name,
    birthday,
    nationality,
    email,
    phoneNumber,
    residential_address,
    idoc_type,
    idoc_full_name,
    idoc_birthday,
    idoc_address,
    idoc_issued_date,
    idoc_expired_date,
    idoc_number,
    idoc_surname,
    idoc_given_name,
    idoc_gender,
    idoc_nationality,
    idoc_applicable_date,
    idoc_supply_no,
    idoc_photo1,
    idoc_photo2,
    idoc_video,
    idoc_photo1_file,
    idoc_photo2_file,
    idoc_video_file,

    // SRS - 02
    business_card_photo1_url,
    business_card_photo2_url,
    business_card_company,
    business_card_pic,
    business_card_company_addr,
    business_card_phone,
    business_card_fax,
    business_card_mobile,
    business_card_email,
    business_card_web,
    legal_entity_photo1_url,
    legal_entity_photo2_url,
    legal_entity_company,
    legal_entity_representative_name,
    legal_entity_company_business,
    legal_entity_license,
  },
} = verificationFormModel();

const {
  DRIVER_LICENSE,
  PASSPORT,
  BASIC_RESIDENT_REGISTER,
  HEALTH_INSURANCE_CARD,
  RESIDENCE_CARD,
  FOREIGNER_REGISTRATION_CARD,
  SPECIAL_PERMANENT_RESIDENT,
} = DOCUMENT_TYPE;

const validatePhoneNumber = (field, t) => {
  return Yup.mixed()
    .nullable()
    .test(field.name, function (value) {
      return (
        (value?.length < 4 &&
          this.createError({
            message: field.requiredErrorMsg(t),
          })) ||
        (value?.length < 11 &&
          this.createError({
            message: field.formatErrorMsg(t),
          })) ||
        true
      );
    });
};

const validateEMail = t => {
  return Yup.string().nullable().trim().email(t('message.MSG_2')).trim();
};

const validateWebsite = t => {
  return Yup.string().url(t('message.MSG_46'));
};

const getValidateLegalDocument = t => ({
  [business_card_pic.name]: Yup.string().nullable().trim().required(business_card_pic.requiredErrorMsg(t)),
  [business_card_company.name]: Yup.string().nullable().trim().required(business_card_company.requiredErrorMsg(t)),
  [business_card_company_addr.name]: Yup.string().nullable().trim().required(business_card_company_addr.requiredErrorMsg(t)),
  [business_card_phone.name]: validatePhoneNumber(business_card_phone, t).required(business_card_phone.requiredErrorMsg(t)),
  [business_card_fax.name]: validatePhoneNumber(business_card_fax, t),
  [business_card_mobile.name]: validatePhoneNumber(business_card_mobile, t),
  [business_card_email.name]: validateEMail(t),
  [business_card_web.name]: validateWebsite(t),
  [legal_entity_company.name]: Yup.string().nullable().trim().required(legal_entity_company.requiredErrorMsg(t)),
  [legal_entity_representative_name.name]: Yup.string().nullable().trim().required(legal_entity_representative_name.requiredErrorMsg(t)),
  [legal_entity_company_business.name]: Yup.string().nullable().trim().required(legal_entity_company_business.requiredErrorMsg(t)),
  [legal_entity_license.name]: Yup.string().nullable().trim().required(legal_entity_license.requiredErrorMsg(t)),
});

const getValidateLegalUpload = t => ({
  [business_card_photo1_url.name]: Yup.string().nullable().required(),
  [business_card_photo2_url.name]: Yup.string().nullable().required(),
  [legal_entity_photo1_url.name]: Yup.string().nullable().required(),
  [legal_entity_photo2_url.name]: Yup.string().nullable().required(),
});
const validationSchema = (t = { i18n }, isLegalDocument = false) => {
  const validateLegalDocument = isLegalDocument ? getValidateLegalDocument(t) : {};
  const ValidateLegalUpload = isLegalDocument ? getValidateLegalUpload(t) : {};

  return [
    Yup.object().shape({
      [full_name.name]: Yup.string()
        .nullable()
        .trim()
        .required(t('message.MSG_4', { fieldName: t('verification.text_full_name') })),
      [birthday.name]: Yup.string()
        .nullable()
        .required(t('message.MSG_4', { fieldName: t('verification.text_date_of_birth') })),
      [nationality.name]: Yup.string()
        .nullable()
        .required(t('message.MSG_4', { fieldName: t('verification.text_nationality') })),
      [email.name]: validateEMail(t).required(t('message.MSG_4', { fieldName: t('verification.text_email') })),
      [phoneNumber.name]: validatePhoneNumber(business_card_phone).required(phoneNumber.requiredErrorMsg),
      [residential_address.name]: Yup.string()
        .nullable()
        .trim()
        .required(t('message.MSG_4', { fieldName: t('verification.text_residential_address') })),
    }),

    Yup.object().shape({
      // SRS 02
      ...validateLegalDocument,
      // END SRS 02
      [idoc_type.name]: Yup.string()
        .nullable()
        .required(t('message.MSG_4', { fieldName: t('verification.text_identity_document') })),
      [idoc_full_name.name]: Yup.string()
        .nullable()
        .when([idoc_type.name], {
          is: type => type !== PASSPORT,
          then: Yup.string()
            .nullable()
            .trim()
            .required(t('message.MSG_4', { fieldName: t('verification.text_full_name') })),
        }),
      [idoc_birthday.name]: Yup.string()
        .nullable()
        .required(t('message.MSG_4', { fieldName: t('verification.text_date_of_birth') })),
      [idoc_address.name]: Yup.string()
        .nullable()
        .when([idoc_type.name], {
          is: type =>
            [DRIVER_LICENSE, BASIC_RESIDENT_REGISTER, HEALTH_INSURANCE_CARD, FOREIGNER_REGISTRATION_CARD, SPECIAL_PERMANENT_RESIDENT].includes(type),
          then: Yup.string()
            .trim()
            .required(t('message.MSG_4', { fieldName: t('verification.text_address') })),
        }),
      [idoc_issued_date.name]: Yup.string()
        .nullable()
        .when([idoc_type.name], {
          is: type => [DRIVER_LICENSE, PASSPORT].includes(type),
          then: Yup.string()
            .nullable()
            .required(t('message.MSG_4', { fieldName: t('verification.text_date_of_issue') })),
        }),
      [idoc_expired_date.name]: Yup.string()
        .nullable()
        .required(t('message.MSG_4', { fieldName: t('verification.text_date_of_expiry') })),
      [idoc_number.name]: Yup.string()
        .nullable()
        .when([idoc_type.name], {
          is: type => [DRIVER_LICENSE, PASSPORT].includes(type),
          then: Yup.string()
            .nullable()
            .trim()
            .required(t('message.MSG_4', { fieldName: t('verification.text_license_number') })),
          otherwise: Yup.string().when([idoc_type.name], {
            is: type => [FOREIGNER_REGISTRATION_CARD, RESIDENCE_CARD, HEALTH_INSURANCE_CARD, SPECIAL_PERMANENT_RESIDENT].includes(type),
            then: Yup.string()
              .nullable()
              .trim()
              .required(t('message.MSG_4', { fieldName: t('verification.text_card_number') })),
          }),
        }),
      [idoc_surname.name]: Yup.string()
        .nullable()
        .when([idoc_type.name], {
          is: PASSPORT,
          then: Yup.string()
            .trim()
            .required(t('message.MSG_4', { fieldName: t('verification.text_surname') })),
        }),
      [idoc_given_name.name]: Yup.string()
        .nullable()
        .when([idoc_type.name], {
          is: PASSPORT,
          then: Yup.string()
            .trim()
            .required(t('message.MSG_4', { fieldName: t('verification.text_given_name') })),
        }),
      [idoc_gender.name]: Yup.string()
        .nullable()
        .when([idoc_type.name], {
          is: type => type !== DRIVER_LICENSE,
          then: Yup.string()
            .nullable()
            .required(t('message.MSG_4', { fieldName: t('verification.text_gender') })),
        }),
      [idoc_nationality.name]: Yup.string()
        .nullable()
        .when([idoc_type.name], {
          is: type => [PASSPORT, RESIDENCE_CARD, FOREIGNER_REGISTRATION_CARD, SPECIAL_PERMANENT_RESIDENT].includes(type),
          then: Yup.string()
            .nullable()
            .required(t('message.MSG_4', { fieldName: t('verification.text_nationality') })),
        }),
      [idoc_applicable_date.name]: Yup.string()
        .nullable()
        .when([idoc_type.name], {
          is: HEALTH_INSURANCE_CARD,
          then: Yup.string().required(t('message.MSG_4', { fieldName: t('verification.text_applicable_date') })),
        }),
      [idoc_supply_no.name]: Yup.string()
        .nullable()
        .when([idoc_type.name], {
          is: HEALTH_INSURANCE_CARD,
          then: Yup.string()
            .nullable()
            .trim()
            .required(t('message.MSG_4', { fieldName: t('verification.text_insurer_number') })),
        }),
    }),

    Yup.object().shape({
      // SRS - 02
      ...ValidateLegalUpload,
      [idoc_photo1.name]: Yup.string()
        .nullable()
        .when([idoc_photo1_file.name], {
          is: file => {
            return !file;
          },
          then: Yup.string().nullable().required(t('message.MSG_39')),
        }),
      [idoc_photo2.name]: Yup.string()
        .nullable()
        .when([idoc_photo2_file.name], {
          is: file => !file,
          then: Yup.string().nullable().required(t('message.MSG_39')),
        }),
      [idoc_video.name]: Yup.string()
        .nullable()
        .when([idoc_video_file.name, idoc_type.name], {
          is: (file, documentType) => !file && documentType !== PASSPORT,
          then: Yup.string().nullable().required(t('message.MSG_39')),
        }),
    }),
  ];
};

export default validationSchema;
