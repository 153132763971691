import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import MediaCard from '../Components/Step3/mediaCard';
import BUSINESS_IMAGE_DEFAULT_BEFORE from '../../../resources/images/business_before.png';
import BUSINESS_IMAGE_DEFAULT_AFTER from '../../../resources/images/business_after.png';
import LEGAL_IMAGE_DEFAULT_BEFORE from '../../../resources/images/legal_before.png';
import LEGAL_IMAGE_DEFAULT_AFTER from '../../../resources/images/legal_after.png';

import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 24,
  },
  heading: {
    fontSize: 16,
    textAlign: 'center',
    color: '#1181F1',
    marginTop: 24,
  },
  card: {
    width: '100%',
  },
  content: {
    justifyContent: 'center',
  },
  textCaption: {
    fontStyle: 'italic',
    margin: 'auto',
  },
  textError: {
    color: theme.overrides.colorBurntSienna,
    display: 'block',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
}));

const countValueUpload = props => props.filter(element => element).length;

const isErrorUpload = (errors, touched, listFieldName = []) => {
  const errorsValue = listFieldName.some(item => errors[item]);
  const touchedValue = listFieldName.some(item => touched[item]);
  return errorsValue && touchedValue;
};

export const LegalEntityUpload = ({ formField }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { values, errors, touched } = useFormikContext();

  const {
    customer_code,
    business_card_photo1_file,
    business_card_photo1_url,
    business_card_photo2_file,
    business_card_photo2_url,
    full_name,
    legal_entity_photo1_file,
    legal_entity_photo1_url,
    legal_entity_photo2_file,
    legal_entity_photo2_url,
  } = formField;

  return (
    <Grid item xs={12}>
      <div className="title-form-underline in-verify-form">{t('verification.text_legal_entity_documents')}</div>
      <Grid className="verify-box-item" item xs={12}>
        <div className="title-item">{t('verification.business_card_photos')}</div>
        <Typography variant="h4" component="h5" className={classes.heading}>
          {`${t('verification.business_card_photos')}: ${countValueUpload([values.business_card_photo1_url, values.business_card_photo2_url])}/2 ${t(
            'common.text_photo'
          )}`}
        </Typography>
        {isErrorUpload(errors, touched, [business_card_photo1_url.name, business_card_photo2_url.name]) && (
          <Typography variant="caption" className={classes.textError}>
            {t('message.MSG_13')}
          </Typography>
        )}
        <Grid container spacing={3} className="box-form box-upload">
          <Grid item md={12} lg={6} className={classes.card}>
            <MediaCard
              cardID="media-card-21"
              imgSrc={BUSINESS_IMAGE_DEFAULT_BEFORE}
              description={t('verification.placeholder_provider_business_card')}
              card={business_card_photo1_url}
              cardUrl={business_card_photo1_url}
              cardFile={business_card_photo1_file}
              fileName={t('component.filename_image_business', {
                customerName: values[full_name.name],
                customerID: values[customer_code.name],
                number: 1,
              })}
            />
          </Grid>
          <Grid item md={12} lg={6} className={classes.card}>
            <MediaCard
              cardID="media-card-22"
              imgSrc={BUSINESS_IMAGE_DEFAULT_AFTER}
              description={t('verification.placeholder_provider_business_card_behind')}
              card={business_card_photo2_url}
              cardUrl={business_card_photo2_url}
              cardFile={business_card_photo2_file}
              fileName={`${t('component.filename_image_business', {
                customerName: values[full_name.name],
                customerID: values[customer_code.name],
                number: 2,
              })}.jpeg`}
            />
          </Grid>
          <Typography variant="caption" className={classes.textCaption}>
            {t('verification.text_detail_upload_image')}
          </Typography>
        </Grid>
      </Grid>
      <Grid className="verify-box-item" item xs={12}>
        <div className="title-item">{t('verification.legal_entity_photos')}</div>
        <Typography variant="h4" component="h5" className={classes.heading}>
          {`${t('verification.legal_entity_photos')}: ${countValueUpload([values.legal_entity_photo1_url, values.legal_entity_photo2_url])}/2 ${t(
            'common.text_photo'
          )}`}
        </Typography>
        {isErrorUpload(errors, touched, [legal_entity_photo1_url.name, legal_entity_photo2_url.name]) && (
          <Typography variant="caption" className={classes.textError}>
            {t('message.MSG_13')}
          </Typography>
        )}{' '}
        <Grid container spacing={3} className="box-form box-upload">
          <Grid item md={12} lg={6} className={classes.card}>
            <MediaCard
              cardID="media-card-23"
              imgSrc={LEGAL_IMAGE_DEFAULT_BEFORE}
              description={t('verification.placeholder_provider_business_card')}
              card={legal_entity_photo1_url}
              cardUrl={legal_entity_photo1_url}
              cardFile={legal_entity_photo1_file}
              fileName={t('component.filename_image_legal', {
                customerName: values[full_name.name],
                customerID: values[customer_code.name],
                number: 1,
              })}
            />
          </Grid>
          <Grid item md={12} lg={6} className={classes.card}>
            <MediaCard
              cardID="media-card-24"
              imgSrc={LEGAL_IMAGE_DEFAULT_AFTER}
              description={t('verification.placeholder_provider_business_card_behind')}
              card={legal_entity_photo2_url}
              cardUrl={legal_entity_photo2_url}
              cardFile={legal_entity_photo2_file}
              fileName={`${t('component.filename_image_legal', {
                customerName: values[full_name.name],
                customerID: values[customer_code.name],
                number: 2,
              })}.jpeg`}
            />
          </Grid>
          <Typography variant="caption" className={classes.textCaption}>
            {t('verification.text_detail_upload_image')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
