import React, { Fragment, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Information } from '../../../components';

function Step4From({}) {
  const { values } = useFormikContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Information data={values} />
    </Fragment>
  );
}

export default Step4From;
