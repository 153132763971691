import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { DOCUMENT_TYPE_NAME } from '../../common/constant';
import clsx from 'clsx';
import { formatDate } from '../../utils';
import { themConfigModalOverflow, themeConfigButtonExport, themeConfigLink, themeStatusConfig } from '../../config/theme';
// import { themConfigModalOverflow, themeConfigButtonSave, themeStatusConfig } from "../../utils/themeConfig";

export const useStyles = makeStyles(theme => ({
    buttonExport: {
        ...themeConfigButtonExport(theme).buttonExport,
        marginBottom: theme.spacing(4),
    },
    ...themeConfigLink(theme),
    ...themeStatusConfig(theme, 117),
    buttonCloseModalDetail: {
        display: 'block',
        margin: 'auto',
        width: '274px',
    },
    boxButtonClose: {
        width: '100%',
        marginTop: theme.spacing(6),
    },
    boxModalKycHistory: {
        padding: theme.spacing(4),
        ...themConfigModalOverflow(),
    },
}));

//config colum
export const columns = t => {
    return [
        { dataIndex: 'no', minWidth: 35, align: 'center', label: t('list_kyc.col_no'), isSorter: false },
        { dataIndex: 'created_at', minWidth: 120, align: 'left', label: t('list_kyc.col_edit_date'), isSorter: true },
        { dataIndex: 'request_code', minWidth: 120, align: 'left', label: t('list_kyc.col_request_id'), isSorter: true },
        { dataIndex: 'reason', width: 400, align: 'left', label: t('list_kyc.col_reason'), isSorter: false },
        { dataIndex: 'status', minWidth: 120, align: 'center', label: t('list_kyc.col_status'), isSorter: false },
        { dataIndex: 'action', minWidth: 120, align: 'center', label: t('list_kyc.col_action'), isSorter: false },
    ];
};

//render data to table
export const renderDataKycRequest = (listKyc, filters, classes, t, callbackRequestKyc) => {
    return listKyc?.map((item, index) => {
        const { page, pageSize } = filters;
        return {
            no: index + 1 + page * pageSize,
            code: item.code,
            created_at: formatDate(item.created_at),
            request_code: item.request_code,
            reason:<p title={item.reason} className="col-overflow">{item.reason} </p>,
            status: renderStatusKYC(classes, item?.status, t),
            action: (
                <Link className={classes.link} to="#" onClick={callbackRequestKyc(item)}>
                    {t('list_kyc.label_view_detail')}
                </Link>
            ),
        };
    });
};

//render kyc status DOM
export const renderStatusKYC = (classes, status, t) => {
    let value = status === 'REQUESTING' ? 'PENDING' : status;
    return value && (
        <span
            className={clsx(classes.status, {
                [classes.statusVerify]: status === 'VERIFYING',
                [classes.statusApproved]: status === 'APPROVED',
                [classes.statusRejected]: status === 'REJECTED',
                [classes.statusRequesting]: status === 'REQUESTING',
            })}>
            {t(`verification.text_status_${value?.toLowerCase()}`)}
        </span>
    );
};
