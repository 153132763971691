import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  customer: {},
  type: '',
  error: '',
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_CUSTOMER_CODE_SUCCESS:
      return {
        ...state,
        type: action.type,
        customer: action.payload,
      };

    case ActionTypes.GET_USER_INFORMATION_SUCCESS:
      return {
        ...state,
        type: action.type,
        customer: action.payload,
      };

    case ActionTypes.RESET_TYPE_OF_ACTION:
      return {
        ...state,
        type: '',
      };
    default:
      return state;
  }
};
