import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { TableComponent } from '../../components';
import { actions } from './actions';
import { PARAMS_FILTER_DEFAULT, END_POINT_EXPORT, KEY_UPLOAD } from '../../common/constant';
import { columns, renderDataKycRequest, useStyles } from './style';
import _ from 'lodash';
import { changeTable, dateNowNoSpecial } from '../../utils';
import ModalDetailHistory from './ModalDetailHistory';
import { AuthenticatedLink } from '../../components/AuthenticatedLink';

function KYCHistory() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const listData = useSelector(state => state.kycHistory.listData);
  const dataChange = useSelector(state => state.kycHistory.dataChange);
  const [visibelModalEditDetail, setVisibelModalEditDetail] = useState(false);
  const [filters, setFilters] = useState(PARAMS_FILTER_DEFAULT);
  const [requestDetail, setRequestDetail] = useState([]);
  const customerCode = useSelector(state => state.kyc.information.customer_code);

  //close or open modal kyc detail
  const closeModalDetail = useCallback(() => {
    setVisibelModalEditDetail(false);
  }, [visibelModalEditDetail]);

  //when-click view kyc detail
  const viewKYCDetails = useCallback(
    requestDetail => () => {
      setRequestDetail(requestDetail);
      dispatch(actions.getDetailKYCStart(requestDetail.kyc_id));
    },
    [dispatch]
  );

  useEffect(() => {
    setVisibelModalEditDetail(dataChange.length ? true : false);
  }, [dataChange]);

  const renderDataChange = data => {
    return {
      idData: data?.filter(item => item.key === 'id')[0],
      dataNormal: data?.filter(item => !KEY_UPLOAD.includes(item.key) && item.key !== 'id'),
      dataUpload: data?.filter(item => KEY_UPLOAD.includes(item.key)),
      // dataUploadBusiness: data?.filter(item => KEY_UPLOAD_BUSINESS_DETAIL.includes(item.key)),
      // dataUploadLegal: data?.filter(item => KEY_UPLOAD_LEGAL_DETAIL.includes(item.key)),
    };
  };

  //listener change filter
  useEffect(() => {
    const { order, orderBy, page, pageSize } = filters;
    dispatch(
      actions.getListRequestKYCStart({
        ...filters,
        page: page + 1,
        page_size: pageSize,
        order_type: order,
        order_by: orderBy,
      })
    );
  }, [filters]);

  const memoizedButtonExport = useMemo(() => {
    return (
      <AuthenticatedLink
        url={END_POINT_EXPORT.KYC_EDIT_REQUEST}
        filename={`${t('file_name.kyc_edit_history')}_${dateNowNoSpecial()}.csv`}
        children={
          <Button startIcon={<SaveAltIcon />} className={classes.buttonExport}>
            {t('my_kyc.button_export_data')}
          </Button>
        }
      />
    );
  }, [t]);

  return (
    <div className={`customer-management table-kyc-list`}>
      <Grid container>
        <Grid item xs={12}>
          {memoizedButtonExport}
        </Grid>
        <Grid item xs={12}>
          <TableComponent
            headCells={columns(t)}
            rows={renderDataKycRequest(listData.data, filters, classes, t, viewKYCDetails)}
            currentPage={filters.page}
            rowPerPage={filters.pageSize}
            order={filters.order}
            orderBy={filters.orderBy}
            rowLength={listData.total}
            handleFilters={changeTable(setFilters, filters).handleFilters}
            onChangePage={changeTable(setFilters, filters).handleChangePage}
            onChangeRowsPerPage={changeTable(setFilters, filters).handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
      <ModalDetailHistory
        toggleModal={closeModalDetail}
        visible={visibelModalEditDetail}
        classes={classes}
        customerCode={customerCode}
        dataChange={renderDataChange(dataChange)}
        requestDetail={requestDetail}
        t={t}
      />
    </div>
  );
}
export default KYCHistory;
