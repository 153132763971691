import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={80} height={80} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M31.25 55h17.5v2.5h-17.5V55zM26.25 55h2.5v2.5h-2.5V55z" fill="#666A74" />
      <path
        d="M76.25 7.5h-17.5V6.25A3.761 3.761 0 0055 2.5H25a3.761 3.761 0 00-3.75 3.75v5H3.75A1.25 1.25 0 002.5 12.5v15a1.25 1.25 0 001.25 1.25h17.5v2.5A6.25 6.25 0 0015 37.5v5.112L17.5 68.8v7.45a1.25 1.25 0 001.25 1.25h27.5a1.25 1.25 0 001.25-1.25v-7.1l4.65-6.65H55a3.762 3.762 0 003.75-3.75V57.5H60a4.982 4.982 0 003.275-8.75 4.938 4.938 0 000-7.5 4.938 4.938 0 000-7.5A4.982 4.982 0 0060 25h-1.25v-2.5h17.5a1.25 1.25 0 001.25-1.25V8.75a1.25 1.25 0 00-1.25-1.25zM49.65 5l-.387 1.55a1.25 1.25 0 01-1.213.95h-16.1a1.25 1.25 0 01-1.212-.95L30.35 5h19.3zM5 26.25v-12.5h22.5v12.5H5zM45 75H20v-5h25v5zm.6-7.5H19.887l-2.387-25v-5a3.762 3.762 0 013.75-3.75H30a3.75 3.75 0 010 7.5h-7.5a1.25 1.25 0 00-1.25 1.25v16.25A3.762 3.762 0 0025 62.5h24.1l-3.5 5zm10.65-8.75A1.25 1.25 0 0155 60H25a1.25 1.25 0 01-1.25-1.25v-15H30a6.25 6.25 0 000-12.5h-6.25v-2.5h5A1.25 1.25 0 0030 27.5v-15a1.25 1.25 0 00-1.25-1.25h-5v-5A1.25 1.25 0 0125 5h2.775l.538 2.162A3.75 3.75 0 0031.95 10h16.1a3.75 3.75 0 003.638-2.838L52.224 5H55a1.25 1.25 0 011.25 1.25V7.5h-2.5a1.25 1.25 0 00-1.25 1.25v12.5a1.25 1.25 0 001.25 1.25h2.5V25H55a4.982 4.982 0 00-3.275 8.75 4.938 4.938 0 000 7.5 4.938 4.938 0 000 7.5A4.982 4.982 0 0055 57.5h1.25v1.25zM60 55h-5a2.5 2.5 0 010-5h5a2.5 2.5 0 010 5zm0-7.5h-5a2.5 2.5 0 010-5h5a2.5 2.5 0 010 5zm0-7.5h-5a2.5 2.5 0 010-5h5a2.5 2.5 0 010 5zm0-12.5a2.5 2.5 0 010 5h-5a2.5 2.5 0 010-5h5zM75 20H55V10h20v10z"
        fill="#666A74"
      />
      <path
        d="M7.5 16.25H10v2.5H7.5v-2.5zM12.5 16.25H25v2.5H12.5v-2.5zM7.5 21.25H25v2.5H7.5v-2.5zM57.5 13.75h5v2.5h-5v-2.5zM65 13.75h7.5v2.5H65v-2.5z"
        fill="#666A74"
      />
    </svg>
  );
}

export default SvgComponent;
