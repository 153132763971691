import React from 'react';
import { useTranslation } from 'react-i18next';

function FooterComponent() {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="container">
        <div>{t('common.text_copyright')}</div>
      </div>
    </div>
  );
}

export default FooterComponent;
