export const ActionTypes = {
  GET_LIST_REQUEST_KYC_START: 'GET_LIST_REQUEST_KYC_START',
  GET_LIST_REQUEST_KYC_SUCCESS: 'GET_LIST_REQUEST_KYC_SUCCESS',
  GET_LIST_REQUEST_KYC_ERROR: 'GET_LIST_REQUEST_KYC_ERROR',
  GET_DETAIL_KYC_START: 'GET_DETAIL_KYC_START',
  GET_DETAIL_KYC_SUCCESS: 'GET_DETAIL_KYC_SUCCESS',
  GET_DETAIL_KYC_ERROR: 'GET_DETAIL_KYC_ERROR',
};

export const actions = {
  getListRequestKYCStart: params => ({ type: ActionTypes.GET_LIST_REQUEST_KYC_START, params }),
  getListRequestKYCSuccess: data => ({ type: ActionTypes.GET_LIST_REQUEST_KYC_SUCCESS, payload: data }),
  getListRequestKYCError: error => ({ type: ActionTypes.GET_LIST_REQUEST_KYC_ERROR, payload: error }),
  getDetailKYCStart: requestId => ({ type: ActionTypes.GET_DETAIL_KYC_START, requestId }),
  getDetailKYCSuccess: data => ({ type: ActionTypes.GET_DETAIL_KYC_SUCCESS, payload: data }),
  getDetailKYCError: error => ({ type: ActionTypes.GET_DETAIL_KYC_ERROR, payload: error }),
};
