import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  loading: false,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.HIDE_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
