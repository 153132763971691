import { Button } from '@material-ui/core';
import React from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleModal } from '../../../../components/Modal';

function ConfirmModal({ isOpeningModal, toggleModal, handleConfirm }) {
  const { t } = useTranslation();

  return (
    <SimpleModal
      isOpeningModal={isOpeningModal}
      toggleModal={toggleModal}
      className="modal-confirm"
      classNameBox="modal-container"
      width="unset"
    >
      <Fragment>
        <div className="title">{t('verification.confirm_modal_title')}</div>
        <div className="description">{t('verification.confirm_modal_description')}</div>
        <Button
          variant="contained"
          className="button button-gradient"
          onClick={() => {
            handleConfirm(true);
            toggleModal();
          }}
          fullWidth
        >
          {t('common.text_yes')}
        </Button>
        <Button
          className="button button-transparent"
          onClick={() => {
            handleConfirm(false);
            toggleModal();
          }}
          fullWidth
        >
          {t('common.text_no')}
        </Button>
      </Fragment>
    </SimpleModal>
  );
}

export default ConfirmModal;
