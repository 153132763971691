import React, { Fragment } from 'react';
import { Box, Button, Link, Typography } from '@material-ui/core';
import { Input, InputPhoneNumber } from '../../../components';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { DEFAULT_COUNTRY, MAX_LENGTH_INPUT } from '../../../common/constant';

function LoginForm({ isEmailInput, handleChangeInputType, t, handleSubmitForm }) {
  const validationEmailSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('message.MSG_2'))
      .trim()
      .required(t('message.MSG_4', { fieldName: t('home.text_email') })),
  });

  const validationPhoneNumberSchema = Yup.object().shape({
    phoneNumber: Yup.mixed()
      .test('phoneNumber', function (value) {
        return (
          (value?.length < 4 &&
            this.createError({
              message: t('message.MSG_4', { fieldName: t('home.text_phone_number') }),
            })) ||
          (value?.length < 11 &&
            this.createError({
              message: t('message.MSG_39'),
            })) ||
          true
        );
      })
      .required(t('message.MSG_4', { fieldName: t('home.text_phone_number') })),
  });

  const handleChangePhoneNumber = (setFieldValue, fieldName) => (value) => {
    setFieldValue(fieldName, value);
  };

  const handleSubmit = (data) => {
    handleSubmitForm({
      contact: data,
      contactMethod: isEmailInput ? 'EMAIL' : 'PHONE',
    });
  };

  return (
    <Fragment>
      <Typography variant="h5" component="h5" className="section-login__title">
        {t('home.section_login__title')}
      </Typography>
      <Typography variant="body2" className="section-login__description">
        {t('home.section_login__description')}
      </Typography>
      {isEmailInput && (
        <Formik initialValues={{ email: '' }} validationSchema={validationEmailSchema} onSubmit={handleSubmit}>
          <Form>
            <Field
              name="email"
              className="section-login__input"
              placeholder={t('home.section_login__input_email_placeholder')}
              maxLength={MAX_LENGTH_INPUT}
              fullWidth
              component={Input}
            />
            <Box className="section-login__link">
              <Link href="#" onClick={handleChangeInputType}>
                {t('home.section_login_text_use_phone_number')}
              </Link>
            </Box>
            <Button type="submit" variant="contained" className="button button-primary section-login__button" fullWidth>
              {t('home.button_direct_to_kyc_link')}
            </Button>
          </Form>
        </Formik>
      )}
      {!isEmailInput && (
        <Formik
          initialValues={{ phoneNumber: '' }}
          validationSchema={validationPhoneNumberSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Field
                name="phoneNumber"
                className="phone-number"
                containerClass="input-container"
                placeholder={t('home.section_login__input_phone_number_placeholder')}
                defaultCountry={DEFAULT_COUNTRY}
                value={values.phoneNumber}
                onChange={handleChangePhoneNumber(setFieldValue, 'phoneNumber')}
                component={InputPhoneNumber}
              />
              <Box className="section-login__link">
                <Link href="#" onClick={handleChangeInputType}>
                  {t('home.section_login_text_use_email')}
                </Link>
              </Box>
              <Button
                type="submit"
                variant="contained"
                className="button button-primary section-login__button"
                fullWidth
              >
                {t('home.button_direct_to_kyc_link')}
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </Fragment>
  );
}

export default LoginForm;
