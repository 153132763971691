import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import PropTypes from 'prop-types';

CardComponent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  list: PropTypes.array,
  thumbnail: PropTypes.string,
};

CardComponent.defaultProps = {
  title: '',
  description: '',
  list: [],
  thumbnail: '',
};

function CardComponent({ title, description, list, thumbnail }) {
  return (
    <Card className="card" variant="outlined">
      <CardContent>
        <img src={thumbnail} className="card__thumbnail" alt="card thumbnail" />
        <div className="card__title">{title}</div>
        <div className="card__description">{description}</div>
        <ul className="card__list">
          {list.map((item, index) => (
            <li className="card__item" key={index}>
              {item}
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
}

export default CardComponent;
