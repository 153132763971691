import { api } from './api';

export const getListUserAPI = (params) => {
  return api.get('/users/list', params);
};
export const blockUserAPI = (id, params) => {
  return api.post(`block/${id}`, params);
};

export const getUserInformationAPI = (params) => {
  const { contactAddress, contactMethod } = params;

  return api.get('customer/sensitive-info', {
    contact_address: contactAddress,
    contact_method: contactMethod,
  });
};
