import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider, withSnackbar } from 'notistack';

const mountPoint = document.createElement('div');
document.body.appendChild(mountPoint);

export default {
  success: function (message) {
    this.toast(message, 'success');
  },
  warning: function (message) {
    this.toast(message, 'warning');
  },
  error: function (message) {
    this.toast(message, 'error');
  },
  info: function (message) {
    this.toast(message, 'info');
  },
  toast: function (message, variant) {
    const Display = withSnackbar(({ message, enqueueSnackbar }) => {
      enqueueSnackbar(message, { variant });
      return null;
    });
    ReactDOM.render(
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        autoHideDuration={3000}
        preventDuplicate
      >
        <Display message={message} variant={variant} />
      </SnackbarProvider>,
      mountPoint
    );
  },
};
