import { api } from './api';

export const getKYCInformationAPI = () => {
  return api.get('customer/kyc/info');
};

export const updateKYCInformationAPI = (data) => {
  return api.put('customer/kyc/update', data);
};

export const requestEditKYCAPI = (data) => {
  return api.post('/customer/kyc/request-edit/create', data);
};

export const getListRequestKYCAPI = (params) => {
  return api.get('/customer/kyc/history', params);
};

export const getDetailKYCAPI = (requestId) => {
  return api.get(`customer/kyc/${requestId}/detail`);
};

export const getDetailKYCHistoryPageAPI = (customerId, kycId) => {
  return api.get(`/customer/kyc/info?kyc_id=${kycId}`);
};

export const verifyPhoneNumberAPI = (phone) => {
  return api.get('/customer/kyc/check-phone-existed', {
    phone,
  });
};

export const verifyEmailAPI = (email) => {
  return api.get('/customer/kyc/check-email-existed', {
    email,
  });
};

export const exportKYC = () => {
  return '/customer/kyc/info/export';
};
