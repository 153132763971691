import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, getGender } from '../../../utils';
import ContentChild from '../../ContentChild';

function HealthInsuranceCardContent({ values }) {
  const { t } = useTranslation();
  const {
    idoc_number,
    idoc_birthday,
    idoc_expired_date,
    idoc_full_name,
    idoc_gender,
    idoc_applicable_date,
    idoc_address,
    idoc_supply_no,
  } = values;
  const documentContents = [
    {
      label: t('verification.text_card_number'),
      value: idoc_number,
    },
    {
      label: t('verification.text_date_of_expiry'),
      value: formatDate(idoc_expired_date),
    },
    {
      label: t('verification.text_full_name'),
      value: idoc_full_name,
    },
    {
      label: t('verification.text_date_of_birth'),
      value: formatDate(idoc_birthday),
    },
    {
      label: t('verification.text_gender'),
      value: getGender(idoc_gender, t),
    },
    {
      label: t('verification.text_applicable_date'),
      value: formatDate(idoc_applicable_date),
    },
    {
      label: t('verification.text_address'),
      value: idoc_address,
    },
    {
      label: t('verification.text_insurer_number'),
      value: idoc_supply_no,
    },
  ];

  return (
    <Fragment>
      {documentContents.map((document, index) => (
        <ContentChild key={index} label={document.label} value={document.value} />
      ))}
    </Fragment>
  );
}

export default HealthInsuranceCardContent;
