import React, { useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';
import { MAX_LENGTH_INPUT } from '../../../common/constant';
import { Input, InputPhoneNumber } from '../../../components';

export const LegalEntityDocuments = ({ formField, idocType }) => {
  const { t } = useTranslation();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const {
    business_card_pic,
    full_name,
    business_card_company,
    business_card_company_addr,
    business_card_phone,
    business_card_mobile,
    business_card_fax,
    business_card_email,
    business_card_web,
    legal_entity_company,
    legal_entity_representative_name,
    legal_entity_company_business,
    legal_entity_license,
  } = formField;

  const handleChangePhoneNumber = (setFieldValue, fieldName) => value => {
    setFieldValue(fieldName, value);
    setFieldTouched(fieldName, true);
  };

  useEffect(() => {
    if (!values[business_card_pic.name] && idocType) {
      setFieldValue(business_card_pic.name, values[full_name.name]);
    }
  }, [idocType]);
  return (
    <Grid item xs={12}>
      <div className="title-form-underline in-verify-form">{t('verification.text_legal_entity_documents')}</div>
      <Grid className="verify-box-item" item xs={12}>
        <div className="title-item">{t('verification.text_business_card')}</div>
        <Grid container spacing={3} className="box-form">
          <Grid item xs={12}>
            <Field
              name={business_card_pic.name}
              label={business_card_pic.label}
              placeholder={business_card_pic.placeholder}
              value={values.business_card_pic}
              maxLength={MAX_LENGTH_INPUT}
              fullWidth
              component={Input}
            />
            <Field
              name={business_card_company.name}
              label={business_card_company.label}
              placeholder={business_card_company.placeholder}
              value={values.business_card_company}
              maxLength={MAX_LENGTH_INPUT}
              fullWidth
              component={Input}
            />
            <Field
              name={business_card_company_addr.name}
              label={business_card_company_addr.label}
              placeholder={business_card_company_addr.placeholder}
              value={values.business_card_company_addr}
              maxLength={MAX_LENGTH_INPUT}
              fullWidth
              component={Input}
            />
            <Field
              name={business_card_phone.name}
              label={business_card_phone.label}
              placeholder={business_card_phone.placeholder}
              onChange={handleChangePhoneNumber(setFieldValue, business_card_phone.name)}
              value={values[business_card_phone.name]}
              component={InputPhoneNumber}
            />
            <Field
              name={business_card_fax.name}
              label={business_card_fax.label}
              placeholder={business_card_fax.placeholder}
              onChange={handleChangePhoneNumber(setFieldValue, business_card_fax.name)}
              value={values[business_card_fax.name]}
              component={InputPhoneNumber}
            />
            <Field
              name={business_card_mobile.name}
              label={business_card_mobile.label}
              placeholder={business_card_mobile.placeholder}
              onChange={handleChangePhoneNumber(setFieldValue, business_card_mobile.name)}
              value={values[business_card_mobile.name]}
              component={InputPhoneNumber}
            />
            <Field
              name={business_card_email.name}
              label={business_card_email.label}
              placeholder={business_card_email.placeholder}
              maxLength={MAX_LENGTH_INPUT}
              fullWidth
              component={Input}
            />
            <Field
              name={business_card_web.name}
              label={business_card_web.label}
              placeholder={business_card_web.placeholder}
              maxLength={MAX_LENGTH_INPUT}
              fullWidth
              component={Input}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className="verify-box-item" item xs={12}>
        <div className="title-item">{t('verification.text_legal_entity_proof')}</div>
        <Grid container spacing={3} className="box-form">
          <Grid item xs={12}>
            <Field
              name={legal_entity_company.name}
              label={legal_entity_company.label}
              placeholder={legal_entity_company.placeholder}
              value={values.legal_entity_company}
              maxLength={MAX_LENGTH_INPUT}
              fullWidth
              component={Input}
            />
            <Field
              name={legal_entity_representative_name.name}
              label={legal_entity_representative_name.label}
              placeholder={legal_entity_representative_name.placeholder}
              value={values.legal_entity_representative_name}
              maxLength={MAX_LENGTH_INPUT}
              fullWidth
              component={Input}
            />
            <Field
              name={legal_entity_company_business.name}
              label={legal_entity_company_business.label}
              placeholder={legal_entity_company_business.placeholder}
              value={values.legal_entity_company_business}
              maxLength={MAX_LENGTH_INPUT}
              fullWidth
              component={Input}
            />
            <Field
              name={legal_entity_license.name}
              label={legal_entity_license.label}
              placeholder={legal_entity_license.placeholder}
              value={values.legal_entity_license}
              maxLength={MAX_LENGTH_INPUT}
              fullWidth
              component={Input}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
