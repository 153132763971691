import React, { useEffect, useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import OTPInput from 'react-otp-input';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useSelector } from 'react-redux';

import { OTP_TIME, STATUS_LOGIN } from '../../../common/constant';
import { isUndefined } from 'lodash-es';

function OTPCodePage({ switchPage, handleResetOTP, OTPTime = OTP_TIME, handleSubmitOTPCode, method = 'PHONE' }) {
  const { t } = useTranslation();
  const customer = useSelector(state => state.home.customer);

  const [counterTimer, setCounterTimer] = useState('');

  const { censored_email, censored_phone } = customer;

  const initialValues = {
    otpNumber: '',
  };
  const [isCountTime, setIsCountTime] = useState(false);

  const validationSchema = Yup.object().shape({
    otpNumber: Yup.string()
      .min(6, t('message.MSG_7'))
      .required(t('message.MSG_4', { fieldName: 'OTP' })),
  });

  const formatDate = (minutes, seconds) => {
    if (isUndefined(minutes) || isUndefined(seconds)) return;

    const m = `0${minutes}`.slice(-2);
    const s = `0${seconds}`.slice(-2);

    return `${m}:${s}`;
  };

  useEffect(() => {
    let timer = Math.floor(OTPTime),
      minutes,
      seconds;

    if (!timer) {
      setCounterTimer('');
      return;
    }

    let t =
      !isCountTime &&
      setInterval(function () {
        minutes = Math.floor(timer / 60);
        seconds = Math.floor(timer % 60);

        const newDate = formatDate(minutes, seconds);
        setCounterTimer(newDate);
        if (--timer < 0) {
          setCounterTimer('');
          turnOffCountTime();
          clearInterval(t);
        }
      }, 1000);

    return () => {
      clearInterval(t);
    };
  }, [isCountTime, OTPTime]);

  const turnOffCountTime = () => {
    setIsCountTime(true);
  };

  const handleChangeOTP = setFieldValue => otp => {
    setFieldValue('otpNumber', otp);
  };

  const handleResetOTPCode = () => {
    handleResetOTP();
    setIsCountTime(false);
  };

  const handleBackPage = () => {
    switchPage(STATUS_LOGIN.DEFAULT);
  };

  return (
    <div className="otp-code">
      <div className="otp-code__container">
        <button onClick={handleBackPage} className="button-back">
          <KeyboardBackspaceIcon /> {t('login.button_back')}
        </button>
        <div className="box">
          <Typography variant="h2" component="h2" className="title">
            {t('login.text_title')}
          </Typography>
          {method === 'EMAIL' && (
            <span className="detail">{t('login.text_detail', { value: t('login.text_email', { value: censored_email }) })}</span>
          )}
          {method === 'PHONE' && (
            <span className="detail">{t('login.text_detail', { value: t('login.text_phone_number', { value: censored_phone }) })}</span>
          )}
          <Typography variant="h3" component="h3" id="time-counter" className="time-number">
            {counterTimer || '--:--'}
          </Typography>
          <Button variant="contained" className="button button-resend" disabled={!isCountTime} onClick={handleResetOTPCode}>
            {t('login.button_resend_otp')}
          </Button>
          <Formik initialValues={initialValues} validationSchema={validationSchema} validateOnMount onSubmit={handleSubmitOTPCode}>
            {({ values, setFieldValue, isValid }) => (
              <Form>
                <Field
                  name="otpNumber"
                  numInputs={6}
                  onChange={handleChangeOTP(setFieldValue)}
                  value={values.otpNumber}
                  shouldAutoFocus
                  isInputNum
                  containerStyle="otp-number"
                  inputStyle="input-number"
                  component={OTPInput}
                />
                <span className="message error-message">
                  <ErrorMessage name="otpNumber" />
                </span>
                <Button type="submit" variant="contained" className="button button-primary" disabled={!isValid} fullWidth>
                  {t('login.button_verify')}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default OTPCodePage;
