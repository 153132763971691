import React, { Fragment, useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import MediaCard from '../Components/Step3/mediaCard';
import IMAGE_DEFAULT_1 from '../../../resources/images/upload-card-image--default-1.jpg';
import IMAGE_DEFAULT_2 from '../../../resources/images/upload-card-image--default-2.jpg';
import IMAGE_DEFAULT_3 from '../../../resources/images/upload-card-video--default.png';
import { DOCUMENT_TYPE_NAME, getDocumentTypeOptions, REPRESENTATIVE_TYPE } from '../../../common/constant';
import { isEmpty } from '../../../utils';
import { LegalEntityUpload } from './LegalEntityUpload';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 24,
  },
  heading: {
    fontSize: 16,
    textAlign: 'center',
    color: '#1181F1',
    marginTop: 24,
  },
  card: {
    width: '100%',
  },
  content: {
    justifyContent: 'center',
  },
  textCaption: {
    fontStyle: 'italic',
  },
  textError: {
    color: theme.overrides.colorBurntSienna,
    display: 'block',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
}));

function renderHeading(documentType, t, ...props) {
  const number = props.filter(element => element).length;
  const document = getDocumentTypeOptions(t).find(item => item.key === documentType)?.value;
  if (documentType === 'PASSPORT') {
    return <Fragment>{`${document}: ${number}/2 ${t('common.text_photo')}`}</Fragment>;
  } else {
    return <Fragment>{`${document}: ${number}/3 ${t('common.text_photo')}`}</Fragment>;
  }
}

function Step3Form({ formField }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { values, errors, touched } = useFormikContext();
  const typeRepresentative = useSelector(state => state.auth.typeRepresentative);

  const {
    customer_code,
    full_name,
    idoc_photo1,
    idoc_photo2,
    idoc_video,
    idoc_photo1_url,
    idoc_photo2_url,
    idoc_video_url,
    idoc_photo1_file,
    idoc_photo2_file,
    idoc_video_file,
  } = formField;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Typography variant="h4" component="h5" className={classes.heading}>
        {renderHeading(values.idoc_type, t, values.idoc_photo1_url, values.idoc_photo2_url, values.idoc_video_url)}
      </Typography>
      {!isEmpty(errors) && !isEmpty(touched) && (
        <Typography variant="caption" className={classes.textError}>
          {values.idoc_type === 'PASSPORT' ? t('message.MSG_13') : t('message.MSG_12')}
        </Typography>
      )}
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={false} md={1} lg={1}></Grid>
        <Grid container item xs={12} md={10} lg={10} spacing={3} className={classes.content}>
          <Grid item md={12} lg={4} className={classes.card}>
            <MediaCard
              cardID="media-card-1"
              imgSrc={IMAGE_DEFAULT_1}
              description={t('verification.placeholder_media_card_1')}
              card={idoc_photo1}
              cardUrl={idoc_photo1_url}
              cardFile={idoc_photo1_file}
              fileName={t('component.filename_image', {
                customerName: values[full_name.name],
                customerID: values[customer_code.name],
                number: 1,
              })}
            />
          </Grid>
          <Grid item md={12} lg={4} className={classes.card}>
            <MediaCard
              cardID="media-card-2"
              imgSrc={IMAGE_DEFAULT_2}
              description={t('verification.placeholder_media_card_2')}
              card={idoc_photo2}
              cardUrl={idoc_photo2_url}
              cardFile={idoc_photo2_file}
              fileName={`${t('component.filename_image', {
                customerName: values[full_name.name],
                customerID: values[customer_code.name],
                number: 2,
              })}.jpeg`}
            />
          </Grid>
          {DOCUMENT_TYPE_NAME[values.idoc_type] !== 'Passport' && (
            <Grid item md={12} lg={4} className={classes.card}>
              <MediaCard
                cardID="media-card-3"
                imgSrc={IMAGE_DEFAULT_3}
                description={t('verification.placeholder_media_card_3')}
                card={idoc_video}
                cardUrl={idoc_video_url}
                cardFile={idoc_video_file}
                fileName={`${t('component.filename_image', {
                  customerName: values[full_name.name],
                  customerID: values[customer_code.name],
                  number: 3,
                })}.jpeg`}
              />
            </Grid>
          )}
          <Typography variant="caption" className={classes.textCaption}>
            {t('verification.text_detail_upload_image')}
          </Typography>
        </Grid>
        {REPRESENTATIVE_TYPE.LEGAL_ENTITY === typeRepresentative && <LegalEntityUpload formField={formField} />}
        <Grid item xs={false} md={1} lg={1}></Grid>
      </Grid>
    </Fragment>
  );
}

export default Step3Form;
