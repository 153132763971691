import React from 'react';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { Route, Router, Switch } from 'react-router-dom';
import { enUS } from '@material-ui/core/locale';
import { Backdrop, CircularProgress, CssBaseline } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import { browserHistory } from './utils/history';
import { themeConfig } from './config/theme';
import './App.css';
import './styles/_app.scss';
import NotFound from './pages/notFound';
import { privateRoutes, publicRoutes } from './Routes';
import PublicLayout from './pages/layout/public';
import PrivateLayout from './pages/layout/private';

const theme = createMuiTheme(themeConfig, enUS);

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function App() {
  const classes = useStyles();
  const isLoading = useSelector((state) => state.app.loading);

  return (
    <div className="App bg-app">
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          autoHideDuration={3000}
          preventDuplicate
        >
          <Router history={browserHistory}>
            <Switch>
              {privateRoutes.map((route, index) => (
                <Route
                  path={route.path}
                  exact={route.exact}
                  key={index}
                  render={(props) => <PrivateLayout component={route.component} routeProps={props} />}
                />
              ))}
              {publicRoutes.map((route, index) => (
                <Route
                  path={route.path}
                  exact={route.exact}
                  key={index}
                  render={(props) => <PublicLayout component={route.component} routeProps={props} />}
                />
              ))}
              <Route path="*" component={NotFound} />
            </Switch>
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default withTranslation()(App);
