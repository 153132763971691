import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { Input, DatePicker, AutocompleteComponent, InputPhoneNumber } from '../../../components';
import { countries } from '../../../utils/countries';
import { DATE_FORMAT_FIELD, MAX_LENGTH_INPUT, MAX_LENGTH_TEXTAREA } from '../../../common/constant';

function Step1Form({ formField }) {
  const { full_name, birthday, nationality, email, phoneNumber, residential_address, isFirstKYC } = formField;
  const { setFieldValue, values, setFieldTouched } = useFormikContext();

  const handleDatePickerChange = date => {
    setFieldValue('birthday', moment(date).format(DATE_FORMAT_FIELD));
  };

  const handleChangePhoneNumber = (setFieldValue, fieldName) => value => {
    setFieldValue(fieldName, value);
    setFieldTouched(fieldName, true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={false} md={false} lg={2}></Grid>
      <Grid item xs={12} md={12} lg={8}>
        <Field
          name={full_name.name}
          label={full_name.label}
          placeholder={full_name.placeholder}
          value={values.full_name}
          maxLength={MAX_LENGTH_INPUT}
          fullWidth
          component={Input}
        />
        <Field
          name={birthday.name}
          label={birthday.label}
          placeholder={birthday.placeholder}
          value={values.birthday}
          maxDate={new Date()}
          format="yyyy/MM/dd"
          onChange={handleDatePickerChange}
          fullWidth
          component={DatePicker}
        />
        <Field
          name={nationality.name}
          label={nationality.label}
          labelField={nationality.label}
          placeholder={nationality.placeholder}
          options={countries}
          value={values.nationality}
          onChange={(event, newValue) => {
            setFieldValue(nationality.name, newValue);
          }}
          fullFieldWidth
          component={AutocompleteComponent}
        />
        <Field name={email.name} label={email.label} placeholder={email.placeholder} maxLength={MAX_LENGTH_INPUT} fullWidth component={Input} />
        <Field
          name={phoneNumber.name}
          label={phoneNumber.label}
          placeholder={phoneNumber.placeholder}
          onChange={handleChangePhoneNumber(setFieldValue, phoneNumber.name)}
          value={values[phoneNumber.name]}
          isDisabled={!!values[isFirstKYC.name]}
          component={InputPhoneNumber}
        />
        <Field
          name={residential_address.name}
          label={residential_address.label}
          placeholder={residential_address.placeholder}
          maxLength={MAX_LENGTH_TEXTAREA}
          fullWidth
          component={Input}
        />
      </Grid>
      <Grid item xs={false} md={false} lg={2}></Grid>
    </Grid>
  );
}

export default Step1Form;
