import { all } from 'redux-saga/effects';
import { watchAuthentication as watchHome } from '../pages/home/saga';
import { watchAuthentication } from '../pages/login/saga';
import { watchKYC } from '../pages/verification/saga';
import { watchKYCHistory } from '../pages/kycHistory/saga';
import { watchKYCHistoryDetail } from '../pages/kycHistoryDetail/saga';

function* rootSaga() {
  yield all([watchHome(), watchAuthentication(), watchKYC(), watchKYCHistory(), watchKYCHistoryDetail()]);
}
export default rootSaga;
