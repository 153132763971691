import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { isStatusApproved, isStatusRejected, isStatusRequesting, isStatusVerifying } from '../../utils';
import { getKYCStatusOptions, REPRESENTATIVE_TYPE } from '../../common/constant';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(3),
  },
  blockRight: {
    marginLeft: theme.spacing(2),
  },
  title: {
    color: theme.overrides.colorActive,
    fontSize: 24,
    marginBottom: 12,
  },
  status: {
    textTransform: 'lowercase',
    padding: theme.spacing(1, 3),

    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  statusRequesting: {
    color: theme.overrides.colorSeance,
    backgroundColor: 'rgba(156, 39, 176, 0.2)',
  },
  statusVerifying: {
    color: theme.overrides.colorOrangePeel,
    backgroundColor: 'rgba(255, 153, 0, 0.2)',
  },
  statusApproved: {
    color: theme.overrides.colorApple,
    backgroundColor: 'rgba(67, 160, 71, 0.2)',
  },
  statusRejected: {
    backgroundColor: 'rgba(239, 83, 80, 0.2)',
    color: theme.overrides.colorBurntSienna,
  },
}));

function ContentTopComponent({ customerCode = 0, status = '' }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const newStatus = getKYCStatusOptions(t).find(item => item.key === status)?.value;
  const typeRepresentative = useSelector(state => state.auth.typeRepresentative);

  return (
    <div className={classes.root}>
      <div className={classes.blockLeft}>
        <Typography variant="h5" component="h5" className={classes.title}>
          {t('verification.text_title')}
        </Typography>
        <span className="block block-left__id">
          <b>{t('verification.text_customer_id')}</b>
        </span>
        <span className="block block-left__value"> {customerCode}</span>
        {REPRESENTATIVE_TYPE.LEGAL_ENTITY === typeRepresentative && (
          <div className={classes.blockLeft}>
            <span className="block block-left__id">
              <b>{t('verification.text_representative_type')}: </b>
            </span>
            <span className="block block-left__value"> {t('verification.text_legal_entity')}</span>
          </div>
        )}
      </div>
      <div className={classes.blockRight}>
        <div
          className={clsx(classes.status, {
            [classes.statusRequesting]: isStatusRequesting(status),
            [classes.statusVerifying]: isStatusVerifying(status),
            [classes.statusApproved]: isStatusApproved(status),
            [classes.statusRejected]: isStatusRejected(status),
          })}>
          {newStatus}
        </div>
      </div>
    </div>
  );
}

export default ContentTopComponent;
