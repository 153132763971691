import { validateStatus } from '../utils/validateStatus';
import { HTTP_RESPONSE } from '../common/constant';
import _ from 'lodash';
import { Toast } from '../components';
import { store } from '../redux/configStore';
import { actions as authAction } from '../pages/login/actions';
import i18n from '../i18n/i18n';

const axios = require('axios');

export const HEADERS = {
  // "Authorization": "Basic " + base64.encode(Config.AUTH_USER_NAME + ":" + Config.AUTH_PASSWORD),
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Request-Method': 'GET, POST, DELETE, PUT, OPTIONS',
  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
};

const HEADERS_MULTIPLE_PART = {
  ...HEADERS,
  'Content-Type': 'multipart/form-data; boundary=something',
  Accept: 'application/json',
};

export const getToken = (token) => {
  // token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI5YjBmM2EyZi02M2I2LTQ3MmEtYmEzYS00NjZmMDRhMzBhMDIiLCJjdXN0b21lcl9pZCI6MywiaWF0IjoxNjE3Njk0MTkwLCJleHAiOjE2MjEyOTQxOTB9.nUjqY2BQbiofzK2wzqkez2lcGI2qFXukhVFijDZE2Rk";
  HEADERS['x-auth-token'] = token;
  HEADERS['Authorization'] = `Bearer ${token}`;
};

export const getFullUrl = (url) => {
  if (!url.startsWith('/')) {
    url = '/' + url;
  }
  return `${process.env.REACT_APP_API_URL}` + url;
};

const resetToLogin = () => {
  const promiseList = [];
  // promiseList.push(sessionStorage.removeItem('persist:root'));
  promiseList.push(store.dispatch(authAction.logout()));
};

const throttledResetToLogin = _.throttle(resetToLogin, 500, {
  leading: false,
  trailing: true,
});

const checkErrorStatus = (response) => {
  if (response?.success === false) {
    const errorCode = response?.error?.code.split('_')[1];
    const message = i18n.t(`message.MSG_${errorCode}`);
    Toast.error(message || response?.error?.message);
  }
  return response;
};

const checkErrorNetwork = (err) => {
  if (err?.toJSON() && err.toJSON().message === 'Network Error') {
    // return console.log(TYPE_OF_ANT_DESIGN.ERROR, getI18n().t(`message.MSG_39`));
  }
  return err;
};

const api = {
  post: (endpoint, params) => {
    return axios.default
      .post(getFullUrl(endpoint), params, {
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
            throttledResetToLogin(endpoint, params, response);
            return response?.data;
          }
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      );
  },

  postMultiplePart: (endpoint, params) => {
    return axios.default
      .post(getFullUrl(endpoint), params, {
        headers: HEADERS_MULTIPLE_PART,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
            throttledResetToLogin(endpoint, params, response);
            return response?.data;
          }
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      );
  },

  get: (endpoint, params = {}) => {
    return axios.default
      .get(getFullUrl(endpoint), {
        params: params,
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
            throttledResetToLogin(endpoint, params, response);
            return response?.data;
          }
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      );
  },

  put: (endpoint, params) => {
    return axios.default
      .put(getFullUrl(endpoint), params, {
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
            throttledResetToLogin(endpoint, params, response);
            return response?.data;
          }
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      );
  },

  delete: (endpoint, params) => {
    return axios.default
      .delete(getFullUrl(endpoint), {
        params: params,
        headers: HEADERS,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          if (response?.status === HTTP_RESPONSE.ERROR_CODE_401) {
            throttledResetToLogin(endpoint, params, response);
            return response?.data;
          }
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      )
      .catch(
        (response) => {
          return checkErrorStatus(response.data);
        },
        (err) => {
          return (err?.response?.data && checkErrorStatus(err.response.data)) || checkErrorNetwork(err);
        }
      );
  },
};

export { api };
