import { Button, Grid, Typography } from '@material-ui/core';
import React, { memo } from 'react';
import { getDocumentTypeOptions, getGenderOptions } from '../../../common/constant';
import { SimpleModal } from '../../../components/Modal';
import { formatCountries, formatDate } from '../../../utils';
import { renderStatusKYC } from '../style';
import _, { find } from 'lodash';
import ZoomPhotoChildImage from '../../../components/ZoomPhoto/childImage';
import EmptyData from '../../../components/EmptyData';
import './index.scss';
import { Fragment } from 'react';

function ModalKycHistoryDetail({
  toggleModal,
  customerCode,
  visible,
  t,
  classes,
  requestDetail,
  dataChange: { idData, dataNormal, dataUpload, dataUploadBusiness, dataUploadLegal },
}) {
  let changeIdocType = false;
  let dataDocType = {};
  dataNormal = dataNormal?.map(item => {
    let oldValue = item?.old_version_value;
    let newValue = item?.new_version_value;
    if (item.key === 'idoc_type') {
      changeIdocType = true;
      dataDocType = {
        ...item,
        key_new_value: newValue,
        key_old_value: oldValue,
        old_version_value: oldValue ? _.find(getDocumentTypeOptions(t), { key: oldValue.toUpperCase() })?.value : '',
        new_version_value: newValue ? _.find(getDocumentTypeOptions(t), { key: newValue.toUpperCase() })?.value : '',
      };
      return dataDocType;
    }
    if (item.key === 'idoc_gender') {
      return {
        ...item,
        old_version_value: oldValue ? _.find(getGenderOptions(t), { key: oldValue.toUpperCase() })?.value : '',
        new_version_value: newValue ? _.find(getGenderOptions(t), { key: newValue.toUpperCase() })?.value : '',
      };
    }
    if (item.key === 'idoc_nationality') {
      return {
        ...item,
        old_version_value: oldValue ? formatCountries(oldValue) : '',
        new_version_value: newValue ? formatCountries(newValue) : '',
      };
    }
    return item;
  });

  const openTabViewKYCHistory = id => () => {
    window.open(`/kyc-history-detail/${customerCode}/${id}`, '_blank');
  };

  return (
    <SimpleModal
      title={t('list_kyc.title_modal_kyc_edit_detail')}
      toggleModal={toggleModal}
      width={1140}
      classNameBox={`modal-history-box ${classes.boxModalKycHistory}`}
      classNameHeader="modal-history-header"
      classNameTitle="modal-history-title"
      isOpeningModal={visible}>
      <Grid container className="grid-info-request">
        <Grid item xs={12} md={3}>
          <div className="box-header border-info-right">
            <Typography variant="subtitle2" className={'subtitle-header'}>
              {t('list_kyc.label_request_id')}
            </Typography>
          </div>
          <div className="box-info-value border-info-right-no">{requestDetail?.request_code}</div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className="box-header border-info-right">
            <Typography variant="subtitle2" className={'subtitle-header'}>
              {t('list_kyc.col_edit_date')}
            </Typography>
          </div>
          <div className="box-info-value border-info-right-no">{formatDate(requestDetail?.created_at)}</div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className="box-header border-info-right">
            <Typography variant="subtitle2" className={'subtitle-header'}>
              {t('list_kyc.col_reason')}
            </Typography>
          </div>
          <div className="box-info-value border-info-right-no">{requestDetail?.reason}</div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className="box-header">
            <Typography variant="subtitle2" className={'subtitle-header'}>
              {t('list_kyc.col_status')}
            </Typography>
          </div>
          <div className="box-info-value colum">
            <div className="box-status">{renderStatusKYC(classes, requestDetail?.status, t)}</div>
            <div className="box-reason description-pre-line">{requestDetail?.reject_reason ? requestDetail.reject_reason : ''}</div>
          </div>
        </Grid>
      </Grid>
      <div className="kyc-detail-history-container">
        <table className="app-table table-kyc-history-info">
          <thead>
            <tr>
              <th rowSpan="2" className="left col-info-label">
                {t('list_kyc.title_table_information')}
              </th>
              <th colSpan="2" className="center">
                {t('list_kyc.title_table_changes')}
              </th>
            </tr>
            <tr>
              <th className="center" style={{ width: `410px` }}>
                {t('list_kyc.title_table_before')}
                <span onClick={openTabViewKYCHistory(idData?.old_version_value)} className="view-item">
                  {t('list_kyc.label_view')}
                </span>
              </th>
              <th className="center unset-border-left" style={{ width: `410px` }}>
                {t('list_kyc.title_table_after')}
                <span onClick={openTabViewKYCHistory(idData?.new_version_value)} className="view-item">
                  {t('list_kyc.label_view')}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {dataNormal?.map(item => {
              if (item?.key === 'idoc_number' && changeIdocType) {
                const idocType = find(dataNormal, { key: 'idoc_type' });
                const validateDocumentType = ['DRIVER_LICENSE', 'PASSPORT'].some(
                  item => item === idocType.key_old_value || item === idocType.key_new_value
                );
                return (
                  <Fragment key={item.key}>
                    {validateDocumentType ? (
                      <>
                        {item.old_version_value && (
                          <tr>
                            <td className="info-item">{t(`list_kyc.idoc_number_${dataDocType?.key_old_value}`)}</td>
                            <td className="center info-item old">{item?.old_version_value}</td>
                            <td className="center info-item"></td>
                          </tr>
                        )}
                        {item.new_version_value && (
                          <tr>
                            <td className="info-item">{t(`list_kyc.idoc_number_${dataDocType?.key_new_value}`)}</td>
                            <td className="center info-item old"></td>
                            <td className="center info-item">{item?.new_version_value}</td>
                          </tr>
                        )}
                      </>
                    ) : (
                      (item.new_version_value || item.old_version_value) && (
                        <tr key={`${item.key}`}>
                          <td className="info-item">{t(`list_kyc.idoc_number_${dataDocType?.key_new_value}`)}</td>
                          <td className="center info-item old">{item?.old_version_value}</td>
                          <td className="center info-item">{item?.new_version_value}</td>
                        </tr>
                      )
                    )}
                  </Fragment>
                );
              } else {
                let label = item?.key === 'idoc_number' && !dataDocType?.key_new_value ? `${item.key}_${requestDetail?.idoc_type}` : item?.key;
                return (
                  <tr key={item?.key}>
                    <td className="info-item">{t(`list_kyc.${label}`)}</td>
                    <td className="center info-item old">
                      {item?.old_version_value &&
                      ['idoc_birthday', 'idoc_issued_date', 'idoc_expired_date', 'idoc_applicable_date'].includes(item?.key)
                        ? formatDate(item?.old_version_value)
                        : item?.old_version_value}
                    </td>
                    <td className="center info-item">
                      {item?.new_version_value &&
                      ['idoc_birthday', 'idoc_issued_date', 'idoc_expired_date', 'idoc_applicable_date'].includes(item?.key)
                        ? formatDate(item?.new_version_value)
                        : item?.new_version_value}
                    </td>
                  </tr>
                );
              }
            })}
            {dataUpload?.map((item, index) => (
              <tr key={item.key}>
                {!index && (
                  <td rowSpan={dataUpload?.length} className="info-item">
                    {t('list_kyc.upload_photo')}
                  </td>
                )}
                <td className="center info-item old">
                  {item?.old_version_value && <ZoomPhotoChildImage width={200} alt={item.key} src={item.old_version_value} />}
                </td>
                <td className="center info-item">
                  {item?.new_version_value && <ZoomPhotoChildImage width={200} alt={item.key} src={item.new_version_value} />}
                </td>
              </tr>
            ))}
            {/* {dataUploadBusiness?.map((item, index) => (
              <tr key={item.key}>
                {!index && (
                  <td rowSpan={dataUploadBusiness?.length} className="info-item">
                    {t('list_kyc.business_card_photo')}
                  </td>
                )}
                <td className="center info-item old">
                  {item?.old_version_value && <ZoomPhotoChildImage width={200} alt={item.key} src={item.old_version_value} />}
                </td>
                <td className="center info-item">
                  {item?.new_version_value && <ZoomPhotoChildImage width={200} alt={item.key} src={item.new_version_value} />}
                </td>
              </tr>
            ))}
            {dataUploadLegal?.map((item, index) => (
              <tr key={item.key}>
                {!index && (
                  <td rowSpan={dataUploadLegal?.length} className="info-item">
                    {t('list_kyc.legal_entity_photo')}
                  </td>
                )}
                <td className="center info-item old">
                  {item?.old_version_value && <ZoomPhotoChildImage width={200} alt={item.key} src={item.old_version_value} />}
                </td>
                <td className="center info-item">
                  {item?.new_version_value && <ZoomPhotoChildImage width={200} alt={item.key} src={item.new_version_value} />}
                </td>
              </tr>
            ))} */}
          </tbody>
        </table>
        {!dataUpload?.length && !dataNormal?.length && <EmptyData text={t('common.text_no_data')} />}
      </div>
      <div className={classes.boxButtonClose}>
        <Button onClick={toggleModal} className={`button block button-gradient ${classes.buttonCloseModalDetail}`}>
          {t('common.text_close')}
        </Button>
      </div>
    </SimpleModal>
  );
}

export default memo(ModalKycHistoryDetail);
