import { ActionTypes } from "./actions";

const DEFAULT_STATE = {
  kycHistory: {},
};

export default (state = DEFAULT_STATE, action) => {
  const { type } = action;
  switch (type) {
    case ActionTypes.GET_KYC_HISTORY_SUCCESS:
      return { ...state, kycHistory: action.payload };
    default:
      return state;
  }
};
