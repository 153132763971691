import React, { useMemo, useState, Fragment, useCallback, useEffect } from 'react';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import OTPCodeComponent from './otp';
import IconMethodPhoneNumber from '../../resources/images/iconPhoneNumber';
import IconMethodEmail from '../../resources/images/iconEmail';
import { actions, ActionTypes } from './actions';
import { OTP_REPRESENT, REPRESENTATIVE_TYPE, STATUS_KYC, STATUS_LOGIN } from '../../common/constant';
import clsx from 'clsx';
import IconOfficeGroup from '../../resources/images/icon_office_group';
import IconIndependentUser from '../../resources/images/IconIndependentUser';

function LoginPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();

  // const [isSelectMethodPage, setIsSelectMethodPage] = useState(true);
  const [methodPage, setMethodPage] = useState(STATUS_LOGIN.DEFAULT);
  const [methodSelected, setMethodSelected] = useState('PHONE');
  const [representativeSelected, setRepresentativeSelected] = useState(REPRESENTATIVE_TYPE.LEGAL_ENTITY);
  const [OTPTime, setOTPTime] = useState(0);

  const customer = useSelector(state => state.home.customer);
  const currentUserTemp = useSelector(state => state.auth.currentUserTemp);
  const statusAction = useSelector(state => state.auth.type);
  const session = useSelector(state => state.auth.session);

  useEffect(() => {
    const { customer_code } = customer;
    if (!customer_code) {
      history.push('/');
    }
  }, [customer]);

  useEffect(() => {
    switch (statusAction) {
      case ActionTypes.CREATE_OTP_CODE_SUCCESS:
        calculateOTPTime(session.expired_at);
        switchPage(STATUS_LOGIN.VERIFY_OTP);
        dispatch(actions.resetTypeOfAction());
        break;
      case ActionTypes.RESEND_OTP_CODE_SUCCESS:
        calculateOTPTime(session.expired_at);
        dispatch(actions.resetTypeOfAction());
        break;
      case ActionTypes.RESEND_OTP_CODE_ERROR:
        calculateOTPTime(0);
        dispatch(actions.resetTypeOfAction());
        break;
      case ActionTypes.VERIFY_OTP_CODE_SUCCESS:
        if (currentUserTemp?.access_token && currentUserTemp.representative !== OTP_REPRESENT.UNDEFINE) {
          dispatch(actions.completeLogin(currentUserTemp?.representative));
          history.push('/information');
        }
        switchPage(STATUS_LOGIN.SELECT_REPRESENTATIVE);
        dispatch(actions.resetTypeOfAction());
        break;
      default:
        break;
    }
  }, [statusAction, currentUserTemp]);

  const calculateOTPTime = expiredAt => {
    const startAt = moment(new Date());
    const endAt = moment(expiredAt);
    setOTPTime(moment.duration(endAt.diff(startAt)).asSeconds());
  };

  const switchPage = method => {
    setMethodPage(method);
  };

  const handleChange = value => () => {
    setMethodSelected(value);
  };

  const handleChangeRepresentType = type => () => {
    setRepresentativeSelected(type);
  };

  const handleCompletedLogin = () => {
    dispatch(actions.completeLogin(representativeSelected));
    history.push('/information');
  };

  const handleSubmit = () => {
    const { customer_code } = customer;

    dispatch(
      actions.createOTPCodeStart({
        customer_code,
        contact_method: methodSelected,
      })
    );
  };

  const handleSubmitOTPCode = useCallback(
    data => {
      dispatch(
        actions.verifyOTPCodeStart({
          request_id: session.request_id,
          code: data.otpNumber,
        })
      );
    },
    [dispatch, session]
  );

  const handleResendOTPCode = useCallback(() => {
    dispatch(actions.resendOTPCodeStart(session.request_id));
  }, [dispatch, session]);

  const memoizedModal = useMemo(
    () => (
      <OTPCodeComponent
        switchPage={switchPage}
        handleResetOTP={handleResendOTPCode}
        OTPTime={OTPTime}
        handleSubmitOTPCode={handleSubmitOTPCode}
        method={methodSelected}
      />
    ),
    [switchPage, handleResendOTPCode, OTPTime, handleSubmitOTPCode, methodSelected]
  );

  return (
    <div className="login">
      {STATUS_LOGIN.DEFAULT === methodPage && (
        <div className="login__container">
          <Typography variant="h2" component="h2" className="title">
            {t('login.text_title')}
          </Typography>
          <div
            className="detail"
            dangerouslySetInnerHTML={{
              __html: t('login.text_description_send_otp'),
            }}
          />
          <div className="list">
            <div className="item">
              <Button
                variant="contained"
                className={methodSelected === 'PHONE' ? 'active' : ''}
                onClick={handleChange('PHONE')}
                disabled={!customer?.censored_phone}>
                <IconMethodPhoneNumber />
              </Button>
              <div className="item__content">{t('login.text_phone_number', { value: customer?.censored_phone })}</div>
            </div>
            <div className="item">
              <Button
                variant="contained"
                className={methodSelected === 'EMAIL' ? 'active' : ''}
                onClick={handleChange('EMAIL')}
                disabled={!customer?.censored_email}>
                <IconMethodEmail />
              </Button>
              {!!customer?.censored_email && <div className="item__content">{t('login.text_email', { value: customer.censored_email })}</div>}
            </div>
          </div>
          <Button variant="contained" className="button button-gradient" onClick={handleSubmit} fullWidth>
            {t('login.button_get_otp')}
          </Button>
        </div>
      )}
      {STATUS_LOGIN.VERIFY_OTP === methodPage && <Fragment>{memoizedModal}</Fragment>}
      {STATUS_LOGIN.SELECT_REPRESENTATIVE === methodPage && (
        <div className="login__container">
          <Typography variant="h2" component="h2" className="title">
            {t('login.text_title_select_representative')}
          </Typography>
          <div
            className="detail"
            dangerouslySetInnerHTML={{
              __html: t('login.text_i_am'),
            }}
          />
          <div className="list">
            <div className="item">
              <Button
                variant="contained"
                className={clsx(representativeSelected === REPRESENTATIVE_TYPE.LEGAL_ENTITY && 'active')}
                onClick={handleChangeRepresentType(REPRESENTATIVE_TYPE.LEGAL_ENTITY)}>
                <IconOfficeGroup />
              </Button>
              <div className="item__content des-option">{t('login.text_des_legal_entity')}</div>
            </div>
            <div className="item">
              <Button
                variant="contained"
                className={clsx(representativeSelected === REPRESENTATIVE_TYPE.INDIVIDUAL && 'active')}
                onClick={handleChangeRepresentType(REPRESENTATIVE_TYPE.INDIVIDUAL)}>
                <IconIndependentUser />
              </Button>
              <div className="item__content des-option">{t('login.text_des_independent_user')}</div>
            </div>
          </div>
          <Button variant="contained" className="button button-gradient" onClick={handleCompletedLogin} fullWidth>
            {t('login.text_button_select_representative')}
          </Button>
        </div>
      )}
    </div>
  );
}

export default LoginPage;
