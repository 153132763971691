import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { REPRESENTATIVE_TYPE } from '../../common/constant';
import { clearCacheForImage } from '../../utils';
import ContentChild from '../ContentChild';

export const useGetInfoBusiness = ({ data }) => {
  const {
    business_card_pic,
    business_card_company,
    business_card_company_addr,
    business_card_phone,
    business_card_mobile,
    business_card_fax,
    business_card_email,
    business_card_web,
  } = data;

  const { t } = useTranslation();
  return [
    {
      label: t('verification.text_company_full_name'),
      value: business_card_pic,
    },
    {
      label: t('verification.text_your_company'),
      value: business_card_company,
    },
    {
      label: t('verification.text_company_address'),
      value: business_card_company_addr,
    },
    {
      label: t('verification.text_telephone_number'),
      value: business_card_phone,
    },
    {
      label: t('verification.text_fax_label'),
      value: business_card_mobile,
    },
    {
      label: t('verification.text_mobile_number_label'),
      value: business_card_fax,
    },
    {
      label: t('verification.text_mail_label'),
      value: business_card_email,
    },
    {
      label: t('verification.text_website_label'),
      value: business_card_web,
      isLink: true,
    },
  ];
};

export const useGetInfoLegal = ({ data }) => {
  const { t } = useTranslation();
  const { legal_entity_company, legal_entity_representative_name, legal_entity_company_business, legal_entity_license } = data;
  return [
    {
      label: t('verification.text_your_company'),
      value: legal_entity_company,
    },
    {
      label: t('verification.text_representative_name'),

      value: legal_entity_representative_name,
    },
    {
      label: t('verification.text_company_business'),
      value: legal_entity_company_business,
    },
    {
      label: t('verification.text_license'),
      value: legal_entity_license,
    },
  ];
};

export const LengalBusinessInfo = ({ classes, data, toggleZoomPhotoModal }) => {
  const { t } = useTranslation();
  const typeRepresentative = useSelector(state => state.auth.typeRepresentative);
  const visible = REPRESENTATIVE_TYPE.LEGAL_ENTITY === typeRepresentative;
  const {
    legal_entity_photo1_file,
    legal_entity_photo1_url,
    legal_entity_photo2_file,
    legal_entity_photo2_url,
    business_card_photo1_file,
    business_card_photo1_url,
    business_card_photo2_file,
    business_card_photo2_url,
  } = data;

  const businessDocument = useGetInfoBusiness({ data });

  const legalDocument = useGetInfoLegal({ data });
  return (
    visible && (
      <Grid container item spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <div className="title-form-underline in-verify-form">{t('verification.text_legal_entity_documents')}</div>
        </Grid>
        <Grid item xs={12} className="verify-box-item">
          <div className="title-item">{t('verification.text_business_card')}</div>{' '}
          <Box component="div" p={2} className={classes.contentPhotos}>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} md={8}>
                {businessDocument?.map((item, index) => (
                  <ContentChild key={index} label={item.label} value={item.value} />
                ))}
              </Grid>
              <Grid item xs={12} md={4}>
                <img
                  className={classes.image}
                  src={clearCacheForImage(business_card_photo1_file, business_card_photo1_url)}
                  alt="document"
                  crossOrigin="anonymous"
                  style={{ marginBottom: '15px', display: 'block' }}
                  onClick={() => toggleZoomPhotoModal(business_card_photo1_url)}
                />
                <img
                  className={classes.image}
                  src={clearCacheForImage(business_card_photo2_file, business_card_photo2_url)}
                  alt="document"
                  crossOrigin="anonymous"
                  onClick={() => toggleZoomPhotoModal(business_card_photo2_url)}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} className="verify-box-item">
          <div className="title-item">{t('verification.text_legal_entity_proof')}</div>
          <Box component="div" p={2} className={classes.contentPhotos}>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} md={8}>
                {legalDocument?.map((item, index) => (
                  <ContentChild key={index} label={item.label} value={item.value} />
                ))}
              </Grid>
              <Grid item xs={12} md={4}>
                <img
                  className={classes.image}
                  src={clearCacheForImage(legal_entity_photo1_file, legal_entity_photo1_url)}
                  alt="document"
                  crossOrigin="anonymous"
                  style={{ marginBottom: '15px', display: 'block' }}
                  onClick={() => toggleZoomPhotoModal(legal_entity_photo1_url)}
                />
                <img
                  className={classes.image}
                  src={clearCacheForImage(legal_entity_photo2_file, legal_entity_photo2_url)}
                  alt="document"
                  crossOrigin="anonymous"
                  onClick={() => toggleZoomPhotoModal(legal_entity_photo2_url)}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    )
  );
};
