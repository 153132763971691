export const ActionTypes = {
  GET_KYC_HISTORY_START: "GET_KYC_HISTORY_START",
  GET_KYC_HISTORY_SUCCESS: "GET_KYC_HISTORY_SUCCESS",
  GET_KYC_HISTORY_ERROR: "GET_KYC_HISTORY_ERROR",
};

export const actions = {
  getKYCHistoryStart: (customerId, kycId) => ({ type: ActionTypes.GET_KYC_HISTORY_START, customerId, kycId }),
  getKYCHistorySuccess: (data) => ({ type: ActionTypes.GET_KYC_HISTORY_SUCCESS, payload: data }),
  getKYCHistoryError: (error) => ({ type: ActionTypes.GET_KYC_HISTORY_ERROR, payload: error }),
};
