import HomePage from './pages/home';
import KYCVerificationPage from './pages/verification';
import MyKYCPage from './pages/myKYC';
import LoginPage from './pages/login';
import kycHistoryDetail from './pages/kycHistoryDetail';

export const publicRoutes = [
  {
    name: 'Home',
    path: '/',
    component: HomePage,
    exact: true,
  },
  {
    name: 'Login',
    path: '/login',
    component: LoginPage,
    exact: true,
  },
];

export const privateRoutes = [
  {
    name: 'KYC Verification',
    path: '/verification',
    component: KYCVerificationPage,
    exact: true,
  },
  {
    name: 'KYC Information',
    path: '/information',
    component: MyKYCPage,
    exact: true,
  },
  {
    name: 'KYC History Detail',
    path: '/kyc-history-detail/:customerId/:kycId',
    component: kycHistoryDetail,
    exact: true,
  },
];

export const ROUTE_HIDE_LAYOUT = ['/kyc-history-detail'];
