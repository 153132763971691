import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { ErrorOutline as ErrorOutlineIcon, SaveAlt as SaveAltIcon, Assignment as AssignmentIcon, Edit as EditIcon } from '@material-ui/icons';
import { AppBar, Box, Button, Divider, Tab, Tabs, Typography } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { isNull } from 'lodash-es';
import Pdf from 'react-to-pdf';

import { actions, ActionTypes } from '../verification/actions';
import { Information } from '../../components';
import { isEmpty, isStatusApproved, isStatusRejected, isStatusRequesting, isStatusVerifying } from '../../utils';
import ContentTopComponent from '../../components/ContentTop';
import IconApproved from '../../resources/images/iconApproved';
import { SimpleModal } from '../../components/Modal';
import { Input } from '../../components';
import { MAX_LENGTH_TEXTAREA } from '../../common/constant';
import KYCHistory from '../kycHistory';
import { AuthenticatedLink } from '../../components/AuthenticatedLink';
import { exportKYC } from '../../services/KYCRequest';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  content: {
    backgroundColor: theme.overrides.colorAlabaster,
    padding: theme.spacing(3),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    margin: theme.spacing(3, 0),

    '& > svg': {
      marginRight: theme.spacing(1),
    },
  },
  messageWaiting: {
    backgroundColor: theme.overrides.colorOrangePeel,
    color: theme.overrides.colorWhite,
  },
  messageApproved: {
    backgroundColor: theme.overrides.colorApple,
    color: theme.overrides.colorWhite,
  },
  appBar: {
    backgroundColor: 'transparent',
    color: 'rgba(0, 0, 0, 0.38)',
    boxShadow: 'none',

    '& .MuiButtonBase-root': {
      fontFamily: 'RobotoMedium',
    },

    '& .Mui-selected': {
      color: theme.overrides.colorActive,
    },

    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tab: {
    minWidth: 'unset',
    paddingLeft: 0,
  },
  tabRight: {
    marginLeft: theme.spacing(4),
  },
  buttonExport: {
    border: `1px solid ${theme.overrides.colorActive}`,
    color: theme.overrides.colorActive,
    backgroundColor: 'transparent',
    marginRight: theme.spacing(2),

    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 0.8,
    },
  },
  buttonRequest: {
    border: `1px solid ${theme.overrides.colorActive}`,
    color: theme.overrides.colorWhite,
    background: ' linear-gradient(129.66deg, #BB9043 0%, #DDA74F 64.36%, #BB9043 132.87%)',

    '&:hover': {
      background: ' linear-gradient(129.66deg, #BB9043 0%, #DDA74F 64.36%, #BB9043 132.87%)',
      opacity: 0.8,
    },

    '&.Mui-disabled': {
      color: theme.overrides.colorWhite,
    },
  },
  tabContainer: {
    paddingTop: theme.spacing(3),
  },
  modalRequest: {
    width: '100%',
    maxWidth: 350,
    textAlign: 'center',
    padding: theme.spacing(5),
  },
  formRequest: {
    '&>*:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
  buttonContainer: {
    [theme.breakpoints.down('sm')]: {
      '& > .btn-export': {
        display: 'inline-block',
        minWidth: '50%',

        '& button': {
          minWidth: '100%',
        },
      },

      '& button': {
        minWidth: '60%',
        marginTop: theme.spacing(1),
      },

      '& .button-apple': {
        minWidth: '60%',
        marginTop: theme.spacing(1),
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function KYCInformationPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const kycInformation = useSelector(state => state.kyc.information);
  const requestStatus = useSelector(state => state.kyc.type);
  const pdfRef = useRef();

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  let history = useHistory();

  const goToVerificationPage = () => {
    history.push('/verification');
  };

  useEffect(() => {
    dispatch(actions.getKYCInformationStart());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(kycInformation)) {
      const { status } = kycInformation;
      if (isStatusRejected(status) || isStatusRequesting(status)) {
        goToVerificationPage();
      }
    }
  }, [dispatch, kycInformation]);

  useEffect(() => {
    if (requestStatus === ActionTypes.REQUEST_EDIT_KYC_SUCCESS) {
      enqueueSnackbar(t('message.MSG_25'), { variant: 'success' });
      toggleRequestModal();
      dispatch(actions.resetTypeOfAction());
      dispatch(actions.getKYCInformationStart());
    }
  }, [dispatch, requestStatus]);

  const [value, setValue] = useState(0);
  const [isVisibleRequestModal, setIsVisibleRequestModal] = useState(false);

  const initialValue = {
    reason: '',
  };

  const validationSchema = Yup.object().shape({
    reason: Yup.string()
      .trim()
      .required(t('message.MSG_4', { fieldName: t('my_kyc.text_request_reason') })),
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleRequestModal = useCallback(() => {
    setIsVisibleRequestModal(!isVisibleRequestModal);
  }, [isVisibleRequestModal]);

  const handleSubmit = data => {
    dispatch(actions.requestEditKYCStart(data));
  };

  const { customer_code, status, edit_kyc_rq_status, full_name } = kycInformation;

  const memoizedButtonExport = useMemo(() => {
    return (
      <AuthenticatedLink url={exportKYC()} filename={`KYC.pdf`}>
        <Button startIcon={<SaveAltIcon />} className={classes.buttonExport}>
          {t('my_kyc.button_export_data')}
        </Button>
      </AuthenticatedLink>
    );
  }, [t]);

  return (
    <div className="kyc-information page">
      <div className="container">
        <div className={classes.root}>
          <ContentTopComponent customerCode={customer_code} status={status} />
          {isStatusVerifying(status) && (
            <div className={`${classes.message} ${classes.messageWaiting}`}>
              <ErrorOutlineIcon />
              {t('my_kyc.text_waiting')}
            </div>
          )}
          {isStatusApproved(status) && isStatusRequesting(edit_kyc_rq_status) && (
            <div className={`${classes.message} ${classes.messageWaiting}`}>
              <ErrorOutlineIcon />
              {t('my_kyc.text_requesting', { customerID: customer_code })}
            </div>
          )}
          {isStatusApproved(status) && isStatusApproved(edit_kyc_rq_status) && (
            <div className={`${classes.message} ${classes.messageApproved}`}>
              <IconApproved />
              {t('my_kyc.text_approved', { customerID: customer_code })}
            </div>
          )}
          <div className={classes.content}>
            <AppBar position="static" className={classes.appBar}>
              <Tabs value={value} onChange={handleChange}>
                <Tab className={classes.tab} label={t('my_kyc.tab_my_kyc')} />
                <Tab className={`${classes.tab} ${classes.tabRight}`} label={t('my_kyc.tab_kyc_edit_history')} />
              </Tabs>
            </AppBar>
            <Divider />
            <TabPanel className={classes.tabContainer} value={value} index={0}>
              <Box className={classes.buttonContainer}>
                {memoizedButtonExport}
                {isStatusApproved(status) &&
                  (isNull(edit_kyc_rq_status) || isStatusRequesting(edit_kyc_rq_status) || isStatusRejected(edit_kyc_rq_status)) && (
                    <Button
                      startIcon={<AssignmentIcon />}
                      className={classes.buttonRequest}
                      onClick={toggleRequestModal}
                      disabled={isStatusRequesting(edit_kyc_rq_status)}>
                      {t('my_kyc.button_request_to_edit_kyc')}
                    </Button>
                  )}
                {isStatusApproved(status) && isStatusApproved(edit_kyc_rq_status) && (
                  <Button startIcon={<EditIcon />} className="button button-apple" href="/verification">
                    {t('my_kyc.button_edit_kyc_information')}
                  </Button>
                )}
              </Box>
              <Box ref={pdfRef} className="kyc-data">
                <Information data={kycInformation} />
              </Box>
            </TabPanel>
            <TabPanel className={classes.tabContainer} value={value} index={1}>
              <KYCHistory />
            </TabPanel>
          </div>
        </div>
      </div>
      <SimpleModal isOpeningModal={isVisibleRequestModal} toggleModal={toggleRequestModal} classNameBox={classes.modalRequest}>
        <Formik initialValues={initialValue} validationSchema={validationSchema} onSubmit={handleSubmit}>
          <Form className={classes.formRequest}>
            <Typography variant="h6">{t('my_kyc.text_request_to_edit_kyc')}</Typography>
            <Typography variant="body2">{t('my_kyc.text_description_request_to_edit_kyc')}</Typography>
            <Field
              name="reason"
              multiline
              rows={6}
              placeholder={t('my_kyc.placeholder_textarea_reasons')}
              fullWidth
              maxLength={MAX_LENGTH_TEXTAREA}
              component={Input}
            />
            <Button type="submit" className="button button-gradient" fullWidth>
              {t('component.button_submit')}
            </Button>
            <Button className="button button-transparent" fullWidth onClick={toggleRequestModal}>
              {t('component.button_cancel')}
            </Button>
          </Form>
        </Formik>
      </SimpleModal>
    </div>
  );
}

export default KYCInformationPage;
