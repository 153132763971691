import React, { useState, useRef, useCallback, useEffect } from 'react';
import Webcam from 'react-webcam';
import { Box, Button, IconButton, makeStyles } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/CameraAltOutlined';
import { useTranslation } from 'react-i18next';

import { PERMISSION_DENIED } from '../../../../common/constant';
import PortalClickAway from './warningPortal';

const videoConstraints = {
  width: 540,
  facingMode: 'environment',
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
  },
  content: {},
  action: {
    height: 70,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonShot: {
    backgroundColor: theme.overrides.colorWhite,

    '&:hover': {
      backgroundColor: theme.overrides.colorWhite,
      opacity: '0.8',
    },

    '&.Mui-disabled': {
      backgroundColor: theme.overrides.colorWhite,
    },
  },
}));

function dataURLtoFile(dataUrl, filename) {
  var arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

function WebcamComponent({ handleTakePhotoUpload, className = '', fileName }) {
  const webcamRef = useRef(null);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [photoFile, setPhotoFile] = useState(null);
  const [isVisibleWarning, setIsVisibleWarning] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const capturePhoto = useCallback(async () => {
    try {
      const imageSrc = webcamRef.current.getScreenshot();
      const image = dataURLtoFile(imageSrc, fileName);
      setPhotoFile(image);
      setPhotoUrl(imageSrc);
    } catch (e) {
      alert(t('component.warning_camera'));
    }
  }, [webcamRef]);

  const handleTakePhoto = () => {
    handleTakePhotoUpload(photoFile);
  };

  const handleReTakePhoto = () => {
    setPhotoFile(null);
    setPhotoUrl(null);
  };

  const onUserMedia = (e) => {
    console.log(e);
  };

  const toggleWarning = () => {
    setIsVisibleWarning((prevState) => !prevState);
  };

  useEffect(() => {
    navigator.permissions
      .query({ name: 'camera' })
      .then((permissionObj) => {
        if (permissionObj.state === PERMISSION_DENIED) {
          alert(t('component.warning_camera'));
          toggleWarning();
        }
      })
      .catch((error) => {
        console.log('Got error :', error);
      });
  }, [navigator]);

  return (
    <Box className={`${classes.root} ${className}`}>
      {!photoUrl && (
        <Webcam
          ref={webcamRef}
          audio={false}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          onUserMedia={onUserMedia}
          className={classes.content}
        />
      )}
      {photoUrl && (
        <div>
          <img src={photoUrl} alt="webcam" />
        </div>
      )}
      <div className={classes.action}>
        <Button disabled={!photoUrl} variant="outlined" className="button button-gradient" onClick={handleTakePhoto}>
          {t('verification.button_use_this_photo')}
        </Button>
        <IconButton disabled={photoUrl} className={classes.buttonShot} onClick={capturePhoto}>
          <PhotoCamera />
        </IconButton>
        <Button
          disabled={!photoUrl}
          variant="outlined"
          className="button button-transparent-border"
          onClick={handleReTakePhoto}
        >
          {t('verification.button_use_re_take')}
        </Button>
      </div>{' '}
      <PortalClickAway isVisible={isVisibleWarning} handleClickAway={toggleWarning} />
    </Box>
  );
}

export default WebcamComponent;
