import * as React from 'react';

function IconOfficeGroup(props) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M76.0938 69.5016H75.102V50.1499C75.102 49.5027 74.5775 48.978 73.9302 48.978C73.2828 48.978 72.7583 49.5027 72.7583 50.1499V69.5017H47.8983V37.6455H71.8006C72.3288 37.6455 72.7584 38.0752 72.7584 38.6031V44.6289C72.7584 45.2761 73.283 45.8008 73.9303 45.8008C74.5777 45.8008 75.1022 45.2761 75.1022 44.6289V38.6031C75.1022 36.7827 73.6211 35.3017 71.8006 35.3017H47.8983V14.0058C47.8983 12.3006 46.5716 10.8605 44.8745 10.7169V8.36611C44.8745 6.65424 43.5377 5.20846 41.8309 5.07471L11.4809 2.69596C9.56453 2.54455 7.92156 4.06439 7.92156 5.98736V7.86408C6.18359 8.01252 4.89797 9.46627 4.89797 11.1531V69.5014H3.90625C1.75234 69.5014 0 71.2538 0 73.4077C0 75.5616 1.75234 77.3139 3.90625 77.3139H76.0938C78.2477 77.3139 80 75.5616 80 73.4077C80 71.2538 78.2477 69.5016 76.0938 69.5016ZM10.2653 5.98752C10.2653 5.42627 10.743 4.98877 11.298 5.03267L41.648 7.41127C42.143 7.45017 42.5308 7.86955 42.5308 8.36611V10.5322L10.2652 8.00345V5.98752H10.2653ZM7.24172 11.1533C7.24172 10.5875 7.72563 10.1535 8.27422 10.1985L44.6714 13.051C45.1666 13.0899 45.5544 13.5092 45.5544 14.0058V69.5016H42.2798V52.7234C42.2798 51.6569 41.4122 50.7892 40.3456 50.7892H29.4581C28.3916 50.7892 27.5239 51.6569 27.5239 52.7234V60.3255C27.5239 60.9727 28.0486 61.4974 28.6958 61.4974C29.343 61.4974 29.8677 60.9727 29.8677 60.3255V53.133H39.9361V69.5016H29.8677V65.8041C29.8677 65.1569 29.343 64.6322 28.6958 64.6322C28.0486 64.6322 27.5239 65.1569 27.5239 65.8041V69.5016H7.24172V11.1533ZM76.0938 74.9703H3.90625C3.04469 74.9703 2.34375 74.2694 2.34375 73.4078C2.34375 72.5463 3.04469 71.8453 3.90625 71.8453H76.0938C76.9553 71.8453 77.6562 72.5463 77.6562 73.4078C77.6562 74.2694 76.9553 74.9703 76.0938 74.9703Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M11.8603 25.4598H16.6038C17.7189 25.4598 18.6261 24.5526 18.6261 23.4376V19.7881C18.6261 18.6731 17.7189 17.7659 16.6038 17.7659H11.8603C10.7453 17.7659 9.83813 18.6731 9.83813 19.7881V23.4376C9.83813 24.5526 10.7453 25.4598 11.8603 25.4598ZM12.1819 20.1095H16.2825V23.116H12.1819V20.1095Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M23.6872 25.4598H28.4307C29.5457 25.4598 30.4529 24.5526 30.4529 23.4376V19.7881C30.4529 18.6731 29.5457 17.7659 28.4307 17.7659H23.6872C22.5722 17.7659 21.665 18.6731 21.665 19.7881V23.4376C21.6649 24.5526 22.5721 25.4598 23.6872 25.4598ZM24.0086 20.1095H28.1091V23.116H24.0086V20.1095Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M35.5144 25.4598H40.2578C41.373 25.4598 42.28 24.5526 42.28 23.4376V19.7881C42.28 18.6731 41.3728 17.7659 40.2578 17.7659H35.5144C34.3994 17.7659 33.4922 18.6731 33.4922 19.7881V23.4376C33.492 24.5526 34.3992 25.4598 35.5144 25.4598ZM35.8358 20.1095H39.9362V23.116H35.8358V20.1095Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M11.8603 35.9968H16.6038C17.7189 35.9968 18.6261 35.0896 18.6261 33.9746V30.3249C18.6261 29.2099 17.7189 28.3027 16.6038 28.3027H11.8603C10.7453 28.3027 9.83813 29.2099 9.83813 30.3249V33.9745C9.83813 35.0896 10.7453 35.9968 11.8603 35.9968ZM12.1819 30.6465H16.2825V33.653H12.1819V30.6465Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M23.6872 35.9968H28.4307C29.5457 35.9968 30.4529 35.0896 30.4529 33.9746V30.3249C30.4529 29.2099 29.5457 28.3027 28.4307 28.3027H23.6872C22.5722 28.3027 21.665 29.2099 21.665 30.3249V33.9745C21.6649 35.0896 22.5721 35.9968 23.6872 35.9968ZM24.0086 30.6465H28.1091V33.653H24.0086V30.6465Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M35.5144 35.9968H40.2578C41.373 35.9968 42.28 35.0896 42.28 33.9746V30.3249C42.28 29.2099 41.3728 28.3027 40.2578 28.3027H35.5144C34.3994 28.3027 33.4922 29.2099 33.4922 30.3249V33.9745C33.492 35.0896 34.3992 35.9968 35.5144 35.9968ZM35.8358 30.6465H39.9362V33.653H35.8358V30.6465Z"
        fill="url(#paint6_linear)"
      />
      <path
        d="M11.8603 46.5335H16.6038C17.7189 46.5335 18.6261 45.6263 18.6261 44.5113V40.8618C18.6261 39.7468 17.7189 38.8396 16.6038 38.8396H11.8603C10.7453 38.8396 9.83813 39.7468 9.83813 40.8618V44.5113C9.83813 45.6263 10.7453 46.5335 11.8603 46.5335ZM12.1819 41.1832H16.2825V44.1898H12.1819V41.1832Z"
        fill="url(#paint7_linear)"
      />
      <path
        d="M22.5863 50.7893H12.0881C10.8475 50.7893 9.83813 51.7987 9.83813 53.0393V56.2334C9.83813 57.474 10.8475 58.4834 12.0881 58.4834H22.5863C23.8269 58.4834 24.8363 57.474 24.8363 56.2334V53.0393C24.8363 51.7987 23.8269 50.7893 22.5863 50.7893ZM22.4925 56.1396H12.1819V53.1331H22.4925V56.1396Z"
        fill="url(#paint8_linear)"
      />
      <path
        d="M23.6872 46.5335H28.4307C29.5457 46.5335 30.4529 45.6263 30.4529 44.5113V40.8618C30.4529 39.7468 29.5457 38.8396 28.4307 38.8396H23.6872C22.5722 38.8396 21.665 39.7468 21.665 40.8618V44.5113C21.6649 45.6263 22.5721 46.5335 23.6872 46.5335ZM24.0086 41.1832H28.1091V44.1898H24.0086V41.1832Z"
        fill="url(#paint9_linear)"
      />
      <path
        d="M35.5144 46.5335H40.2578C41.373 46.5335 42.28 45.6263 42.28 44.5113V40.8618C42.28 39.7468 41.3728 38.8396 40.2578 38.8396H35.5144C34.3994 38.8396 33.4922 39.7468 33.4922 40.8618V44.5113C33.492 45.6263 34.3992 46.5335 35.5144 46.5335ZM35.8358 41.1832H39.9362V44.1898H35.8358V41.1832Z"
        fill="url(#paint10_linear)"
      />
      <path
        d="M59.4646 45.5152C59.4646 44.3266 58.4976 43.3594 57.3088 43.3594H52.5232C51.3346 43.3594 50.3674 44.3264 50.3674 45.5152V49.3109C50.3674 50.4995 51.3345 51.4667 52.5232 51.4667H57.3088C58.4974 51.4667 59.4646 50.4997 59.4646 49.3109V45.5152ZM57.1209 49.123H52.7112V45.7031H57.1209V49.123Z"
        fill="url(#paint11_linear)"
      />
      <path
        d="M57.3088 54.1743H52.5232C51.3346 54.1743 50.3674 55.1413 50.3674 56.3301V60.1259C50.3674 61.3145 51.3345 62.2817 52.5232 62.2817H57.3088C58.4974 62.2817 59.4646 61.3146 59.4646 60.1259V56.3301C59.4646 55.1413 58.4976 54.1743 57.3088 54.1743ZM57.1209 59.9379H52.7112V56.5181H57.1209V59.9379Z"
        fill="url(#paint12_linear)"
      />
      <path
        d="M70.3646 45.5152C70.3646 44.3266 69.3976 43.3594 68.2088 43.3594H63.4234C62.2348 43.3594 61.2676 44.3264 61.2676 45.5152V49.3109C61.2676 50.4995 62.2346 51.4667 63.4234 51.4667H68.2088C69.3974 51.4667 70.3646 50.4997 70.3646 49.3109V45.5152ZM68.0209 49.123H63.6113V45.7031H68.0209V49.123Z"
        fill="url(#paint13_linear)"
      />
      <path
        d="M68.2088 54.1743H63.4234C62.2348 54.1743 61.2676 55.1413 61.2676 56.3301V60.1259C61.2676 61.3145 62.2346 62.2817 63.4234 62.2817H68.2088C69.3974 62.2817 70.3646 61.3146 70.3646 60.1259V56.3301C70.3646 55.1413 69.3976 54.1743 68.2088 54.1743ZM68.0209 59.9379H63.6113V56.5181H68.0209V59.9379Z"
        fill="url(#paint14_linear)"
      />
      <defs>
        <linearGradient id="paint0_linear" x1="0" y1="2.68555" x2="108.625" y2="99.2193" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BB9043" />
          <stop offset="0.484375" stopColor="#DDA74F" />
          <stop offset="1" stopColor="#BB9043" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="9.83813" y1="17.7659" x2="21.0983" y2="28.4281" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BB9043" />
          <stop offset="0.484375" stopColor="#DDA74F" />
          <stop offset="1" stopColor="#BB9043" />
        </linearGradient>
        <linearGradient id="paint2_linear" x1="21.665" y1="17.7659" x2="32.9252" y2="28.4279" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BB9043" />
          <stop offset="0.484375" stopColor="#DDA74F" />
          <stop offset="1" stopColor="#BB9043" />
        </linearGradient>
        <linearGradient id="paint3_linear" x1="33.4922" y1="17.7659" x2="44.7523" y2="28.4279" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BB9043" />
          <stop offset="0.484375" stopColor="#DDA74F" />
          <stop offset="1" stopColor="#BB9043" />
        </linearGradient>
        <linearGradient id="paint4_linear" x1="9.83813" y1="28.3027" x2="21.0985" y2="38.965" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BB9043" />
          <stop offset="0.484375" stopColor="#DDA74F" />
          <stop offset="1" stopColor="#BB9043" />
        </linearGradient>
        <linearGradient id="paint5_linear" x1="21.665" y1="28.3027" x2="32.9254" y2="38.9648" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BB9043" />
          <stop offset="0.484375" stopColor="#DDA74F" />
          <stop offset="1" stopColor="#BB9043" />
        </linearGradient>
        <linearGradient id="paint6_linear" x1="33.4922" y1="28.3027" x2="44.7526" y2="38.9648" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BB9043" />
          <stop offset="0.484375" stopColor="#DDA74F" />
          <stop offset="1" stopColor="#BB9043" />
        </linearGradient>
        <linearGradient id="paint7_linear" x1="9.83813" y1="38.8396" x2="21.0983" y2="49.5018" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BB9043" />
          <stop offset="0.484375" stopColor="#DDA74F" />
          <stop offset="1" stopColor="#BB9043" />
        </linearGradient>
        <linearGradient id="paint8_linear" x1="9.83813" y1="50.7893" x2="19.9304" y2="67.0984" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BB9043" />
          <stop offset="0.484375" stopColor="#DDA74F" />
          <stop offset="1" stopColor="#BB9043" />
        </linearGradient>
        <linearGradient id="paint9_linear" x1="21.665" y1="38.8396" x2="32.9252" y2="49.5016" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BB9043" />
          <stop offset="0.484375" stopColor="#DDA74F" />
          <stop offset="1" stopColor="#BB9043" />
        </linearGradient>
        <linearGradient id="paint10_linear" x1="33.4922" y1="38.8396" x2="44.7523" y2="49.5016" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BB9043" />
          <stop offset="0.484375" stopColor="#DDA74F" />
          <stop offset="1" stopColor="#BB9043" />
        </linearGradient>
        <linearGradient id="paint11_linear" x1="50.3674" y1="43.3594" x2="62.2193" y2="54.3844" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BB9043" />
          <stop offset="0.484375" stopColor="#DDA74F" />
          <stop offset="1" stopColor="#BB9043" />
        </linearGradient>
        <linearGradient id="paint12_linear" x1="50.3674" y1="54.1743" x2="62.2193" y2="65.1993" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BB9043" />
          <stop offset="0.484375" stopColor="#DDA74F" />
          <stop offset="1" stopColor="#BB9043" />
        </linearGradient>
        <linearGradient id="paint13_linear" x1="61.2676" y1="43.3594" x2="73.1194" y2="54.3842" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BB9043" />
          <stop offset="0.484375" stopColor="#DDA74F" />
          <stop offset="1" stopColor="#BB9043" />
        </linearGradient>
        <linearGradient id="paint14_linear" x1="61.2676" y1="54.1743" x2="73.1195" y2="65.1991" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BB9043" />
          <stop offset="0.484375" stopColor="#DDA74F" />
          <stop offset="1" stopColor="#BB9043" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default IconOfficeGroup;
