import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { themeStatusConfig } from '../../config/theme';

export const useStyles = makeStyles(theme => ({
  boxContainer: {
    maxWidth: '1140px',
    margin: 'auto',
    background: 'white',
    borderRadius: '5px',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(4),
  },
  kycTitleContent: {
    marginBottom: theme.spacing(3),
  },
  gridInfoContainer: {
    marginBottom: theme.spacing(5),
  },
  labelInfo: {
    fontWeight: '500',
    fontFamily: `"Roboto", sans-serif`,
  },
  labelValue: {
    wordBreak: 'break-word',
  },
  ...themeStatusConfig(theme, 117),
}));

//render kyc status DOM
export const renderStatusKYC = (classes, status, t) => {
  let value = status === 'REQUESTING' ? 'PENDING' : status;
  return (
    value && (
      <span
        className={clsx(classes.status, {
          [classes.statusVerify]: status === 'VERIFYING',
          [classes.statusApproved]: status === 'APPROVED',
          [classes.statusRejected]: status === 'REJECTED',
          [classes.statusRequesting]: status === 'REQUESTING',
        })}>
        {t(`verification.text_status_${value?.toLowerCase()}`)}
      </span>
    )
  );
};
