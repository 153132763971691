import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FooterComponent from '../../../components/Footer';
import { getToken } from '../../../services/api';
import HeaderCommon from './components/header';

function PublicLayout({ component: Component }) {
  let history = useHistory();
  const isAuthenticated = useSelector(state => state.auth.currentUser?.access_token);

  useEffect(() => {
    if (isAuthenticated) {
      getToken(isAuthenticated);
      history.push('/information');
    }
  }, [isAuthenticated, history]);

  return (
    <div className="layout">
      <HeaderCommon />
      <main className="main">
        <Component />
      </main>
      <FooterComponent />
    </div>
  );
}

export default PublicLayout;
