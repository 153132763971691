import { Box, Divider, Typography } from '@material-ui/core';
import React, { useState, useEffect, Fragment, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import ErrorIcon from '@material-ui/icons/Error';
import { useSnackbar } from 'notistack';

import Step1Form from './Forms/Step1';
import Step2Form from './Forms/Step2';
import Step3Form from './Forms/Step3';
import Step4From from './Forms/Step4';
import initialValues, { initialValuesStep1, initialValuesStep2 } from './FormModel/initialValues';
import verificationFormModel, { listIgnoreUrl } from './FormModel/formModel';
import initialValidationSchema from './FormModel/validationSchema';
import { isEmpty, isFile, isStatusApproved, isStatusRejected, isStatusVerifying } from '../../utils';
import { getVerificationSteps, REPRESENTATIVE_TYPE } from '../../common/constant';
import { actions, ActionTypes } from './actions';
import ContentTopComponent from '../../components/ContentTop';
import { ConfirmModal } from '../../components';
import { trim } from 'lodash-es';
import i18n from '../../i18n/i18n';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  stepperRoot: {
    padding: theme.spacing(4, 2),
  },
  divider: {
    marginTop: theme.spacing(1),
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  rejectedBox: {
    backgroundColor: 'rgba(239, 83, 80, 0.2)',
    width: '100%',
    maxWidth: 600,
    margin: '30px auto',
    color: theme.overrides.colorBlack,
    display: 'flex',

    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1),
      color: theme.overrides.colorBurntSienna,
    },
  },
  rejectContent: {
    padding: theme.spacing(2, 1),
    whiteSpace: 'pre-line',
  },
  buttonCancel: {
    marginRight: theme.spacing(2),
    border: `1px solid ${theme.overrides.colorActive}`,
    color: theme.overrides.colorActive,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  },
}));

function renderStepContent(stepIndex, t) {
  const { formField } = verificationFormModel();

  switch (stepIndex) {
    case 0:
      return <Step1Form formField={formField} />;
    case 1:
      return <Step2Form formField={formField} />;
    case 2:
      return <Step3Form formField={formField} />;
    case 3:
      return <Step4From formField={formField} />;
    default:
      return 'Unknown stepIndex';
  }
}

function KYCVerificationPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  let history = useHistory();
  const steps = getVerificationSteps(t);
  const formRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const [activeStep, setActiveStep] = useState(0);
  const [initValues, setInitValues] = useState({ ...initialValuesStep1(), ...initialValuesStep2() });
  const [isVisibleConfirmModal, setIsVisibleConfirmModal] = useState(false);
  const typeRepresentative = useSelector(state => state.auth.typeRepresentative);

  const isLastStep = activeStep === steps.length - 1;
  const [validationSchema, setValidateSchema] = useState(initialValidationSchema(t, REPRESENTATIVE_TYPE.LEGAL_ENTITY === typeRepresentative));

  const { formField } = verificationFormModel();

  const kycInformation = useSelector(state => state.kyc.information);
  const requestStatus = useSelector(state => state.kyc.type);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const goToMyKYC = useCallback(() => {
    history.push('/information');
  }, [history]);

  useEffect(() => {
    setValidateSchema(initialValidationSchema(t, REPRESENTATIVE_TYPE.LEGAL_ENTITY === typeRepresentative));
  }, [i18n.language, t]);

  const toggleConfirmModal = useCallback(() => {
    setIsVisibleConfirmModal(prevState => !prevState);
  }, [setIsVisibleConfirmModal]);

  const handleSubmit = (data, formikActions) => {
    const { email } = formField;
    if (isLastStep) {
      var formData = new FormData();
      for (let key in data) {
        if (data[key] && !listIgnoreUrl.includes(key)) {
          if (isFile(data[key])) {
            formData.append(key, data[key]);
          } else {
            formData.append(key, trim(data[key]));
          }
        }
      }
      dispatch(actions.updateKYCInformationStart(formData));
    } else {
      if (activeStep === 0) {
        dispatch(actions.verifyEmailStart(trim(data[email.name])));
      } else {
        handleNext();
      }
      formikActions.setTouched({});
    }
  };

  const onUnload = useCallback(e => {
    e.preventDefault();
    e.returnValue = '';
  }, []);

  useEffect(() => {
    const { values } = formRef.current;
    const { phoneNumber, isFirstKYC } = formField;

    if (requestStatus === ActionTypes.VERIFY_EMAIL_SUCCESS) {
      if (!values[isFirstKYC.name]) {
        dispatch(actions.verifyPhoneNumberStart(values[phoneNumber.name]));
      } else {
        handleNext();
      }
    } else if (requestStatus === ActionTypes.VERIFY_PHONE_NUMBER_SUCCESS) {
      handleNext();
    } else if (requestStatus === ActionTypes.VERIFY_EMAIL_ERROR) {
      enqueueSnackbar(t('message.MSG_40'), { variant: 'error' });
    } else if (requestStatus === ActionTypes.VERIFY_PHONE_NUMBER_ERROR) {
      enqueueSnackbar(t('message.MSG_27'), { variant: 'error' });
    }
    dispatch(actions.resetTypeOfAction());
  }, [requestStatus]);

  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
      window.addEventListener('beforeunload', onUnload);
    }

    return () => {
      if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
        window.removeEventListener('beforeunload', onUnload);
      }
    };
  }, [onUnload]);

  useEffect(() => {
    dispatch(actions.getKYCInformationStart());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(kycInformation)) {
      const { status, edit_kyc_rq_status } = kycInformation;
      setInitValues(initialValues(kycInformation));
      if (isStatusVerifying(status) || (isStatusApproved(status) && !isStatusApproved(edit_kyc_rq_status))) {
        history.push('/information');
      }
    }
  }, [kycInformation, history]);

  const memoizedConfirmModal = useMemo(
    () => <ConfirmModal isOpeningModal={isVisibleConfirmModal} toggleModal={toggleConfirmModal} onConfirm={goToMyKYC} />,
    [isVisibleConfirmModal, toggleConfirmModal, goToMyKYC]
  );

  return (
    <div className="kyc-verification page">
      <div className="container">
        <ContentTopComponent customerCode={kycInformation.customer_code} status={kycInformation.status} />
        {isStatusRejected(kycInformation.status) && (
          <Box p={3} className={classes.rejectedBox}>
            <ErrorIcon />
            <Box>
              <Typography variant="body2" component="div">
                {t('verification.text_rejected_kyc')}
              </Typography>
              <Typography variant="body2" component="div" className={classes.rejectContent}>
                {kycInformation.reject_reason}
              </Typography>
              <Typography variant="body2" component="div">
                {t('verification.text_request_edit_kyc')}
              </Typography>
            </Box>
          </Box>
        )}
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel className={classes.stepperRoot}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div className="step-container">
            {steps.length && activeStep !== steps.length && (
              <Fragment>
                <div className="step__heading">
                  <div className="step__heading__name">
                    <span className="text text-left">{steps[activeStep]}</span>
                    <span className="text text-right">{t('verification.text_step', { currentStep: activeStep + 1, total: steps.length })}</span>
                  </div>
                  <Divider light className={classes.divider} />
                </div>
                <div className="step__content">
                  <Formik
                    initialValues={initValues}
                    enableReinitialize
                    validationSchema={validationSchema[activeStep]}
                    onSubmit={handleSubmit}
                    innerRef={formRef}>
                    {({ handleSubmit }) => (
                      <form onSubmit={handleSubmit} className="step-form">
                        {renderStepContent(activeStep, t)}

                        <div className="step__button">
                          {activeStep !== 0 && (
                            <Button onClick={handleBack} variant="contained" className={`${classes.backButton} button button-previous`}>
                              {t('verification.button_previous')}
                            </Button>
                          )}
                          {isStatusApproved(kycInformation.status) && isStatusApproved(kycInformation.edit_kyc_rq_status) && (
                            <Button className={classes.buttonCancel} onClick={toggleConfirmModal}>
                              {t('component.button_cancel')}
                            </Button>
                          )}
                          <Button type="submit" variant="contained" className="button button-gradient">
                            {isLastStep
                              ? !isStatusRejected(kycInformation.status)
                                ? t('component.button_submit')
                                : t('component.button_resubmit')
                              : t('verification.button_next_step')}
                          </Button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </Fragment>
            )}
          </div>
          {memoizedConfirmModal}
        </div>
      </div>
    </div>
  );
}

export default KYCVerificationPage;
