import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import {
  getKYCInformationAPI,
  requestEditKYCAPI,
  updateKYCInformationAPI,
  verifyPhoneNumberAPI,
  verifyEmailAPI,
} from '../../services/KYCRequest';
import { RESPONSE_STATUS } from '../../common/constant';
import { actions as appActions } from '../layout/actions';

function* getKYCInformation({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield getKYCInformationAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getKYCInformationSuccess(response.data));
    } else {
      yield put(actions.getKYCInformationError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.getKYCInformationError(e));
    yield put(appActions.hideLoading());
  }
}

function* updateKYCInformation({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield updateKYCInformationAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.updateKYCInformationSuccess(response.data));
    } else {
      yield put(actions.updateKYCInformationError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.updateKYCInformationError(e));
    yield put(appActions.hideLoading());
  }
}

function* requestEditKYC({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield requestEditKYCAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.requestEditKYCSuccess(response.data));
    } else {
      yield put(actions.requestEditKYCError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.requestEditKYCError(e));
    yield put(appActions.hideLoading());
  }
}

function* verifyPhoneNumber({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield verifyPhoneNumberAPI(params);
    if (response.data === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.verifyPhoneNumberSuccess(response.data));
    } else {
      yield put(actions.verifyPhoneNumberError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.verifyPhoneNumberError(e));
    yield put(appActions.hideLoading());
  }
}

function* verifyEmail({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield verifyEmailAPI(params);
    if (response.data === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.verifyEmailSuccess(response.data));
    } else {
      yield put(actions.verifyEmailError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.verifyEmailError(e));
    yield put(appActions.hideLoading());
  }
}

export function* watchKYC() {
  yield takeLatest(ActionTypes.GET_KYC_INFORMATION_START, getKYCInformation);
  yield takeLatest(ActionTypes.UPDATE_KYC_INFORMATION_START, updateKYCInformation);
  yield takeLatest(ActionTypes.REQUEST_EDIT_KYC_START, requestEditKYC);
  yield takeLatest(ActionTypes.VERIFY_PHONE_NUMBER_START, verifyPhoneNumber);
  yield takeLatest(ActionTypes.VERIFY_EMAIL_START, verifyEmail);
}
