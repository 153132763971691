import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import {
  createOTPCodeAPI,
  getAuthInformationAPI,
  resendOTPCodeAPI,
  verifyOTPCodeAPI,
} from '../../services/AuthRequest';
import { RESPONSE_STATUS } from '../../common/constant';
import { actions as appActions } from '../layout/actions';

function* createOTPCode({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield createOTPCodeAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.createOTPCodeSuccess(response.data));
    } else {
      yield put(actions.createOTPCodeError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.createOTPCodeError(e));
    yield put(appActions.hideLoading());
  }
}

function* resendOTPCode({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield resendOTPCodeAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.resendOTPCodeSuccess(response.data));
    } else {
      yield put(actions.resendOTPCodeError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.resendOTPCodeError(e));
    yield put(appActions.hideLoading());
  }
}

function* verifyOTPCode({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield verifyOTPCodeAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.verifyOTPCodeSuccess(response.data));
    } else {
      yield put(actions.verifyOTPCodeError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.verifyOTPCodeError(e));
    yield put(appActions.hideLoading());
  }
}

export function* watchAuthentication() {
  yield takeLatest(ActionTypes.CREATE_OTP_CODE_START, createOTPCode);
  yield takeLatest(ActionTypes.RESEND_OTP_CODE_START, resendOTPCode);
  yield takeLatest(ActionTypes.VERIFY_OTP_CODE_START, verifyOTPCode);
}
