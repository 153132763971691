import React, { useCallback, useState } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ContentChild from '../ContentChild';
import DriverLicenseContent from './IdentityDocument/driverLicense';
import HealthInsuranceCardContent from './IdentityDocument/healthInsuranceCard';
import PassportContent from './IdentityDocument/passport';
import BasicResidentRegisterContent from './IdentityDocument/basicResidentRegister';
import ResidentCardContent from './IdentityDocument/residenceCard';
import SpecialPermanentResidentContent from './IdentityDocument/specialPermanentResident';
import ForeignerRegistrationCardContent from './IdentityDocument/foreignerRegistrationCard';
import { formatCountries, formatDocument } from '../../utils';
import ZoomPhoto from '../ZoomPhoto';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  subtitle: {
    fontSize: 16,
    color: theme.overrides.colorBalticSea,
    padding: theme.spacing(2, 0),
  },
  content: {
    border: '1px dashed rgba(0, 0, 0, 0.12)',
    height: 'calc(100% - 50px)',
  },
  contentPhotos: {
    border: '1px dashed rgba(0, 0, 0, 0.12)',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
  },
}));

function InformationPrintModeComponent({ data = {} }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [zoomPhotoURL, setZoomPhotoURL] = useState('');
  const [isVisibleZoomPhotoModal, setIsVisibleZoomPhotoModal] = useState(false);

  const {
    full_name,
    birthday,
    nationality,
    email,
    phone,
    residential_address,
    idoc_type,
    idoc_photo1_url,
    idoc_photo2_url,
    idoc_video_url,
  } = data;

  const contact = [
    {
      label: t('verification.text_full_name'),
      value: full_name,
    },
    {
      label: t('verification.text_date_of_birth'),
      value: birthday,
    },
    {
      label: t('verification.text_nationality'),
      value: formatCountries(nationality),
    },
    {
      label: t('verification.text_email'),
      value: email,
    },
    {
      label: t('verification.text_phone_number'),
      value: phone,
    },
    {
      label: t('verification.text_residential_address'),
      value: residential_address,
    },
  ];

  const toggleZoomPhotoModal = useCallback(
    (url) => {
      setIsVisibleZoomPhotoModal(!isVisibleZoomPhotoModal);
      setZoomPhotoURL(url);
    },
    [isVisibleZoomPhotoModal]
  );
  const renderIdentityDocument = (documentType, values) => {
    switch (documentType) {
      case 'DRIVER_LICENSE':
        return <DriverLicenseContent values={values} />;
      case 'PASSPORT':
        return <PassportContent values={values} />;
      case 'BASIC_RESIDENT_REGISTER':
        return <BasicResidentRegisterContent values={values} />;
      case 'HEALTH_INSURANCE_CARD':
        return <HealthInsuranceCardContent values={values} />;
      case 'RESIDENCE_CARD':
        return <ResidentCardContent values={values} />;
      case 'FOREIGNER_REGISTRATION_CARD':
        return <ForeignerRegistrationCardContent values={values} />;
      case 'SPECIAL_PERMANENT_RESIDENT':
        return <SpecialPermanentResidentContent values={values} />;
      default:
        break;
    }
  };

  return (
    <>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={3}>
          <Typography variant="h5" component="h5" className={classes.subtitle}>
            {t('verification.text_contact_information')}
          </Typography>
          <Box component="div" p={2} className={classes.content}>
            {contact?.map((item, index) => (
              <ContentChild key={index} label={item.label} value={item.value} />
            ))}
          </Box>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={8}>
          <Typography variant="h5" component="h5" className={classes.subtitle}>
            {t('verification.text_identity_document')}
          </Typography>
          <Box component="div" p={2} className={classes.content}>
            <ContentChild label={t('verification.text_identity_document')} value={formatDocument(idoc_type)} />
            {renderIdentityDocument(idoc_type, data)}
          </Box>
        </Grid>
        <Grid container item spacing={2} className={classes.root}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h5" className={classes.subtitle}>
              {t('verification.text_upload_photos')}
            </Typography>
            <Box component="div" p={2} className={classes.contentPhotos}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={4}>
                  <img
                    className={classes.image}
                    src={idoc_photo1_url}
                    alt="document"
                    crossOrigin="anonymous"
                    onClick={() => toggleZoomPhotoModal(idoc_photo1_url)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <img
                    className={classes.image}
                    src={idoc_photo2_url}
                    alt="document"
                    crossOrigin="anonymous"
                    onClick={() => toggleZoomPhotoModal(idoc_photo2_url)}
                  />
                </Grid>
                <Grid item xs={4}>
                  {idoc_video_url && (
                    <img
                      className={classes.image}
                      src={idoc_video_url}
                      alt="document"
                      crossOrigin="anonymous"
                      onClick={() => toggleZoomPhotoModal(idoc_video_url)}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <ZoomPhoto isOpening={isVisibleZoomPhotoModal} onToggle={toggleZoomPhotoModal} imageURL={zoomPhotoURL} />
    </>
  );
}

export default InformationPrintModeComponent;
