import { put, takeLatest } from "redux-saga/effects";
import { actions, ActionTypes } from "./actions";
import { getListRequestKYCAPI,getDetailKYCAPI } from "../../services/KYCRequest";
import { RESPONSE_STATUS } from "../../common/constant";
import { actions as appActions } from "../../pages/layout/actions";
import notify from "../../components/Toast";
import i18n from "../../i18n/i18n";

function* getListRequestKYC({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield getListRequestKYCAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getListRequestKYCSuccess(response.data));
    } else {
      yield put(actions.getListRequestKYCError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.getListRequestKYCError(e));
    yield put(appActions.hideLoading());
  }
}

function* getDetailKYC({ requestId }) {
  try {
    yield put(appActions.showLoading());
    const response = yield getDetailKYCAPI(requestId);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getDetailKYCSuccess(response.data));
    } else {
      yield put(actions.getDetailKYCError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.getDetailKYCError(e));
    yield put(appActions.hideLoading());
  }
}

export function* watchKYCHistory() {
  yield takeLatest(ActionTypes.GET_DETAIL_KYC_START, getDetailKYC);
  yield takeLatest(ActionTypes.GET_LIST_REQUEST_KYC_START, getListRequestKYC);
}
