import { isEmpty } from '../../../utils';
import verificationFormModel from './formModel';
const {
  formField: {
    customer_code,
    full_name,
    birthday,
    nationality,
    email,
    phoneNumber,
    residential_address,
    isFirstKYC,
    idoc_type,
    idoc_full_name,
    idoc_birthday,
    idoc_address,
    idoc_issued_date,
    idoc_expired_date,
    idoc_number,
    idoc_surname,
    idoc_given_name,
    idoc_gender,
    idoc_nationality,
    idoc_applicable_date,
    idoc_supply_no,
    idoc_photo1,
    idoc_photo2,
    idoc_video,
    idoc_photo1_url,
    idoc_photo2_url,
    idoc_video_url,
    idoc_photo1_file,
    idoc_photo2_file,
    idoc_video_file,

    // SRS-02
    business_card_photo1,
    business_card_photo1_url,
    business_card_photo1_file,
    business_card_photo2,
    business_card_photo2_url,
    business_card_photo2_file,
    business_card_phone,
    business_card_pic,
    business_card_web,
    business_card_mobile,
    business_card_fax,
    business_card_email,
    business_card_company_addr,
    business_card_company,
    legal_entity_photo1,
    legal_entity_photo1_url,
    legal_entity_photo1_file,
    legal_entity_photo2,
    legal_entity_photo2_url,
    legal_entity_photo2_file,
    legal_entity_company,
    legal_entity_representative_name,
    legal_entity_company_business,
    legal_entity_license,
  },
} = verificationFormModel();

const handleValue = (value = {}, field = '') => {
  return !isEmpty(value) && !!value[field] ? value[field] : '';
};

export default function (data) {
  return {
    [customer_code.name]: handleValue(data, 'customer_code'),
    [full_name.name]: handleValue(data, 'full_name'),
    [birthday.name]: data?.birthday ? data.birthday : null,
    [nationality.name]: handleValue(data, 'nationality'),
    [email.name]: handleValue(data, 'email'),
    [phoneNumber.name]: handleValue(data, 'phone'),
    [residential_address.name]: handleValue(data, 'residential_address'),
    [isFirstKYC.name]: data?.first_kyc,

    [idoc_type.name]: handleValue(data, 'idoc_type'),
    [idoc_full_name.name]: handleValue(data, 'idoc_full_name'),
    [idoc_birthday.name]: data?.idoc_birthday ? data.idoc_birthday : null,
    [idoc_address.name]: handleValue(data, 'idoc_address'),
    [idoc_issued_date.name]: data?.idoc_issued_date ? data.idoc_issued_date : null,
    [idoc_expired_date.name]: data?.idoc_expired_date ? data.idoc_expired_date : null,
    [idoc_number.name]: handleValue(data, 'idoc_number'),
    [idoc_surname.name]: handleValue(data, 'idoc_surname'),
    [idoc_given_name.name]: handleValue(data, 'idoc_given_name'),
    [idoc_gender.name]: handleValue(data, 'idoc_gender'),
    [idoc_nationality.name]: handleValue(data, 'idoc_nationality'),
    [idoc_applicable_date.name]: data?.idoc_applicable_date ? data.idoc_applicable_date : null,
    [idoc_supply_no.name]: handleValue(data, 'idoc_supply_no'),

    [idoc_photo1.name]: handleValue(data, 'idoc_photo1'),
    [idoc_photo2.name]: handleValue(data, 'idoc_photo2'),
    [idoc_video.name]: handleValue(data, 'idoc_video'),

    [idoc_photo1_url.name]: handleValue(data, 'idoc_photo1_url'),
    [idoc_photo2_url.name]: handleValue(data, 'idoc_photo2_url'),
    [idoc_video_url.name]: handleValue(data, 'idoc_video_url'),

    [idoc_photo1_file.name]: handleValue(data, 'idoc_photo1_file'),
    [idoc_photo2_file.name]: handleValue(data, 'idoc_photo2_file'),
    [idoc_video_file.name]: handleValue(data, 'idoc_video_file'),

    // sRS-02
    [business_card_photo1.name]: handleValue(data, 'business_card_photo1'),
    [business_card_photo1_url.name]: handleValue(data, 'business_card_photo1_url'),
    [business_card_photo1_file.name]: handleValue(data, 'business_card_photo1_file'),
    [business_card_photo2.name]: handleValue(data, 'business_card_photo2'),
    [business_card_photo2_url.name]: handleValue(data, 'business_card_photo2_url'),
    [business_card_photo2_file.name]: handleValue(data, 'business_card_photo2_file'),
    [business_card_phone.name]: handleValue(data, 'business_card_phone'),
    [business_card_pic.name]: handleValue(data, 'business_card_pic'),
    [business_card_web.name]: handleValue(data, 'business_card_web'),
    [business_card_mobile.name]: handleValue(data, 'business_card_mobile'),
    [business_card_fax.name]: handleValue(data, 'business_card_fax'),
    [business_card_email.name]: handleValue(data, 'business_card_email'),
    [business_card_company_addr.name]: handleValue(data, 'business_card_company_addr'),
    [business_card_company.name]: handleValue(data, 'business_card_company'),
    [legal_entity_photo1.name]: handleValue(data, 'legal_entity_photo1'),
    [legal_entity_photo1_url.name]: handleValue(data, 'legal_entity_photo1_url'),
    [legal_entity_photo1_file.name]: handleValue(data, 'legal_entity_photo1_file'),
    [legal_entity_photo2.name]: handleValue(data, 'legal_entity_photo2'),
    [legal_entity_photo2_url.name]: handleValue(data, 'legal_entity_photo2_url'),
    [legal_entity_photo2_file.name]: handleValue(data, 'legal_entity_photo2_file'),
    [legal_entity_company.name]: handleValue(data, 'legal_entity_company'),
    [legal_entity_representative_name.name]: handleValue(data, 'legal_entity_representative_name'),
    [legal_entity_company_business.name]: handleValue(data, 'legal_entity_company_business'),
    [legal_entity_license.name]: handleValue(data, 'legal_entity_license'),
  };
}

export function initialValuesStep1(data) {
  return {
    [full_name.name]: handleValue(data, 'full_name'),
    [birthday.name]: data?.birthday ? data.birthday : null,
    [nationality.name]: handleValue(data, 'nationality'),
    [email.name]: handleValue(data, 'email'),
    [phoneNumber.name]: handleValue(data, 'idoc_number'),
    [residential_address.name]: handleValue(data, 'residential_address'),
  };
}

export const getInitialValuesStep2Legal = data => {
  return {
    // SRS - 02
    [business_card_phone.name]: handleValue(data, 'business_card_phone'),
    [business_card_pic.name]: handleValue(data, 'business_card_pic'),
    [business_card_web.name]: handleValue(data, 'business_card_web'),
    [business_card_mobile.name]: handleValue(data, 'business_card_mobile'),
    [business_card_fax.name]: handleValue(data, 'business_card_fax'),
    [business_card_email.name]: handleValue(data, 'business_card_email'),
    [business_card_company_addr.name]: handleValue(data, 'business_card_company_addr'),
    [business_card_company.name]: handleValue(data, 'business_card_company'),
    [legal_entity_company.name]: handleValue(data, 'legal_entity_company'),
    [legal_entity_representative_name.name]: handleValue(data, 'legal_entity_representative_name'),
    [legal_entity_company_business.name]: handleValue(data, 'legal_entity_company_business'),
    [legal_entity_license.name]: handleValue(data, 'legal_entity_license'),
  };
};

export const getInitialValuesStep3Legal = data => {
  return {
    // SRS - 02
    [business_card_photo1.name]: handleValue(data, 'business_card_photo1'),
    [business_card_photo1_url.name]: handleValue(data, 'business_card_photo1_url'),
    [business_card_photo1_file.name]: handleValue(data, 'business_card_photo1_file'),
    [business_card_photo2.name]: handleValue(data, 'business_card_photo2'),
    [business_card_photo2_url.name]: handleValue(data, 'business_card_photo2_url'),
    [business_card_photo2_file.name]: handleValue(data, 'business_card_photo2_file'),
    [legal_entity_photo1.name]: handleValue(data, 'legal_entity_photo1'),
    [legal_entity_photo1_url.name]: handleValue(data, 'legal_entity_photo1_url'),
    [legal_entity_photo1_file.name]: handleValue(data, 'legal_entity_photo1_file'),
    [legal_entity_photo2.name]: handleValue(data, 'legal_entity_photo2'),
    [legal_entity_photo2_url.name]: handleValue(data, 'legal_entity_photo2_url'),
    [legal_entity_photo2_file.name]: handleValue(data, 'legal_entity_photo2_file'),
  };
};

export function initialValuesStep2(data) {
  return {
    [idoc_type.name]: handleValue(data, 'idoc_type'),
    [idoc_full_name.name]: handleValue(data, 'idoc_full_name'),
    [idoc_birthday.name]: data?.idoc_birthday ? data.idoc_birthday : null,
    [idoc_address.name]: handleValue(data, 'idoc_address'),
    [idoc_issued_date.name]: data?.idoc_issued_date ? data.idoc_issued_date : null,
    [idoc_expired_date.name]: data?.idoc_expired_date ? data.idoc_expired_date : null,
    [idoc_number.name]: handleValue(data, 'idoc_number'),
    [idoc_surname.name]: handleValue(data, 'idoc_surname'),
    [idoc_given_name.name]: handleValue(data, 'idoc_given_name'),
    [idoc_gender.name]: handleValue(data, 'idoc_gender'),
    [idoc_nationality.name]: handleValue(data, 'idoc_nationality'),
    [idoc_applicable_date.name]: data?.idoc_applicable_date ? data.idoc_applicable_date : null,
    [idoc_supply_no.name]: handleValue(data, 'idoc_supply_no'),
    // SRS - 02
    ...getInitialValuesStep2Legal(data),
  };
}

export function initialValuesStep3(data) {
  return {
    [idoc_photo1.name]: handleValue(data, 'idoc_photo1'),
    [idoc_photo2.name]: handleValue(data, 'idoc_photo2'),
    [idoc_video.name]: handleValue(data, 'idoc_video'),
    [idoc_photo1_url.name]: handleValue(data, 'idoc_photo1_url'),
    [idoc_photo2_url.name]: handleValue(data, 'idoc_photo2_url'),
    [idoc_video_url.name]: handleValue(data, 'idoc_video_url'),
    [idoc_photo1_file.name]: handleValue(data, 'idoc_photo1_file'),
    [idoc_photo2_file.name]: handleValue(data, 'idoc_photo2_file'),
    [idoc_video_file.name]: handleValue(data, 'idoc_video_file'),
    // SRS - 02
    ...getInitialValuesStep3Legal(data),
  };
}
