import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import { actions as appActions } from '../layout/actions';
import { getCustomerCodeAPI } from '../../services/AuthRequest';
import { RESPONSE_STATUS } from '../../common/constant';
import { getUserInformationAPI } from '../../services/UserRequest';

function* getCustomerCode({ params }) {
  try {
    // yield put(appActions.showLoadingEvent());
    const response = yield getCustomerCodeAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getCustomerCodeSuccess(response.data));
    } else {
      yield put(actions.getCustomerCodeError(response));
    }
    // yield put(appActions.hideLoadingEvent());
  } catch (e) {
    yield put(actions.getCustomerCodeError(e));
    // yield put(appActions.hideLoadingEvent());
  }
}

function* getUserInformation({ params }) {
  try {
    yield put(appActions.showLoading());
    const response = yield getUserInformationAPI(params);
    if (response.success === RESPONSE_STATUS.SUCCESS) {
      yield put(actions.getUserInformationSuccess(response.data));
    } else {
      yield put(actions.getUserInformationError(response));
    }
    yield put(appActions.hideLoading());
  } catch (e) {
    yield put(actions.getUserInformationError(e));
    yield put(appActions.hideLoading());
  }
}

export function* watchAuthentication() {
  yield takeLatest(ActionTypes.GET_CUSTOMER_CODE_START, getCustomerCode);
  yield takeLatest(ActionTypes.GET_USER_INFORMATION_START, getUserInformation);
}
