import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { CHART_EMPTY } from '../../common/constant';

const useStyles = makeStyles(theme => ({
  content: {
    '&:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
  contentLabel: {
    fontSize: 16,
    lineHeight: '19px',
    color: theme.overrides.colorBalticSea,
  },
  contentValue: {
    color: theme.overrides.colorShuttleGray,
    fontSize: 14,
    lineHeight: '16px',
    marginTop: theme.spacing(1),
  },
}));

function ContentChild({ label, value }) {
  const classes = useStyles();

  return (
    <Box component="div" className={classes.content}>
      <Typography component="div" className={classes.contentLabel}>
        {label}
      </Typography>
      <Typography component="div" className={classes.contentValue}>
        {value ? value : CHART_EMPTY}
      </Typography>
    </Box>
  );
}

export default ContentChild;
