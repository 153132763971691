import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import CardComponent from './components/card';
import HeadingComponent from './components/heading';
import LoginForm from './components/loginForm';
import { SimpleModal } from '../../components/Modal';
import ThumbnailContactInformation from '../../resources/images/home__contact-information.png';
import ThumbnailContactDocument from '../../resources/images/home__document.png';
import ThumbnailContactKYCPhotos from '../../resources/images/home__kyc-photos.png';
import ThumbnailContactReviewAndSubmit from '../../resources/images/home__review-submit.png';
import Banner from '../../resources/images/home__banner.png';
import { actions, ActionTypes } from './actions';

function HomePage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const [isEmailInput, setIsEmailInput] = useState(false);
  const [isOpeningModalAccessKYC, setIsOpeningModalAccessKYC] = useState(false);
  const customer = useSelector((state) => state.home.customer);
  const resultRequest = useSelector((state) => state.home.type);

  const handleChangeInputType = (e) => {
    e.preventDefault();
    setIsEmailInput(!isEmailInput);
  };

  const toggleModalAccessKYC = () => {
    setIsOpeningModalAccessKYC(!isOpeningModalAccessKYC);
  };

  useEffect(() => {
    if (resultRequest === ActionTypes.GET_USER_INFORMATION_SUCCESS) {
      history.push('/login');
      dispatch(actions.resetTypeOfAction());
    }
  }, [customer]);

  const handleSubmitLoginForm = (data) => {
    const { email, phoneNumber } = data.contact;
    const { contactMethod } = data;
    if (contactMethod === 'PHONE') {
      dispatch(actions.getUserInformationStart({ contactAddress: phoneNumber, contactMethod }));
    } else {
      dispatch(actions.getUserInformationStart({ contactAddress: email.trim(), contactMethod }));
    }
  };

  return (
    <div className="home">
      <div id="banner" className="home__section-banner">
        <div className="container">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <img className="section-banner__image" src={Banner} alt="banner" />
            </Grid>
            <Grid item xs={12} md={1}></Grid>
            <Grid item xs={12} md={5} className="section-banner__right">
              <Typography variant="h2" component="h2" className="title">
                {t('home.section_banner__text_title')}
              </Typography>
              <Typography variant="body2" component="div" className="description">
                {t('home.section_banner__text_description')}
              </Typography>
              <Button variant="contained" className=" button button-primary" onClick={toggleModalAccessKYC}>
                {t('home.button_direct_to_kyc_link')}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      <div id="how-to-use" className="home__section-description">
        <div className="container">
          <HeadingComponent text={t('home.section_description__text_heading')} />
          <Grid container spacing={2} className="list-card">
            <Grid item xs={12} sm={6} md={3}>
              <CardComponent
                thumbnail={ThumbnailContactInformation}
                title={t('home.card_1__title')}
                description={t('home.card_1__description')}
                list={[
                  t('home.text_full_name'),
                  t('home.text_date_of_birth'),
                  t('home.text_nationality'),
                  t('home.text_email'),
                  t('home.text_phone_number'),
                  t('home.text_residential_address'),
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardComponent
                thumbnail={ThumbnailContactDocument}
                title={t('home.card_2__title')}
                description={t('home.card_2__description')}
                list={[
                  t('home.text_driver_license'),
                  t('home.text_passport'),
                  t('home.text_basic_resident_register'),
                  t('home.text_health_insurance_card'),
                  t('home.text_residence_card'),
                  t('home.text_foreigner_registration_card'),
                  t('home.text_special_permanent_resident'),
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardComponent
                thumbnail={ThumbnailContactKYCPhotos}
                title={t('home.card_3__title')}
                description={t('home.card_3__description')}
                list={[
                  t('home.text_front_page'),
                  t('home.text_back_page'),
                  t('home.text_selfie_with_identity_document'),
                  t('home.text_card_is_thickness'),
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardComponent
                thumbnail={ThumbnailContactReviewAndSubmit}
                title={t('home.card_4__title')}
                description={t('home.card_4__description')}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div id="access-kyc-link" className="home__section-login">
        <div className="container">
          <Grid container spacing={2}>
            <Grid item xs={false} md={3}></Grid>
            <Grid item xs={12} md={6}>
              <LoginForm
                isEmailInput={isEmailInput}
                handleChangeInputType={handleChangeInputType}
                handleSubmitForm={handleSubmitLoginForm}
                t={t}
              />
            </Grid>
            <Grid item xs={false} md={3}></Grid>
          </Grid>
        </div>
      </div>
      <div id="about-us" className="home__section-about-us">
        <div className="container">
          <HeadingComponent text={t('home.section_about_us__heading')} />
          <div className="section-about-us__text">
            {t('home.section_about_us_paragraph')}
            <br />
            <br />
            {t('home.section_about_us_paragraph')}
            <br />
            <br />
            {t('home.section_about_us_paragraph')}
          </div>
        </div>
      </div>
      <SimpleModal
        isOpeningModal={isOpeningModalAccessKYC}
        toggleModal={toggleModalAccessKYC}
        className={'modal-kyc'}
        width={'unset'}
        children={
          <LoginForm
            isEmailInput={isEmailInput}
            handleChangeInputType={handleChangeInputType}
            t={t}
            handleSubmitForm={handleSubmitLoginForm}
          />
        }
      />
    </div>
  );
}
export default HomePage;
